import { Benchmark } from '../../benchmarks-repository';
import { Skill, SkillGroupId, SkillId } from '../skill';

export class TradeKillOpportunitiesPerRound extends Skill {
	public readonly benchmarkKeyAvg: keyof Benchmark = 'tradeKillOpportunitiesPerRoundAvg';
	public readonly benchmarkKeyStd: keyof Benchmark = 'tradeKillOpportunitiesPerRoundStd';
	public groupId: SkillGroupId = 'positioningSkillGroup';

	dependsOn = null;
	description = '';
	descriptionExtra = '';
	detailsInfo = false;
	displayInGoodBadSummary = false;
	groupIcon = 'mdi mdi-progress-wrench';
	groupTitle = 'Positioning group';
	icon = 'mdi mdi-weather-cloudy';
	id = SkillId.TRADE_KILL_OPPORTUNITIES_PER_ROUND;
	improveTip = false;
	inversed = false;
	isRatio = false;
	minValue = 0;
	smallDescription = '';
	title = 'Trade Kill Opportunities per Round';
}
