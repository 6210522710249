import { Skill, SkillId } from '../skill';

export class OpeningDuelMostDiedToWeapon extends Skill {
	public readonly benchmarkKeyAvg = undefined;
	public readonly benchmarkKeyStd = undefined;

	dependsOn = null;
	description = '';
	descriptionExtra = '';
	detailsInfo = false;
	displayInGoodBadSummary = false;
	fallbackValue = 'N/A';
	groupId = null;
	groupTitle = 'General';
	id = SkillId.OPENING_DUEL_MOST_DIED_TO_WEAPON;
	improveTip = false;
	inversed = false;
	isRatio = false;
	minValue = 0;
	smallDescription = '';
	sortable = false;
	title = 'Most Died To';
}
