import { User } from 'src/app/models/user.model';
import { PrivacySettingsMode } from 'leetify-shared-utils/enums';

export class PrivacySettingsHelper {
	private static getSelfTooltip(user: User): string {
		if (user.privacySettings === PrivacySettingsMode.FRIENDS) return 'This data is only visible to your friends.';
		if (user.privacySettings === PrivacySettingsMode.PRIVATE) return 'This data is only visible to you.';
	}

	static getSpectateTooltip(isSensitiveDataVisible: boolean, leetifyUserId: string): string {
		if (!leetifyUserId) return 'Spectate is disabled for players without a Leetify account.';
		if (!isSensitiveDataVisible && leetifyUserId) return 'This player has chosen to disable spectate for their profile.';
	}

	static getActivityTooltip(isSensitiveDataVisible: boolean, leetifyUserId: string, user?: User): string {
		if (user && user.id === leetifyUserId) return this.getSelfTooltip(user);

		if (!leetifyUserId) return 'Activity is hidden and Rank History is restricted for players without a Leetify account.';
		if (!isSensitiveDataVisible && leetifyUserId) return 'This player has chosen to hide their Activity and restrict their Rank History.';
	}

	static getTeammatesTooltip(isSensitiveDataVisible: boolean, leetifyUserId: string, user?: User): string {
		if (user && user.id === leetifyUserId) return this.getSelfTooltip(user);

		if (!leetifyUserId) return 'Teammates data is hidden for players without a Leetify account.';
		if (!isSensitiveDataVisible && leetifyUserId) return 'This player has chosen to hide their Teammates data.';
	}

	static getMatchHistoryTooltip(isSensitiveDataVisible: boolean, leetifyUserId: string, user?: User): string {
		if (user && user.id === leetifyUserId) return this.getSelfTooltip(user);

		if (!leetifyUserId) return 'Match history is hidden for players without a Leetify account.';
		if (!isSensitiveDataVisible && leetifyUserId) return 'This player has chosen to hide their match history.';
	}
}
