import { Injectable } from '@angular/core';
import { HttpClient } from '@angular/common/http';
import { Observable } from 'rxjs';
import { map } from 'rxjs/operators';
import { environment } from 'src/environments/environment';

@Injectable({
	providedIn: 'root',
})
export class AuthService {
	constructor(
		private http: HttpClient,
	) {
	}

	public static setToken(token: string): void {
		localStorage.setItem('access_token', token);
	}

	public static getToken(): string {
		// TODO remove hardcoded token
		return environment.token;
		// if (typeof localStorage === 'undefined') return null;
		// return localStorage.getItem('access_token');
	}

	public static removeToken(): void {
		if (typeof localStorage !== 'undefined') localStorage.removeItem('access_token');
	}

	public login(email: string, password: string): Observable<any> {
		return this.http.post('/api/login', { email, password }).pipe(
			map((result: any) => {
				AuthService.setToken(result.token);
				return true;
			}),
		);
	}

	public signup(
		email: string,
		password: string,
		referralCode?: string,
	): Observable<any> {
		return this.http.post('/api/signup', { email, password, referralCode }).pipe(
			map((result: any) => {
				AuthService.setToken(result.token);
				return true;
			}),
		);
	}

	public logout(): any {
		AuthService.removeToken();
	}

	public resetPasswordRequest(email: string): Observable<any> {
		return this.http.post('/api/reset-password-request', { email }, { responseType: 'text' });
	}

	public resetPasswordSubmit(token: string, password: string): Observable<any> {
		return this.http.post('/api/reset-password-submit', { token, password }).pipe(
			map((result: any) => {
				AuthService.setToken(result.token);
				return true;
			}),
		);
	}
}
