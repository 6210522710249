import { Component, Input, OnChanges } from '@angular/core';

type UserLevelClass = '--founder' | '--pro' | '--staff';

@Component({
	selector: 'app-user-leetify-icon',
	templateUrl: './user-leetify-icon.component.html',
	styleUrls: ['./user-leetify-icon.component.scss'],
})
export class UserLeetifyIconComponent implements OnChanges {
	@Input() public tooltip = true;

	@Input() public userData: {
		isCollector?: boolean;
		isLeetifyStaff?: boolean;
		isLeetifyUser?: boolean;
		isProPlan?: boolean;
		leetifyUserId?: string;
	};

	public className: UserLevelClass;
	public label: string;

	protected getClassName(): UserLevelClass {
		if (this.userData?.isLeetifyStaff) return '--staff';
		if (this.userData?.isCollector) return '--founder';
		if (this.userData?.isProPlan) return '--pro';
	}

	protected getLabel(): string {
		if (this.userData?.isLeetifyStaff) return 'Leetify Staff';
		if (this.userData?.isCollector) return 'Leetify Founder';
		if (this.userData?.isProPlan) return 'Leetify Pro';
		if (this.userData?.leetifyUserId || this.userData?.isLeetifyUser) return 'Leetify User';
	}

	public ngOnChanges(): void {
		this.className = this.getClassName();
		this.label = this.getLabel();
	}
}
