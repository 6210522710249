import { Benchmark } from '../../benchmarks-repository';
import { PostSign, Skill, SkillGroupId, SkillId } from '../skill';

export class FlashbangLeadingToKill extends Skill {
	public readonly benchmarkKeyAvg: keyof Benchmark = 'flashbangLeadingToKillAvg';
	public readonly benchmarkKeyStd: keyof Benchmark = 'flashbangLeadingToKillStd';
	public readonly dependsOn = 'flashbangThrown';
	public readonly descriptionExtra = 'Any time you throw a flashbang we look for flashed players, excluding teammates and anyone blinded less than 1.1 sec (half-blind). If the flashed player then gets killed by you or a teammate, the flashbang is counted as having lead to a kill.';
	public readonly description = 'This stat displays how many, on average, enemies were killed whilst affected by your flashbang. This is similar to flash assists, but also includes your own kills after you flashed the enemy. (Note: Only includes players from enemy team).';
	public readonly detailsInfo = false;
	public readonly displayInGoodBadSummary = true;
	public readonly fallbackValue = 'n/a';
	public readonly groupIcon = 'mdi mdi-progress-wrench';
	public readonly groupId: SkillGroupId = 'utilitySkillGroup';
	public readonly groupTitle = 'Utility group';
	public readonly icon = 'mdi mdi-account-star-outline';
	public readonly id = SkillId.FLASHBANG_LEADING_TO_KILL;
	public readonly improveTip = false;
	public readonly inversed = false;
	public readonly isRatio = true;
	public readonly minValue = 0;
	public readonly postSign: PostSign = '%';
	public readonly shortTitle = 'Flash Assists';
	public readonly smallDescription = 'How many, on average, enemies were killed with help of your flashbangs.';
	public readonly title = 'Flashbangs Leading to Kills';
}
