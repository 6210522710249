import { Benchmark } from '../../benchmarks-repository';
import { PostSign, Skill, SkillGroupId, SkillId } from '../skill';

export class Accuracy extends Skill {
	public readonly benchmarkKeyAvg: keyof Benchmark = 'accuracyAvg';
	public readonly benchmarkKeyStd: keyof Benchmark = 'accuracyStd';
	public groupId: SkillGroupId = 'aimSkillGroup';
	public postSign: PostSign = '%';

	dependsOn = 'shotsFired';
	description = 'Out of the total amount of bullets you fired, how many of those hit an enemy. Not included in your overall aim score.';
	descriptionExtra = 'All hits divided by all shots.';
	detailsInfo = true;
	displayInGoodBadSummary = false;
	fallbackValue = 'n/a';
	groupIcon = 'mdi mdi-crosshairs-gps';
	groupTitle = 'Aim group';
	icon = 'mdi mdi-bullseye';
	id = SkillId.ACCURACY;
	improveTip = true;
	inversed = false;
	isRatio = true;
	minValue = 0;
	shortTitle = 'Accuracy (All)';
	smallDescription = 'All hits divided by all shots.';
	title = 'Accuracy (All Shots)';
}
