<svg [attr.height]="radius * 2" [attr.width]="radius * 2">
	<circle
		class="score-circle-background --{{ backgroundStyle }}"
		fill="transparent"
		shape-rendering="geometricPrecision"
		[attr.r]="normalizedRadius"
		cx="50%"
		cy="50%"
		[attr.stroke-width]="stroke"
	/>

	<circle
		class="score-circle-outline"
		[ngClass]="outlineClass"
		attr.stroke-dasharray="{{ this.circumference }} {{ this.circumference }}"
		fill="transparent"
		shape-rendering="geometricPrecision"
		[attr.stroke-width]="stroke"
		[attr.r]="normalizedRadius"
		cx="50%"
		cy="50%"
		[attr.stroke-dashoffset]="strokeDashoffset"
	/>

	<image *ngIf="previousPeriodImprovement !== null && previousPeriodImprovement > 0" x="44%" y="21%" width="20" height="20" [attr.href]="getPreviousPeriodIconUrl()" />

	<text *ngIf="showValue" class="{{ textClass }} score-text" x="50%" [attr.y]="getTextYPosition()" text-anchor="middle">
		<ng-container *ngIf="decimalPlaces === 0">
			{{ (distinctNegative && value > 0) ? '+' : '' }}{{ value | number: '1.0-0' }}{{ postSign }}
		</ng-container>

		<ng-container *ngIf="decimalPlaces === 2">
			{{ (distinctNegative && value > 0) ? '+' : '' }}{{ value | number: '1.2-2' }}{{ postSign }}
		</ng-container>
	</text>

	<image *ngIf="previousPeriodImprovement !== null && previousPeriodImprovement < 0" x="44%" y="65%" width="20" height="20" [attr.href]="getPreviousPeriodIconUrl()" />
</svg>
