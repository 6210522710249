import { Benchmark } from '../../benchmarks-repository';
import { FocusAreaSkill } from '../focus-area-skill';
import { PostSign, SkillGroupId, SkillId } from '../skill';

export class PlayerClutchKillsPercentage extends FocusAreaSkill {
	public readonly benchmarkKeyAvg: keyof Benchmark = 'playerClutchKillsPercentageAvg';
	public readonly benchmarkKeyStd: keyof Benchmark = 'playerClutchKillsPercentageStd';
	public groupId: SkillGroupId = 'clutchSkillGroup';
	public postSign: PostSign = '%';

	dependsOn = null;
	description = '';
	detailsInfo = false;
	displayInGoodBadSummary = false;
	groupTitle = 'Clutching';
	id = SkillId.PLAYER_CLUTCH_KILLS_PERCENTAGE;
	improveTip = false;
	inversed = false;
	isRatio = true;
	minValue = 0;
	smallDescription = '';
	title = 'Overall Clutch Kills';

	focusAreaLowerBound = 12;
	focusAreaUpperBound = 25;
}
