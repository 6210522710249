import { Benchmark } from '../../../benchmarks-repository';
import { CtBombsiteAttemptRate } from './ct-bombsite-attempt-rate';
import { SkillId } from '../../skill';

export class CtBombsiteRetakeRate extends CtBombsiteAttemptRate {
	public readonly benchmarkKeyAvg: keyof Benchmark = 'ctBombsiteRetakeAvg';
	public readonly benchmarkKeyStd: keyof Benchmark = 'ctBombsiteRetakeStd';

	description = 'The amount of times you successfully defused the bomb, divided by all times you tried to retake a bombsite.';
	id = SkillId.CT_BOMBSITE_RETAKE_RATE;
	smallDescription = 'The amount of times you successfully defused the bomb, divided by all times you tried to retake a bombsite.';
	title = 'Retakes';
}
