import { Benchmark } from '../../benchmarks-repository';
import { FocusAreaSkill } from '../focus-area-skill';
import { SkillGroupId, SkillId } from '../skill';

export class TotalGrenadesThrownPerRound extends FocusAreaSkill {
	public readonly benchmarkKeyAvg: keyof Benchmark = 'totalGrenadesThrownPerRoundAvg';
	public readonly benchmarkKeyStd: keyof Benchmark = 'totalGrenadesThrownPerRoundStd';
	public groupId: SkillGroupId = 'focusAreaSkillGroup';

	dependsOn = null;
	description = '';
	descriptionExtra = '';
	detailsInfo = false;
	displayInGoodBadSummary = false;
	groupIcon = '';
	groupTitle = 'Focus Area group';
	icon = '';
	id = SkillId.TOTAL_GRENADES_THROWN_PER_ROUND;
	improveTip = true;
	inversed = false;
	isRatio = true;
	minValue = 0;
	smallDescription = '';
	title = 'Grenades Thrown per Round';

	focusAreaLowerBound = 0;
	focusAreaUpperBound = 2.5;
}
