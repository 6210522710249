import { Skill, SkillId } from '../skill';

export class TotalAssists extends Skill {
	public readonly benchmarkKeyAvg = undefined;
	public readonly benchmarkKeyStd = undefined;
	public readonly dependsOn = null;
	public readonly description = '';
	public readonly detailsInfo = false;
	public readonly displayInGoodBadSummary = false;
	public readonly groupId = null;
	public readonly groupTitle = 'General';
	public readonly id = SkillId.TOTAL_ASSISTS;
	public readonly improveTip = false;
	public readonly inversed = false;
	public readonly isRatio = false;
	public readonly smallDescription = '';
	public readonly title = 'Assists';
}
