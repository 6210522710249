import { Benchmark } from '../../benchmarks-repository';
import { PostSign, SkillGroupId, SkillId } from '../skill';
import { FocusAreaSkill } from '../focus-area-skill';

export class AccuracyEnemySpotted extends FocusAreaSkill {
	public readonly benchmarkKeyAvg: keyof Benchmark = 'accuracyEnemySpottedAvg';
	public readonly benchmarkKeyStd: keyof Benchmark = 'accuracyEnemySpottedStd';
	public readonly calculationChangeDates = [{ date: '04.10.2020', url: 'https://leetify.com/blog/enemy-actually-spotted/' }];
	public readonly dependsOn = 'shotsFiredEnemySpotted';
	public readonly descriptionExtra = 'All hits divided by all shots at the spotted enemy.';
	public readonly description = "When you were actually firing at a target, how accurate were you? For example, this would exclude any time you're spamming through a smoke or wall. Only shots when you were actually shooting at an enemy in your field of view count. Naturally, you want to push this number as high as possible, no matter what Wayne Gretzky said.";
	public readonly detailsInfo = true;
	public readonly displayInGoodBadSummary = true;
	public readonly fallbackValue = 'n/a';
	public readonly focusAreaLowerBound = 18;
	public readonly focusAreaUpperBound = 56;
	public readonly groupIcon = 'mdi mdi-crosshairs-gps';
	public readonly groupId: SkillGroupId = 'aimSkillGroup';
	public readonly groupTitle = 'Aim group';
	public readonly icon = 'mdi mdi-bullseye';
	public readonly id = SkillId.ACCURACY_ENEMY_SPOTTED;
	public readonly improveTip = true;
	public readonly inversed = false;
	public readonly isRatio = true;
	public readonly minValue = 0;
	public readonly postSign: PostSign = '%';
	public readonly shortTitle = 'Spotted Accuracy';
	public readonly smallDescription = 'All hits divided by all shots at the spotted enemy.';
	public readonly title = 'Accuracy (Enemy Spotted)';
}
