import { Skill, SkillId } from '../skill';

export class TotalDeaths extends Skill {
	public readonly benchmarkKeyAvg = undefined;
	public readonly benchmarkKeyStd = undefined;

	dependsOn = null;
	description = '';
	detailsInfo = false;
	displayInGoodBadSummary = false;
	groupId = null;
	groupTitle = 'General';
	id = SkillId.TOTAL_DEATHS;
	improveTip = false;
	inversed = true;
	isRatio = false;
	smallDescription = '';
	title = 'Deaths';
}
