import { FocusAreaSkill } from '../focus-area-skill';
import { PostSign, SkillGroupId } from '../skill';

export abstract class OpeningDuelSuccessPercentage extends FocusAreaSkill {
	public groupId: SkillGroupId = 'positioningSkillGroup';
	public postSign: PostSign = '%';

	dependsOn = null;
	description = '';
	descriptionExtra = '';
	detailsInfo = false;
	displayInGoodBadSummary = false;
	groupIcon = 'mdi mdi-progress-wrench';
	groupTitle = 'Positioning group';
	icon = 'mdi mdi-weather-cloudy';
	improveTip = false;
	inversed = false;
	isRatio = false;
	minValue = 0;
	smallDescription = '';
}
