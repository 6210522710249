import { Skill, SkillId } from '../skill';

export class RoundsSurvived extends Skill {
	public readonly benchmarkKeyAvg = undefined;
	public readonly benchmarkKeyStd = undefined;

	dependsOn = null;
	description = '';
	detailsInfo = false;
	displayInGoodBadSummary = false;
	groupId = null;
	groupTitle = 'General';
	id = SkillId.ROUNDS_SURVIVED;
	improveTip = false;
	inversed = false;
	isRatio = false;
	smallDescription = '';
	title = 'Rounds Survived';
}
