import { Skill, SkillId } from '../skill';

export class DamagePerShot extends Skill {
	public readonly benchmarkKeyAvg = undefined;
	public readonly benchmarkKeyStd = undefined;

	decimalPlaces = 2;
	dependsOn = null;
	description = '';
	detailsInfo = false;
	displayInGoodBadSummary = false;
	groupId = null;
	groupTitle = 'Aim group';
	id = SkillId.DAMAGE_PER_SHOT;
	improveTip = false;
	inversed = false;
	isRatio = false;
	shortTitle = 'Dmg per Shot';
	smallDescription = '';
	title = 'Damage per Shot';
}
