import { Skill, SkillId } from '../skill';

export class TradeKillOpportunities extends Skill {
	public readonly benchmarkKeyAvg = undefined;
	public readonly benchmarkKeyStd = undefined;
	public readonly dependsOn = null;
	public readonly description = '';
	public readonly descriptionExtra = '';
	public readonly detailsInfo = false;
	public readonly displayInGoodBadSummary = false;
	public readonly groupId = null;
	public readonly groupTitle = 'Positioning group';
	public readonly id = SkillId.TRADE_KILL_OPPORTUNITIES;
	public readonly improveTip = false;
	public readonly inversed = false;
	public readonly isRatio = false;
	public readonly minValue = 0;
	public readonly smallDescription = '';
	public readonly title = 'Trade Kill Opportunities';
}
