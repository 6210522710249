import { Benchmark } from '../../benchmarks-repository';
import { OpeningDuelAttemptsPercentageBenchmarkT } from './opening-duel-attempts-percentage-benchmark-t';
import { SkillId } from '../skill';

export class OpeningDuelAttemptsPercentageBenchmarkCt extends OpeningDuelAttemptsPercentageBenchmarkT {
	public readonly benchmarkKeyAvg: keyof Benchmark = 'ctOpeningDuelAttemptsPercentageAvg';
	public readonly benchmarkKeyStd: keyof Benchmark = 'ctOpeningDuelAttemptsPercentageStd';

	id = SkillId.OPENING_DUEL_ATTEMPTS_PERCENTAGE_BENCHMARK_CT;
	keepValuesVerbatim = true;
}
