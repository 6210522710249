import { SubscriptionStatus, SubscriptionDiscountIds, SubscriptionInterval, OnboardingStatus, ProductName, ProgressReportQuarter, PrivacySettingsMode } from 'leetify-shared-utils/enums';
import { WeaponsForSpraysDTO } from 'leetify-shared-utils/dto';

export class User {
	id: string;
	email: string;
	steam64Id: string;
	steamNickname: string;
	steamAuthCode: string;
	steamLastShareCode: string;
	steamLastShareCodeDate: string;
	steamAvatarUrl: string;
	uiSettings: { // TODO Extract to UiSettings shared interface.
		selectedServerLocation: string;
		selectedServerTickRate: number;
		skillLevel: number;
		skillLevelDataSource: string;
		selectedWeaponsForSprays: WeaponsForSpraysDTO[];
		skillLevelSelectedManually: boolean;
	};
	onboardingStatus: OnboardingStatus;
	isProPlan: boolean;
	isCollector: boolean;
	proPlanExpiresAt: string;
	subscriptionStatus: SubscriptionStatus;
	subscriptionInterval: SubscriptionInterval;
	subscriptionDiscountId: SubscriptionDiscountIds;
	subscriptionProductName?: ProductName;
	signUpDate: string;
	progressReport?: { year: number; quarter: ProgressReportQuarter };
	privacySettings?: PrivacySettingsMode;

	constructor(data: User) {
		Object.assign(this, data);
	}
}
