<ng-container [ngSwitch]="type">
	<ng-container *ngSwitchCase="'skill'">
		<ng-container *ngIf="content">
			<ng-template #tipContent>
				<div class="card-box">
					<div class="row">
						<div class="col-md-12">
							<h4 [innerHTML]="header" class="text-white mb-1 text-left"></h4>
							<p class="text-left">{{ content }}</p>
						</div>
					</div>

					<div class="row mt-3" [class]="extra ? 'd-block' : 'd-none'">
						<div class="col-md-12">
							<h4 class="text-white text-left mb-1">How is it calculated</h4>
							<p class="text-left">{{ extra }}</p>
						</div>
					</div>
				</div>
			</ng-template>

			<a
				[class]="selectedIconClass"
				[ngbTooltip]="tipContent"
				[placement]="placement"
				container="body"
				style="font-size:14px"
				tooltipClass="tooltip-custom"
			>
				<i class="fa fa-question-circle mt-1 mr-1 ml-1"></i>
			</a>
		</ng-container>
	</ng-container>

	<ng-container *ngSwitchCase="'pro-button'">
		<ng-template #tipContent>
			<div class="card-box">
				<div class="row">
					<div class="col-md-12">
						<p class="text-white mb-1 text-left">{{ header }}</p>
					</div>
				</div>
			</div>
		</ng-template>

		<div
			[ngbTooltip]="tipContent"
			[placement]="placement"
			class="btn btn-primary"
			container="body"
			routerLink="/app/pro"
			tooltipClass="tooltip-custom"
		>
			<i class="fas fa-star mt-0 text-white"></i>
		</div>
	</ng-container>

	<ng-container *ngSwitchCase="'leetify-rating'">
		<ng-template #tipContent>
			<div class="card-box skill-tip">
				<div class="row">
					<div class="col-md-12">
						<h4 class="text-white mb-1 text-left">What is Leetify Rating?</h4>
						<p class="text-left">
							Leetify Rating is a win rate impact based player rating system.
							It rewards players based not only on who deals the final point of damage but also on the outcome of and contributions to (such as damage, being traded, flash assists and more) all the duels they take part in, and what effect they had on the round.
						</p>
					</div>
				</div>

				<div class="row mt-2">
					<div class="col-md-12">
						<h4 class="text-white text-left mb-1">How to use Leetify Rating?</h4>
						<p class="text-left">
							Leetify Rating is great for seeing your impact in a match or recent form, but it’s not great for identifying what to improve or if you’re improving as it’s highly dependent on the skill of your opponents.
						</p>
					</div>
				</div>
			</div>
		</ng-template>

		<a
			[ngbTooltip]="tipContent"
			[placement]="placement"
			container="body"
			target="_blank"
			tooltipClass="tooltip-custom"
		>
			<i class="fa fa-question-circle mt-1 mr-1 ml-1"></i>
		</a>
	</ng-container>

	<ng-container *ngSwitchCase="'leetify-rating-link'">
		<ng-template #tipContent>
			<div class="card-box skill-tip">
				<div class="row">
					<div class="col-md-12">
						<h4 class="text-white mb-1 text-left">What is Leetify Rating?</h4>
						<p class="text-left">
							Leetify Rating is a win rate impact based player rating system.
							It rewards players based not only on who deals the final point of damage but also on the outcome of and contributions to (such as damage, being traded, flash assists and more) all the duels they take part in, and what effect they had on the round.
						</p>
					</div>
				</div>

				<div class="row mt-2">
					<div class="col-md-12">
						<h4 class="text-white text-left mb-1">How to use Leetify Rating?</h4>
						<p class="text-left">
							Leetify Rating is great for seeing your impact in a match or recent form, but it’s not great for identifying what to improve or if you’re improving as it’s highly dependent on the skill of your opponents.
						</p>
					</div>
				</div>

				<div class="row mt-2">
					<div class="col-md-12">
						<h4 class="text-white text-left mb-1">How is it calculated?</h4>
						<p class="text-left">
							The full calculation of Leetify Rating is quite complex, but is fully documented on the "How is it calculated" link.
						</p>
					</div>
				</div>
			</div>
		</ng-template>

		<a
			[ngbTooltip]="tipContent"
			[placement]="placement"
			class="external-link"
			container="body"
			href="https://leetify.com/blog/introducing-leetify-rating/"
			target="_blank"
			tooltipClass="tooltip-custom"
		>
			How is it calculated
			<i class="fa fa-question-circle mt-1 mr-1 ml-1"></i>
		</a>
	</ng-container>

	<ng-container *ngSwitchCase="'dashboard-consistency'">
		<ng-template #tipContent>
			<div class="card-box dashboard-consistency-card">
				<p>
					This is an assessment of how well you played in your last
					<ng-container *ngIf="dashboardConsistencyCurrentMatches < 15">up to</ng-container>
					15&nbsp;matches, compared to how you've performed on average across your last
					<ng-container *ngIf="dashboardConsistencyAllMatches < 100">up to</ng-container>
					100&nbsp;matches (excluding Wingman).
				</p>

				<p *ngIf="dashboardConsistencyCurrentMatches < 15 || dashboardConsistencyAllMatches < 100">
					<strong>Note:</strong>
					You've played
					{{ dashboardConsistencyAllMatches === 1 ? 'one match' : dashboardConsistencyAllMatches + ' matches' }}
					so far. This is comparing your most recent
					{{ dashboardConsistencyCurrentMatches === 1 ? 'one match' : dashboardConsistencyCurrentMatches + ' matches' }}
					to all matches you've played.
				</p>
			</div>
		</ng-template>

		<a
			[class]="selectedIconClass"
			[ngbTooltip]="tipContent"
			[placement]="placement"
			container="body"
			style="font-size:14px"
			tooltipClass="tooltip-custom"
		>
			<i class="fa fa-question-circle mt-1 mr-1 ml-1 dashboard-consistency-icon"></i>
		</a>
	</ng-container>

	<ng-container *ngSwitchCase="'danger'">
		<ng-container *ngIf="content">
			<ng-template #tipContent>
				<div class="card-box">
					<div class="row">
						<div class="col-md-12">
							<h4 class="text-white mb-1 text-left">{{ header }}</h4>
							<p class="text-left">{{ content }}</p>
						</div>
					</div>

					<div class="row mt-3" [class]="extra ? 'd-block' : 'd-none'">
						<div class="col-md-12">
							<h4 class="text-white text-left mb-1">How is it calculated</h4>
							<p class="text-left">{{ extra }}</p>
						</div>
					</div>
				</div>
			</ng-template>

			<a
				[class]="selectedIconClass"
				[ngbTooltip]="tipContent"
				[placement]="placement"
				container="body"
				style="font-size:14px"
				tooltipClass="tooltip-custom"
			>
				<i class="fas fa-exclamation-circle mt-1 mr-1 ml-1 text-danger"></i>
			</a>
		</ng-container>
	</ng-container>

	<ng-container *ngSwitchCase="'privacy-controls'">
		<ng-template #tipContent>
			<div class="card-box skill-tip">
				<div class="row">
					<div class="col-md-12">
						<p class="text-left">These privacy controls affect:</p>
						<ul>
							<li>Teammates Panel</li>
							<li>Match History Panel</li>
							<li>Activity Panel</li>
							<li>Filters for Rank History</li>
							<li>Spectate feature</li>
						</ul>
					</div>
				</div>
			</div>
		</ng-template>

		<a
			[ngbTooltip]="tipContent"
			[placement]="placement"
			container="body"
			class="external-link"
			target="_blank"
			tooltipClass="tooltip-custom"
		>
			<i class="fa fa-question-circle mt-1 mr-1 ml-1"></i>
			What exact features and data are affected?
		</a>
	</ng-container>
</ng-container>
