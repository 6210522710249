import { Benchmark } from '../../benchmarks-repository';
import { SkillId } from '../skill';
import { LeetifyRatingBase } from './leetify-rating-base';

export class LeetifyRating extends LeetifyRatingBase {
	public readonly benchmarkKeyAvg: keyof Benchmark = 'leetifyRatingAvg';
	public readonly benchmarkKeyStd: keyof Benchmark = 'leetifyRatingStd';
	public readonly description = '';
	public readonly id = SkillId.LEETIFY_RATING;
	public readonly title = 'Leetify Rating';
}
