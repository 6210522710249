import { Benchmark } from '../../benchmarks-repository';
import { PostSign, SkillGroupId, SkillId } from '../skill';
import { FocusAreaSkill } from '../focus-area-skill';

export class CounterStrafingShotsGoodRatio extends FocusAreaSkill {
	public readonly benchmarkKeyAvg: keyof Benchmark = 'counterStrafingShotsGoodRatioAvg';
	public readonly benchmarkKeyStd: keyof Benchmark = 'counterStrafingShotsGoodRatioStd';
	public readonly calculationChangeDates = [{ date: '04.10.2020', url: 'https://leetify.com/blog/enemy-actually-spotted/' }];
	public readonly dependsOn = 'counterStrafingShotsAll';
	public readonly descriptionExtra = 'We look for whenever you have an enemy in vision and then measure your movement speed for every shot. A “good” counter-strafing shot is defined as a shot where your velocity is less than 34% of your max speed with that weapon (34% is when inaccuracy kicks in). We only include shots with a rifle and exclude any shots made while fully crouched. Finally, we divide all “good” counter-strafing shots by all counter-strafing shots.';
	public readonly description = "Moving while shooting means increased inaccuracy. This stat displays how often you're moving while you're shooting with rifles, and can help you figure out how well you're counter-strafing. It only includes when you're actually firing on an enemy and excludes movement while fully crouched.";
	public readonly detailsInfo = true;
	public readonly displayInGoodBadSummary = true;
	public readonly fallbackValue = 'n/a';
	public readonly focusAreaLowerBound = 44;
	public readonly focusAreaUpperBound = 96;
	public readonly groupIcon = 'mdi mdi-crosshairs-gps';
	public readonly groupId: SkillGroupId = 'aimSkillGroup';
	public readonly groupTitle = 'Aim group';
	public readonly icon = 'mdi mdi-arrow-all';
	public readonly id = SkillId.COUNTER_STRAFING_GOOD_SHOTS_RATIO;
	public readonly improveTip = true;
	public readonly inversed = false;
	public readonly isRatio = true;
	public readonly minValue = 0;
	public readonly postSign: PostSign = '%';
	public readonly shortTitle = 'Counter-Strafing';
	public readonly smallDescription = 'We look for whenever you have an enemy in vision and then measure your movement speed for every shot.';
	public readonly title = 'Proper Counter-Strafing';
}
