import { AfterViewInit, Component, ElementRef, Input, OnChanges, ViewChild } from '@angular/core';

/**
 * Wrap any components or HTML elements in this component to provide a smooth "vertical expansion" animation.
 */
@Component({
	selector: 'leetify-expandable-vertical',
	templateUrl: './expandable-vertical.component.html',
	styleUrls: ['./expandable-vertical.component.scss'],
})
export class ExpandableVerticalComponent implements AfterViewInit, OnChanges {
	@Input() public isActive: boolean;
	@Input() public isAriaHiddenWhenInactive = true;

	@ViewChild('content') protected readonly content: ElementRef<HTMLElement>;

	protected isExpandedClassActive: boolean;
	protected viewInitialized = false;
	protected wrapperStyle: { maxHeight?: string } = {};

	protected runAnimation(): void {
		// don't run the animation if nothing is going to change
		if (this.isExpandedClassActive === this.isActive) return;

		// if the view has not been initialized, skip the animation and just apply the class immediately
		if (!this.viewInitialized) {
			this.isExpandedClassActive = this.isActive;
			return;
		}

		// get the complete height of the explainer piece itself
		const styles = window.getComputedStyle(this.content.nativeElement);
		const height = parseFloat(styles.marginTop) + parseFloat(styles.marginBottom) + this.content.nativeElement.offsetHeight;

		// set the wrapper's max-height to the height from before, so that we can apply a transition to the max-height
		this.wrapperStyle = { maxHeight: `${height}px` };

		// wait until the previous style change has been applied (setTimeout without a time parameter)
		setTimeout(() => {
			this.isExpandedClassActive = this.isActive;

			// after the transition has finished, remove the max-height again to prevent any issues in normal display
			setTimeout(() => this.wrapperStyle = {}, 200); // 0.2 seconds
		});
	}

	public ngAfterViewInit(): void {
		this.viewInitialized = true;
	}

	public ngOnChanges(): void {
		this.runAnimation();
	}
}
