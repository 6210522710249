import { Benchmark } from '../../benchmarks-repository';
import { PlayerClutchKillsPercentage } from './player-clutch-kills-percentage';
import { SkillId } from '../skill';

export class PlayerClutch1v2KillsPercentage extends PlayerClutchKillsPercentage {
	public readonly benchmarkKeyAvg: keyof Benchmark = 'playerClutch1v2KillsPercentageAvg';
	public readonly benchmarkKeyStd: keyof Benchmark = 'playerClutch1v2KillsPercentageStd';
	id = SkillId.PLAYER_CLUTCH_1V2_KILLS_PERCENTAGE;
	title = '1v2 Clutch Kills';

	focusAreaLowerBound = 17;
	focusAreaUpperBound = 35;
}
