import { Benchmark } from '../../benchmarks-repository';
import { PostSign, Skill, SkillGroupId, SkillId } from '../skill';

export class TeamClutchKillsPercentage extends Skill {
	public readonly benchmarkKeyAvg: keyof Benchmark = 'teamClutchKillsPercentageAvg';
	public readonly benchmarkKeyStd: keyof Benchmark = 'teamClutchKillsPercentageStd';
	public groupId: SkillGroupId = 'clutchSkillGroup';
	public postSign: PostSign = '%';

	dependsOn = null;
	description = 'Percentage of possible clutch kills your team scored.';
	detailsInfo = false;
	displayInGoodBadSummary = false;
	groupTitle = 'Clutching';
	id = SkillId.TEAM_CLUTCH_KILLS_PERCENTAGE;
	improveTip = false;
	inversed = false;
	isRatio = true;
	minValue = 0;
	smallDescription = 'Percentage of possible clutch kills your team scored.';
	title = 'Team Clutch Kills';
}
