import { Benchmark } from '../../../benchmarks-repository';
import { SkillId } from '../../skill';
import { SprayAccuracyPerWeapon } from './spray-accuracy-per-weapon';
import { Weapon } from '../../../enums';

export class SprayAccuracyAug extends SprayAccuracyPerWeapon {
	public readonly benchmarkKeyAvg: keyof Benchmark = 'sprayAccuracyAugAvg';
	public readonly benchmarkKeyStd: keyof Benchmark = 'sprayAccuracyAugStd';

	id = SkillId.SPRAY_ACCURACY_AUG;
	title = 'Spray Accuracy (Aug)';
	weapon = Weapon.AUG;

	focusAreaLowerBound = 14;
	focusAreaUpperBound = 60;
}
