import { ActivatedRoute, Router } from '@angular/router';
import { Component } from '@angular/core';
import { Meta, Title } from '@angular/platform-browser';
import { GamesService } from 'src/app/services/games.service';
import { User } from 'src/app/models/user.model';
import { UserService } from 'src/app/services/user.service';
import { RedirectComponent } from './redirect.component';

@Component({
	selector: 'app-last-match-redirect',
	templateUrl: '../../../layout/attempting-login/attempting-login.component.html',
	styleUrls: ['../../../layout/attempting-login/attempting-login.component.scss'],
})
export class LastMatchRedirectComponent extends RedirectComponent {
	protected readonly path = '/last-match';

	public constructor(
		protected readonly gamesService: GamesService,
		metaService: Meta,
		route: ActivatedRoute,
		router: Router,
		titleService: Title,
		userService: UserService,
	) {
		super(metaService, route, router, titleService, userService);
	}

	protected async getRedirectUrl(_user: User): Promise<string[]> {
		try {
			const lastProcessedGame = await this.gamesService.fetchLastProcessedGame();
			if (!lastProcessedGame?.id) return ['/lol/matches'];

			return ['/lol/match-details', lastProcessedGame.id, 'overview'];
		} catch {
			return ['/lol/matches'];
		}
	}

	protected setPageMeta(): void {
		this.titleService.setTitle('Your Last Match - Leetify');
		this.metaService.updateTag({ property: 'og:title', content: 'Your Last Match' });
	}
}
