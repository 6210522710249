import { Component, Input, forwardRef } from '@angular/core';
import { ControlValueAccessor, NG_VALUE_ACCESSOR } from '@angular/forms';

export interface RadioOption {
	label: string;
	value: string | number | boolean;
	description?: string;
}

/**
 * Accepts an array of option objects to show the radio inputs.
 *
 * Utilizes regular `ngModel` directive for value binding and tracking of selected radio input.
 */
@Component({
	selector: 'leetify-radio',
	styleUrls: ['./radio.component.scss'],
	templateUrl: './radio.component.html',
	providers: [{ provide: NG_VALUE_ACCESSOR, useExisting: forwardRef(() => RadioComponent), multi: true }],
})
export class RadioComponent implements ControlValueAccessor {
	/** Mandatory to provide. Identifies input for the parent form and `onChange` event listener. */
	@Input() public name: string;

	/**
	 * Array of option objects to render radio options with values and content.
	 * Each object in the array is typeof `RadioOption` and consist of following properties:
	 *
	 * `label`: typeof `string`;
	 *
	 * `value`: typeof `string` or `number` or `boolean`;
	 *
	 * `description`: typeof `string`. Optional property. If provided will render description text under the option label.
	 */
	@Input() public options: RadioOption[];

	private _value: string | number | boolean;
	public get value(): string | number | boolean {
		return this._value;
	}

	public set value(value: string | number | boolean) {
		if (value !== this._value) {
			this._value = value;
			this.change(value);
		}
	}

	writeValue(value: string | number | boolean) {
		if (value !== this._value) {
			this._value = value;
		}
	}

	onChange: (arg: any) => void;
	registerOnChange(onChangeCallback: (arg: any) => void): void {
		this.onChange = onChangeCallback;
	}

	onTouched: (arg: any) => void;
	registerOnTouched(onTouchedCallback: (arg: any) => void): void {
		this.onTouched = onTouchedCallback;
	}

	change(value: string | number | boolean) {
		this._value = value;
		this.onChange(value);
		this.onTouched(value);
	}
}
