import { Benchmark } from '../../../benchmarks-repository';
import { RoundWinRate } from './round-win-rate';
import { SkillId } from '../../skill';
import { TeamName } from '../../../enums';

export class CtRoundWinRate extends RoundWinRate {
	public readonly benchmarkKeyAvg: keyof Benchmark = 'ctRoundWinRateAvg';
	public readonly benchmarkKeyStd: keyof Benchmark = 'ctRoundWinRateStd';

	description = 'Your won rounds on CT side, divided by all rounds you played on CT side.';
	groupTitle = 'Teamplay CT Side';
	id = SkillId.CT_ROUND_WIN_RATE;
	side = TeamName.COUNTER_TERRORIST;
	smallDescription = 'Your won rounds on CT side, divided by all rounds you played on CT side.';
}
