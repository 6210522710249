import { Benchmark } from '../../benchmarks-repository';
import { FocusAreaSkill } from '../focus-area-skill';
import { PostSign, SkillGroupId, SkillId } from '../skill';
import { TeamName } from '../../enums';

export class CtOpeningAggressionSuccessRate extends FocusAreaSkill {
	public readonly benchmarkKeyAvg: keyof Benchmark = 'ctOpeningAggressionSuccessRateAvg';
	public readonly benchmarkKeyStd: keyof Benchmark = 'ctOpeningAggressionSuccessRateStd';
	public groupId: SkillGroupId = 'positioningSkillGroup';
	public postSign: PostSign = '%';
	public readonly showChart: boolean = false;

	dependsOn = null;
	description = '';
	descriptionExtra = '';
	detailsInfo = false;
	displayInGoodBadSummary = false;
	groupIcon = 'mdi mdi-progress-wrench';
	groupTitle = 'Positioning group';
	icon = 'mdi mdi-weather-cloudy';
	id = SkillId.CT_OPENING_AGGRESSION_SUCCESS_RATE;
	improveTip = false;
	inversed = false;
	isRatio = false;
	minValue = 0;
	side = TeamName.COUNTER_TERRORIST;
	smallDescription = '';
	title = 'CT Opening Duels won without being traded';

	focusAreaLowerBound = 0;
	focusAreaUpperBound = 90;
}
