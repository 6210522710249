import { Benchmark } from '../../benchmarks-repository';
import { OpeningDuelSuccessPercentageBenchmarkT } from './opening-duel-success-percentage-benchmark-t';
import { SkillId } from '../skill';

export class OpeningDuelSuccessPercentageBenchmarkCt extends OpeningDuelSuccessPercentageBenchmarkT {
	public readonly benchmarkKeyAvg: keyof Benchmark = 'ctOpeningDuelSuccessPercentageAvg';
	public readonly benchmarkKeyStd: keyof Benchmark = 'ctOpeningDuelSuccessPercentageStd';

	id = SkillId.OPENING_DUEL_SUCCESS_PERCENTAGE_BENCHMARK_CT;
	keepValuesVerbatim = true;
}
