<div #wrapper class="wrapper" style="transform: {{ transform }}">
	<div *ngIf="!player" class="inner loading">
		<img *ngIf="isLoading" class="spinner" src="/assets/images/spinner.svg" alt="">

		<div *ngIf="!isLoading">
			<div>Profile currently not available :(</div>

			<a *ngIf="steam64Id" href="https://steamcommunity.com/profiles/{{ steam64Id }}" target="_blank" rel="noreferrer">
				Visit their Steam profile
			</a>
		</div>
	</div>

	<section *ngIf="player" class="inner mini-profile">
		<header>
			<div class="hero-avatar">
				<img class="hero" [src]="headerImageUrl" alt="" (error)="onPlayerHeaderErrored($event)">

				<div class="profiles">
					<button
						*ngIf="player.leetifyUserId && user && user?.steam64Id !== player.steam64Id"
						(click)="spectate(player)"
						title="Spectate"
						[disabled]="!player.isSensitiveDataVisible"
						[ngbTooltip]="spectateTooltip"
						tooltipClass="default-tooltip"
						openDelay="300"
					>
						<leetify-icon [icon]="player.isSensitiveDataVisible ? Icon.EYE : Icon.EYE_OFF" class="spectate-icon"></leetify-icon>
					</button>

					<a
						href="https://steamcommunity.com/profiles/{{ player.steam64Id }}"
						rel="noreferrer"
						target="_blank"
						title="Steam Profile"
					>
						<img class="logo" src="/assets/images/steam-logo.svg" alt="Steam Profile">
						<div *ngIf="isBannedOnDataSource[DataSource.MATCHMAKING]" class="ban-badge" title="User is banned on Matchmaking">Ban</div>
					</a>

					<a
						*ngIf="false"
						href="https://www.faceit.com/en/players/a"
						rel="noreferrer"
						target="_blank"
						title="Faceit Profile"
					>
						<img class="logo" src="/assets/images/faceit-logo.svg" alt="Faceit Profile">
						<div *ngIf="isBannedOnDataSource[DataSource.FACEIT]" class="ban-badge" title="User is banned on Faceit">Ban</div>
					</a>

					<a
						*ngIf="player.esportalNickname"
						href="https://esportal.com/en/profile/{{ player.esportalNickname }}"
						rel="noreferrer"
						target="_blank"
						title="Esportal Profile"
					>
						<img class="logo" src="/assets/images/esportal-logo.png" alt="Esportal Profile">
						<div *ngIf="isBannedOnDataSource[DataSource.ESPORTAL]" class="ban-badge" title="User is banned on Esportal">Ban</div>
					</a>

					<div
						*ngIf="! player.esportalNickname && isBannedOnDataSource[DataSource.ESPORTAL]"
						title="User is banned on Esportal (their Esportal account was deleted)"
						class="deleted-account-placeholder"
					>
						<img class="logo" src="/assets/images/esportal-logo.png" alt="Esportal">
						<div *ngIf="isBannedOnDataSource[DataSource.ESPORTAL]" class="ban-badge">Ban</div>
					</div>

					<a
						*ngIf="player.gamersClubPlayerId"
						href="https://csgo.gamersclub.gg/player/{{ player.gamersClubPlayerId }}"
						rel="noreferrer"
						target="_blank"
						title="Gamers Club Profile"
					>
						<img class="logo" src="/assets/images/gc-logo.png" alt="Gamers Club Profile">
						<div *ngIf="isBannedOnDataSource[DataSource.GAMERS_CLUB]" class="ban-badge" title="User is banned on Gamers Club">Ban</div>
					</a>
				</div>

				<div class="avatar-wrapper" [ngClass]="avatarAccentClass">
					<div class="avatar-border">
						<div class="border-inner">
							<div class="background">
								<img class="avatar" [src]="steamAvatarUrl" alt="" (error)="onProfilePictureErrored($event)">
							</div>
						</div>
					</div>
				</div>

				<a *ngIf="user && player.leetifyUserId === user?.id" routerLink="/app/account" class="upload-header">
					<i class="mdi mdi-upload"></i>
					Upload Cover Image
				</a>
			</div>

			<div class="rank-name-leetify">
				<div class="rank-name">
					<div
						*ngIf="ranks && ranks.primary"
						class="rank"
						(mouseenter)="isRankOverlayActive = true"
						(mouseleave)="isRankOverlayActive = false"
					>
						<img
							*ngIf="ranks.primary.dataSource === DataSource.MATCHMAKING && ranks.primary.type && ranks.primary.type !== 'premier'"
							(error)="onMapIconErrored($event)"
							[alt]="ranks.primary.type"
							[ngbTooltip]="ranks.primary.type"
							[src]="ranks.primary.type"
							class="map-icon"
							container="body"
							tooltipClass="default-tooltip"
						>
					</div>

					<h2 class="text-truncate" [title]="player.name">
						{{ player.name }}
					</h2>
				</div>

				<app-user-leetify-icon [userData]="player"></app-user-leetify-icon>
			</div>
		</header>

		<div class="inner">
			<div class="stats">
				<div *ngFor="let stat of stats" class="stat">
					<h3>{{ stat.label }}</h3>
					<div class="value">{{ stat.formattedValue ?? stat.value }}</div>

					<app-progress-bar-chart
						[fillStyle]="progressBarChartFillStyle"
						[scale]="stat.scale"
						[value]="stat.value"
						backgroundStyle="surface3"
					></app-progress-bar-chart>
				</div>
			</div>

			<div class="leetify-rating">
				<h3>Leetify Rating</h3>

				<app-score-circle-outline
					[decimalPlaces]="2"
					[distinctNegative]="true"
					[maxValue]="8"
					[minValue]="-8"
					[radius]="42"
					[showValue]="true"
					[stroke]="4"
					[value]="leetifyRating"
					[zScoreClass]="leetifyRatingZScoreLabel"
				></app-score-circle-outline>

				<div class="label text-{{ leetifyRatingZScoreLabel }}">{{ leetifyRatingZScoreLabel | titlecase }}</div>
			</div>

			<div class="recent-results">
				<h3>Recent Results</h3>

				<div class="results"
					[ngbTooltip]="matchHistoryTooltip"
					tooltipClass="default-tooltip"
					openDelay="300"
				>
					<ng-container *ngFor="let game of player.recentMatches" [ngSwitch]="game.result">
						<a *ngSwitchCase="MatchResult.WIN"
							[routerLink]="player.isSensitiveDataVisible ? ['/lol/match-details', game.id] : null"
							queryParamsHandling="merge"
							class="result text-great"
							[ngClass]="{ 'disabled': !player.isSensitiveDataVisible }"
							aria-label="Win"
						>W</a>
						<a *ngSwitchCase="MatchResult.TIE"
							[routerLink]="player.isSensitiveDataVisible ? ['/lol/match-details', game.id] : null"
							queryParamsHandling="merge"
							class="result text-average"
							[ngClass]="{ 'disabled': !player.isSensitiveDataVisible }"
							aria-label="Tie"
						>T</a>
						<a *ngSwitchCase="MatchResult.LOSS"
							[routerLink]="player.isSensitiveDataVisible ? ['/lol/match-details', game.id] : null"
							queryParamsHandling="merge"
							class="result text-poor"
							[ngClass]="{ 'disabled': !player.isSensitiveDataVisible }"
							aria-label="Loss"
						>L</a>
					</ng-container>
				</div>
			</div>
		</div>

		<div class="actions">
			<a *ngIf="compareRedirectUrl" [routerLink]="compareRedirectUrl" [target]="openCompareInNewTab ? '_blank' : undefined">
				Compare
			</a>

			<a [routerLink]="['/app/profile', player.steam64Id]">
				Profile
			</a>
		</div>

		<div class="rank-overlay" [ngClass]="{ '--active': isRankOverlayActive }">
			<div class="premier">
				<div class="label">Premier</div>
			</div>

			<div class="matchmaking-wingman">
				<div class="label">Wingman</div>
				<div *ngIf="ranks[DataSource.MATCHMAKING_WINGMAN] === undefined" class="no-rank">-</div>
			</div>

			<div class="per-map-competitive">
				<div class="label">Map Ranks</div>

				<div *ngFor="let item of ranks.perMapCompetitive | keyvalue" class="rank">
					<img [alt]="item.key" [src]="item.key" class="map-icon" (error)="onMapIconErrored($event)">
				</div>
			</div>

			<div class="faceit">
				<div class="label">Faceit</div>
				<div *ngIf="ranks[DataSource.FACEIT] === undefined" class="no-rank">-</div>
			</div>

			<div class="gamers-club">
				<div class="label">Gamers Club</div>
				<div *ngIf="ranks[DataSource.GAMERS_CLUB] === undefined" class="no-rank">-</div>
			</div>
		</div>
	</section>
</div>
