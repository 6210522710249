<div
	#backdrop
	*ngIf="active"
	[@inOutAnimation]
	(click)="onBackdropClick($event)"
	class="modal-wrapper"
>
	<div class="animation-container" [ngClass]="{ 'card-box modal-card-box': useContainer }">
		<button *ngIf="useContainer" class="close" aria-label="Close" title="Close" (click)="onCloseButtonClick()">
			<i class="mdi mdi-close"></i>
		</button>

		<ng-content></ng-content>
	</div>
</div>
