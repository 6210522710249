import { Component, Input, OnChanges, OnInit } from '@angular/core';

export enum BackgroundStyle {
	LEGACY = 'legacy',
	SURFACE1 = 'surface1',
	SURFACE2 = 'surface2',
	SURFACE3 = 'surface3',
	SURFACE4 = 'surface4',
	SURFACE5 = 'surface5',
}

@Component({
	selector: 'app-score-circle-outline',
	templateUrl: './score-circle-outline.component.html',
	styleUrls: ['./score-circle-outline.component.scss'],
})
export class ScoreCircleOutlineComponent implements OnInit, OnChanges {
	@Input()
	public value: number;

	@Input()
	public goodValue: number;

	@Input()
	public badValue: number;

	@Input()
	public showValue = true;

	@Input()
	public maxValue: number;

	@Input()
	public minValue = 0;

	@Input()
	public decimalPlaces = 0;

	@Input()
	public postSign = '';

	@Input()
	public distinctNegative = false;

	@Input()
	public zScoreClass: string | null = null;

	@Input()
	public textClass: string | null = null;

	@Input()
	public previousPeriodImprovement: number | null = null;

	@Input()
	public stroke = 2;

	@Input()
	public radius = 27;

	@Input()
	public backgroundStyle: BackgroundStyle = BackgroundStyle.LEGACY;

	public circumference: number;
	public normalizedRadius: number;
	public strokeDashoffset = 0;
	public outlineClass: string;

	ngOnChanges(): void {
		this.setValue(this.value);
	}

	ngOnInit(): void {
		this.normalizedRadius = this.radius - this.stroke * 2;
		this.circumference = this.normalizedRadius * 2 * Math.PI;
		this.setValue(0);
		// Add a little juice
		setTimeout(() => {
			this.setValue(this.value);
		}, 500);
	}

	public getPreviousPeriodIconUrl(): string {
		let iconName = 'average';
		if (this.previousPeriodImprovement > 0) iconName = 'angle-double-up';
		if (this.previousPeriodImprovement < 0) iconName = 'angle-double-down';
		return `/assets/icons/${iconName}.svg`;
	}

	public getTextYPosition(): string {
		if (this.previousPeriodImprovement === null) return '60%';
		if (this.previousPeriodImprovement > 0) return '60%';
		return '55%';
	}

	private setValue(percent: number): void {
		this.outlineClass = this.zScoreClass;

		if (!this.outlineClass) {
			if (this.value > this.goodValue) this.outlineClass = 'great';
			else if (this.value < this.badValue) this.outlineClass = 'poor';
		}

		if (!this.maxValue) return;

		const offset = this.circumference - (Math.min(1, Math.max(0, (percent - this.minValue) / (this.maxValue - this.minValue))) * this.circumference);
		this.strokeDashoffset = offset;
	}
}
