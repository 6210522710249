import { Injectable } from '@angular/core';
import { HttpClient } from '@angular/common/http';
import { PosthogTrackingValue } from 'leetify-shared-utils/enums';

@Injectable({
	providedIn: 'root',
})
export class TrackerService {
	constructor(private http: HttpClient) { }

	public track(name: PosthogTrackingValue, data: Record<string, any> = {}): void {
		this.http.post(`/api/track/${name}`, data).subscribe();
	}
}
