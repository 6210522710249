import { Benchmark } from '../../benchmarks-repository';
import { OpeningDuelTradePercentage } from './opening-duel-trade-percentage';
import { SkillId } from '../skill';
import { TeamName } from '../../enums';

export class CtOpeningDuelTradePercentage extends OpeningDuelTradePercentage {
	public readonly benchmarkKeyAvg: keyof Benchmark = 'ctOpeningDuelTradePercentageAvg';
	public readonly benchmarkKeyStd: keyof Benchmark = 'ctOpeningDuelTradePercentageStd';
	id = SkillId.CT_OPENING_DUEL_TRADE_PERCENTAGE;
	side = TeamName.COUNTER_TERRORIST;
	title = 'CT Opening Duel Traded Percentage';
}
