import { Benchmark } from '../../benchmarks-repository';
import { SkillId } from '../skill';
import { PlayerClutchWinPercentageBase } from './player-clutch-win-percentage-base';

export class PlayerClutch1v1WinPercentage extends PlayerClutchWinPercentageBase {
	public readonly benchmarkKeyAvg: keyof Benchmark = 'playerClutch1v1WinPercentageAvg';
	public readonly benchmarkKeyStd: keyof Benchmark = 'playerClutch1v1WinPercentageStd';
	public readonly focusAreaLowerBound = 39;
	public readonly focusAreaUpperBound = 63;
	public readonly id = SkillId.PLAYER_CLUTCH_1V1_WIN_PERCENTAGE;
	public readonly title = '1v1 Clutch Wins';
}
