import { Benchmark } from '../../benchmarks-repository';
import { PlayerClutchSavePercentage } from './player-clutch-save-percentage';
import { SkillId } from '../skill';

export class PlayerClutch1v2SavePercentage extends PlayerClutchSavePercentage {
	public readonly benchmarkKeyAvg: keyof Benchmark = 'playerClutch1v2SavePercentageAvg';
	public readonly benchmarkKeyStd: keyof Benchmark = 'playerClutch1v2SavePercentageStd';
	id = SkillId.PLAYER_CLUTCH_1V2_SAVE_PERCENTAGE;
	title = '1v2 Save Rate';
}
