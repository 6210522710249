import { Benchmark } from '../../../benchmarks-repository';
import { MapSideWinrate } from './map-side-win-rate';
import { SkillId } from '../../skill';
import { TeamName } from '../../../enums';

export class CtRoundWinRateInferno extends MapSideWinrate {
	public readonly benchmarkKeyAvg: keyof Benchmark = 'ctRoundWinRateInfernoAvg';
	public readonly benchmarkKeyStd: keyof Benchmark = 'ctRoundWinRateInfernoStd';

	id = SkillId.CT_ROUND_WIN_RATE_INFERNO;
	side = TeamName.COUNTER_TERRORIST;
	title = 'CT Round Win Rate Inferno';
}
