import { Benchmark } from '../../benchmarks-repository';
import { Skill, SkillId } from '../skill';

export class UtilityRating extends Skill {
	public readonly benchmarkKeyAvg: keyof Benchmark = 'utilityRatingAvg';
	public readonly benchmarkKeyStd: keyof Benchmark = 'utilityRatingStd';

	decimalPlaces = 0;
	dependsOn = null;
	description = '';
	detailsInfo = false;
	displayInGoodBadSummary = false;
	groupId = null;
	groupTitle = 'Ratings';
	id = SkillId.UTILITY_RATING;
	improveTip = false;
	inversed = false;
	isRatio = false;
	isScaledForMatch = true;
	shortTitle = 'Utility';
	smallDescription = '';
	title = 'Utility Rating';
	fallbackValue = 'n/a';
}
