import { Benchmark } from '../../benchmarks-repository';
import { SkillId } from '../skill';
import { PlayerClutchWinPercentageBase } from './player-clutch-win-percentage-base';

export class PlayerClutch1v2WinPercentage extends PlayerClutchWinPercentageBase {
	public readonly benchmarkKeyAvg: keyof Benchmark = 'playerClutch1v2WinPercentageAvg';
	public readonly benchmarkKeyStd: keyof Benchmark = 'playerClutch1v2WinPercentageStd';
	public readonly focusAreaLowerBound = 9;
	public readonly focusAreaUpperBound = 27;
	public readonly id = SkillId.PLAYER_CLUTCH_1V2_WIN_PERCENTAGE;
	public readonly title = '1v2 Clutch Wins';
}
