import { Benchmark } from '../../benchmarks-repository';
import { SkillId } from '../skill';
import { PlayerClutchWinPercentageBase } from './player-clutch-win-percentage-base';

export class PlayerClutch1v4WinPercentage extends PlayerClutchWinPercentageBase {
	public readonly benchmarkKeyAvg: keyof Benchmark = 'playerClutch1v4WinPercentageAvg';
	public readonly benchmarkKeyStd: keyof Benchmark = 'playerClutch1v4WinPercentageStd';
	public readonly focusAreaLowerBound = 0;
	public readonly focusAreaUpperBound = 3.5;
	public readonly id = SkillId.PLAYER_CLUTCH_1V4_WIN_PERCENTAGE;
	public readonly title = '1v4 Clutch Wins';
}
