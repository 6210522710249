<nav>
	<ul class="sidebar-menu">
		<li>
			<a routerLink="/lol/matches" routerLinkActive="active" queryParamsHandling="merge"
			[routerLinkActiveOptions]="{ exact: true }">
				<i class="leet-matches sidebar-icon"></i>
				Matches
			</a>
		</li>

		<ng-container *ngIf="user">
			<hr class="mobile-only">

			<li class="mobile-only">
				<a routerLink="/app/profile" routerLinkActive="active" queryParamsHandling="merge">
					<i class="fa fa-user sidebar-icon"></i>
					Profile
				</a>
			</li>

			<li class="mobile-only">
				<a routerLink="/app/account" routerLinkActive="active" queryParamsHandling="merge">
					<i class="fa fa-cog sidebar-icon"></i>
					Account Settings
				</a>
			</li>

			<li class="mobile-only">
				<a routerLink="/app/support" routerLinkActive="active" queryParamsHandling="merge">
					<i class="leet-support sidebar-icon"></i>
					Support
				</a>
			</li>

			<li class="mobile-only">
				<button (click)="logout()">
					<i class="fa fa-sign-out-alt sidebar-icon"></i>
					Logout
				</button>
			</li>
		</ng-container>
	</ul>

	<div class="sidebar-social">
		<a
			[queryParams]="{ redirect: 'no' }"
			aria-label="Home Page"
			container="body"
			ngbTooltip="Go to Home Page"
			openDelay="300"
			routerLink="/"
			tooltipClass="default-tooltip"
		>
			<leetify-icon [icon]="Icon.HOME"></leetify-icon>
		</a>

		<a *ngFor="let social of socialMediaLinks" [href]="social.link" target="_blank" [attr.aria-label]="social.title">
			<leetify-icon [icon]="social.icon"></leetify-icon>
		</a>
	</div>
</nav>
