import { Benchmark } from '../../../benchmarks-repository';
import { Skill, SkillGroupId, SkillId } from '../../skill';
import { TeamName } from '../../../enums';

export class TOpeningDuelsRating extends Skill {
	public readonly benchmarkKeyAvg: keyof Benchmark = 'tOpeningDuelsRatingAvg';
	public readonly benchmarkKeyStd: keyof Benchmark = 'tOpeningDuelsRatingStd';
	public groupId: SkillGroupId = 'teamplayDuelsGroup';

	dependsOn = null;
	description = '';
	detailsInfo = false;
	displayInGoodBadSummary = false;
	groupTitle = 'Teamplay T Side';
	id = SkillId.T_OPENING_DUELS_RATING;
	improveTip = false;
	inversed = false;
	isRatio = true;
	minValue = 0;
	side = TeamName.TERRORIST;
	smallDescription = '';
	title = 'Opening Duels Rating';
}
