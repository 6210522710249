import { Benchmark } from '../../benchmarks-repository';
import { Skill, SkillGroupId, SkillId } from '../skill';
import { TeamName } from '../../enums';

export class TTradeRating extends Skill {
	public readonly benchmarkKeyAvg: keyof Benchmark = 'tTradeRatingAvg';
	public readonly benchmarkKeyStd: keyof Benchmark = 'tTradeRatingStd';
	public groupId: SkillGroupId = 'teamplayDuelsGroup';

	dependsOn = null;
	description = null;
	detailsInfo = false;
	displayInGoodBadSummary = false;
	groupTitle = 'Teamplay T Side';
	id = SkillId.T_TRADE_RATING;
	improveTip = false;
	inversed = false;
	isRatio = true;
	minValue = 0;
	side = TeamName.TERRORIST;
	smallDescription = null;
	title = 'Trade Rating';
}
