import { PostSign, Skill, SkillGroupId } from '../skill';

export abstract class PlayerClutchSavePercentage extends Skill {
	public groupId: SkillGroupId = 'clutchSkillGroup';
	public postSign: PostSign = '%';

	dependsOn = null;
	description = '';
	detailsInfo = false;
	displayInGoodBadSummary = false;
	groupTitle = 'Clutching';
	improveTip = false;
	inversed = false;
	isRatio = true;
	minValue = 0;
	smallDescription = '';
}
