import { Benchmark } from '../../benchmarks-repository';
import { PlayerClutchKillsPercentage } from './player-clutch-kills-percentage';
import { SkillId } from '../skill';

export class PlayerClutch1v1KillsPercentage extends PlayerClutchKillsPercentage {
	public readonly benchmarkKeyAvg: keyof Benchmark = 'playerClutch1v1KillsPercentageAvg';
	public readonly benchmarkKeyStd: keyof Benchmark = 'playerClutch1v1KillsPercentageStd';
	id = SkillId.PLAYER_CLUTCH_1V1_KILLS_PERCENTAGE;
	title = '1v1 Clutch Kills';

	focusAreaLowerBound = 28;
	focusAreaUpperBound = 52;
}
