import { Skill, SkillId } from '../skill';

export class TradedDeathAttempts extends Skill {
	public readonly benchmarkKeyAvg = undefined;
	public readonly benchmarkKeyStd = undefined;

	dependsOn = null;
	description = '';
	descriptionExtra = '';
	detailsInfo = false;
	displayInGoodBadSummary = false;
	groupId = null;
	groupTitle = 'Positioning group';
	id = SkillId.TRADED_DEATH_ATTEMPTS;
	improveTip = false;
	inversed = false;
	isRatio = false;
	minValue = 0;
	smallDescription = '';
	title = 'Traded Death Attempts';
}
