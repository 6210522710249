import { Benchmark } from '../../benchmarks-repository';
import { SkillId } from '../skill';
import { PlayerClutchWinPercentageBase } from './player-clutch-win-percentage-base';

export class PlayerClutch1v5WinPercentage extends PlayerClutchWinPercentageBase {
	public readonly benchmarkKeyAvg: keyof Benchmark = 'playerClutch1v5WinPercentageAvg';
	public readonly benchmarkKeyStd: keyof Benchmark = 'playerClutch1v5WinPercentageStd';
	public readonly focusAreaLowerBound = 0;
	public readonly focusAreaUpperBound = 2.5;
	public readonly id = SkillId.PLAYER_CLUTCH_1V5_WIN_PERCENTAGE;
	public readonly title = '1v5 Clutch Wins';
}
