import { HttpClient } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { Subject } from 'rxjs';
import { MiniProfileDTO } from 'leetify-shared-utils/dto';
import { Dictionary } from 'leetify-shared-utils/types';

@Injectable({
	providedIn: 'root',
})
export class MiniProfilesService {
	public readonly miniProfile$ = new Subject<MiniProfileDTO>();

	// save promises instead of resolved profiles, to avoid querying the same mini profile a second time while a first request is still loading
	protected miniProfilePromises: Dictionary<Promise<MiniProfileDTO>> = {};

	public constructor(
		protected readonly http: HttpClient,
	) {
		//
	}

	public getMiniProfile(steam64Id: string): Promise<MiniProfileDTO> {
		if (!this.miniProfilePromises[steam64Id]) {
			this.miniProfilePromises[steam64Id] = this.http.get<MiniProfileDTO>(`/api/mini-profiles/${steam64Id}`)
				.toPromise()
				.then((miniProfile) => {
					this.miniProfile$.next(miniProfile);
					return miniProfile;
				});
		}

		return this.miniProfilePromises[steam64Id];
	}

	public getLoadedMiniProfile(steam64Id: string): Promise<MiniProfileDTO> {
		return this.miniProfilePromises[steam64Id];
	}

	public clearCache(): void {
		this.miniProfilePromises = {};
	}

	public writeToCache(miniProfile: MiniProfileDTO): void {
		this.miniProfilePromises[miniProfile.steam64Id] = Promise.resolve(miniProfile);
	}
}
