import { AfterContentChecked, ChangeDetectorRef, Component, OnDestroy, OnInit } from '@angular/core';
import { Subject } from 'rxjs';
import { takeUntil } from 'rxjs/operators';
import { OnboardingStatus, OnboardingStates } from 'leetify-shared-utils/enums';
import { AuthService } from 'src/app/auth/auth.service';
import { OnboardingStateService } from 'src/app/services/onboarding-state.service';
import { User } from 'src/app/models/user.model';
import { UserService } from 'src/app/services/user.service';

@Component({
	selector: 'app-onboarding-layout',
	templateUrl: './onboarding-layout.component.html',
	styleUrls: ['./onboarding-layout.component.scss'],
})
export class OnboardingLayoutComponent implements AfterContentChecked, OnDestroy, OnInit {
	public OnboardingStates = OnboardingStates;

	public onboardingState: OnboardingStates;
	public user: User;

	protected ngUnsubscribe = new Subject();

	public constructor(
		protected authService: AuthService,
		protected userService: UserService,
		protected onboardingStateService: OnboardingStateService,
		protected cdref: ChangeDetectorRef,
	) {
		//
	}

	public onLogout(): void {
		this.authService.logout();
		window.location.href = '/';
	}

	public isOnboardingStateActive(state: OnboardingStates): boolean {
		return this.onboardingState === state;
	}

	public ngOnInit(): void {
		this.user = this.userService.user;
		this.userService.user$.pipe(takeUntil(this.ngUnsubscribe)).subscribe((user) => this.user = user);
		this.onboardingStateService.nextOnboardingState$.pipe(takeUntil(this.ngUnsubscribe)).subscribe((state: OnboardingStates) => this.onboardingState = state);

		this.userService.reloadUser();
	}

	public ngOnDestroy(): void {
		this.ngUnsubscribe.next(null);
		this.ngUnsubscribe.complete();
	}

	public ngAfterContentChecked(): void {
		this.cdref.detectChanges();
	}

	public getOnboardingStateLabelClass(state: OnboardingStates): string {
		if (!this.user) return '';

		let stateLabelClass = '';

		if (state === OnboardingStates.STEAM && !this.isOnboardingStateActive(state) && this.user.steam64Id) stateLabelClass = 'text-success';

		if (
			state === OnboardingStates.DATA_SOURCES
			&& !this.isOnboardingStateActive(state) && this.isOnboardingStateActive(OnboardingStates.STEAM)
		) { stateLabelClass = ''; }

		if (
			state === OnboardingStates.DATA_SOURCES
			&& !this.isOnboardingStateActive(state) && !this.isOnboardingStateActive(OnboardingStates.MATCHMAKING) && !this.isOnboardingStateActive(OnboardingStates.ESPORTAL)
			&& this.user.steam64Id
		) { stateLabelClass = 'text-success'; }

		if ((state === OnboardingStates.STEAM_BOT || state === OnboardingStates.DISCORD)
			&& !this.isOnboardingStateActive(state)
		) { stateLabelClass = ''; }

		if (state === OnboardingStates.STEAM_BOT && !this.isOnboardingStateActive(state) && this.isOnboardingStateActive(OnboardingStates.DISCORD)) {
			stateLabelClass = 'text-success';
		}

		return stateLabelClass;
	}
}
