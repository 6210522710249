import { GameDTO } from './dto';
import { Skill } from './skills-repository';
import { SkillId } from './skills/skill';

const isNil = (v: any): boolean => v === undefined || v === null;

export class CalculatorHelper {
	static averageOf(array: number[], precision = 2): number {
		if (!array || !array.length) return 0;
		array = array.filter((a) => !isNil(a) && !Number.isNaN(a));

		if (!array.length) return 0;

		return parseFloat((array.map((a) => a * 1).reduce((a, b) => a += b) / array.length).toFixed(precision));
	}

	static weightedAverage<T extends Record<string, number>>(array: T[], precision = 2, valueKey: keyof T = 'value', weightKey: keyof T = 'weight'): number {
		if (!array?.length) return 0;

		let sum = 0;
		let totalWeight = 0;

		for (const item of array) {
			if (!item) continue;

			sum += (item[valueKey] || 0) * (item[weightKey] || 0);
			totalWeight += item[weightKey] || 0;
		}

		return parseFloat(((sum / (totalWeight || 1)) || 0).toFixed(precision));
	}

	static standardDeviationOf(array: number[], precision = 2): number {
		if (!array || !array.length || array.length < 2) return 0;
		array = array.filter((a) => a !== null);

		if (!array.length) return 0;

		const mean: number = CalculatorHelper.averageOf(array, 10);
		const totalVariance: number = array.map((a) => a * 1).reduce((a, b) => a += (b - mean) ** 2);

		return parseFloat(Math.sqrt(totalVariance / (array.length - 1)).toFixed(precision));
	}

	public static weightedStandardDeviation<T extends Record<string, number>>(array: T[], precision = 2, valueKey: keyof T = 'value', weightKey: keyof T = 'weight'): number {
		if (!array || !array.length || array.length < 2) return 0;

		const weightedAverage = CalculatorHelper.weightedAverage(array, precision, valueKey, weightKey);
		const sumOfWeights = array.reduce((c, v) => c + v[weightKey], 0);

		// TODO clean up (?)
		const weightedStddev = Math.sqrt(
			array.reduce((c, v) => c + (v[weightKey] * ((v[valueKey] - weightedAverage) ** 2)), 0)
			/ ((array.length - 1) / array.length * sumOfWeights),
		);

		return Number(weightedStddev.toFixed(precision));
	}

	static isAvailable(skillId: Skill['id'], periodGames: GameDTO[]): boolean {
		if (!periodGames) return false;

		const games: GameDTO[] = periodGames.slice(0);

		const skillValues = CalculatorHelper.getSkillValues(skillId, games);

		return skillValues.length !== 0;
	}

	static averageOfPeriod(skillId: Skill['id'], periodGames: GameDTO[]): number {
		if (!periodGames?.length) return 0;

		const skillValues = CalculatorHelper.getSkillValues(skillId, periodGames);

		return CalculatorHelper.weightedAverage(skillValues);
	}

	static limit(value: number, min = 0, max = 100): number {
		return Math.min(max, Math.max(min, value));
	}

	static getSkillValues(skillId: Skill['id'], games: GameDTO[]): { value: number; weight: number }[] {
		switch (skillId) {
			case SkillId.HE_FOES_DAMAGE_AVG:
			case SkillId.HE_FRIENDS_DAMAGE_AVG:
				games = games.filter((game) => game.playerStats[0].heThrown > 0);
				break;
		}

		return games.map((game) => {
			const roundsPlayed = game.playerStats?.[0]
				? game.playerStats[0].ctRoundsWon + game.playerStats[0].ctRoundsLost + game.playerStats[0].tRoundsWon + game.playerStats[0].tRoundsLost
				: 0;

			if (!isNil(game.playerStats?.[0]?.[skillId])) {
				return { value: game.playerStats[0][skillId], weight: roundsPlayed };
			}

			if (!isNil(game.openingDuelPlayerStats?.[0]?.[skillId])) {
				return { value: game.openingDuelPlayerStats[0][skillId], weight: roundsPlayed };
			}

			return null;
		});
	}

	static getSkillCdf(value: number, mean: number, stdev: number): number {
		function erfc(x: number): number {
			const z = Math.abs(x);
			const t = 1 / (1 + z / 2);
			const r = t * Math.exp(-z * z - 1.26551223 + t * (1.00002368
				+ t * (0.37409196 + t * (0.09678418 + t * (-0.18628806
				+ t * (0.27886807 + t * (-1.13520398 + t * (1.48851587
				+ t * (-0.82215223 + t * 0.17087277)))))))));

			return (x >= 0 ? r : 2 - r);
		}
		return 0.5 * erfc(-(value - mean) / (stdev * Math.sqrt(2)));
	}

	static rescale(value: number, oldMin: number, oldMax: number, newMin: number, newMax: number, limit = true): number {
		const rescaled = (newMax - newMin) * ((value - oldMin) / (oldMax - oldMin)) + newMin;
		if (!limit) return rescaled;

		return CalculatorHelper.limit(rescaled, newMin, newMax);
	}

	// Puts more users at closer to 100 while keeping low values higher
	static rescaleSkillRating(value: number): number {
		return Math.min(1, Math.max(0, Math.exp(4.56 * value - 3.63) + 0.245));
	}
}
