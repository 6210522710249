import { Benchmark } from '../../benchmarks-repository';
import { Skill, SkillId } from '../skill';

export class AimRating extends Skill {
	public readonly benchmarkKeyAvg: keyof Benchmark = 'aimRatingAvg';
	public readonly benchmarkKeyStd: keyof Benchmark = 'aimRatingStd';

	decimalPlaces = 0;
	dependsOn = null;
	description = '';
	detailsInfo = false;
	displayInGoodBadSummary = false;
	groupId = null;
	groupTitle = 'Ratings';
	id = SkillId.AIM_RATING;
	improveTip = false;
	inversed = false;
	isRatio = false;
	isScaledForMatch = true;
	shortTitle = 'Aim';
	smallDescription = '';
	title = 'Aim Rating';
	fallbackValue = 'n/a';
}
