import { Skill, SkillId } from '../skill';

export class FlashbangHitFoe extends Skill {
	public readonly benchmarkKeyAvg = undefined;
	public readonly benchmarkKeyStd = undefined;
	public readonly decimalPlaces = 0;
	public readonly dependsOn = null;
	public readonly description = '';
	public readonly detailsInfo = false;
	public readonly displayInGoodBadSummary = false;
	public readonly groupId = null;
	public readonly groupTitle = 'Utility';
	public readonly id = SkillId.FLASHBANG_HIT_FOE;
	public readonly improveTip = false;
	public readonly inversed = false;
	public readonly isRatio = false;
	public readonly shortTitle = 'Enemies Flashed';
	public readonly smallDescription = '';
	public readonly title = 'Total Enemies Flashed';
}
