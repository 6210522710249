import { Benchmark } from '../../../benchmarks-repository';
import { PostSign, Skill, SkillGroupId, SkillId } from '../../skill';
import { TeamName } from '../../../enums';

export class TLeetifyRating extends Skill {
	public readonly benchmarkKeyAvg: keyof Benchmark = 'leetifyRatingNormalizedAvg';
	public readonly benchmarkKeyStd: keyof Benchmark = 'leetifyRatingNormalizedStd';
	public readonly dependsOn = null;
	public readonly description = 'Your Leetify Rating. Leetify Rating is a win rate impact based player rating system.';
	public readonly detailsInfo = false;
	public readonly displayInGoodBadSummary = false;
	public readonly groupId: SkillGroupId = 'teamplaySideGroup';
	public readonly groupTitle = 'Ratings';
	public readonly id = SkillId.T_LEETIFY_RATING;
	public readonly improveTip = false;
	public readonly inversed = false;
	public readonly isRatio = false;
	public readonly isScaledForMatch = true;
	public readonly keepValuesVerbatim = true;
	public readonly minValue = null;
	public readonly postSign: PostSign = '+';
	public readonly showPositiveSign = true;
	public readonly side = TeamName.TERRORIST;
	public readonly smallDescription = 'Your Leetify Rating. Leetify Rating is a win rate impact based player rating system.';
	public readonly title = 'Leetify Rating';
	public readonly titleWithSide = 'Leetify Rating (T Side)';
}
