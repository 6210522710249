import { Benchmark } from '../../benchmarks-repository';
import { Skill, SkillGroupId, SkillId } from '../skill';

export class HeFriendsDamageAvg extends Skill {
	public readonly benchmarkKeyAvg: keyof Benchmark = 'heFriendsDamageAvg';
	public readonly benchmarkKeyStd: keyof Benchmark = 'heFriendsDamageStd';
	public groupId: SkillGroupId = 'utilitySkillGroup';

	decimalPlaces = 2;
	dependsOn = 'heThrown';
	description = 'The average amount of damage you do to teammates with your HE grenades. High numbers here means you need to be more careful with your HE grenades, or your team mates might go in to an unstoppable rage.';
	descriptionExtra = 'Same as Damage to Enemies per HE but for teammates.';
	detailsInfo = false;
	displayInGoodBadSummary = true;
	fallbackValue = 'n/a';
	groupIcon = 'mdi mdi-progress-wrench';
	groupTitle = 'Utility group';
	icon = 'mdi mdi-star-off';
	id = SkillId.HE_FRIENDS_DAMAGE_AVG;
	improveTip = false;
	inversed = true;
	isRatio = false;
	minValue = 0;
	shortTitle = 'Avg HE team damage';
	smallDescription = 'All HE damage to teammates divided by the number of HEs thrown.';
	title = 'Damage to Teammates per HE';
}
