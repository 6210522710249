<ng-template #buttonContent>
	<leetify-icon *ngIf="iconLeft || isWide" [icon]="iconLeft || Icon.PLACEHOLDER"></leetify-icon>

	<span>{{ label }}</span>
	<ng-content></ng-content>

	<leetify-icon *ngIf="iconRight || isWide" [icon]="iconRight || Icon.PLACEHOLDER"></leetify-icon>
</ng-template>

@if (routerLink) {
	<a
		[class]="classNames"
		[fragment]="fragment"
		[ngClass]="{ '--disabled': isDisabled }"
		[queryParamsHandling]="queryParamsHandling"
		[queryParams]="queryParams"
		[relativeTo]="relativeTo"
		[rel]="rel"
		[routerLink]="isDisabled ? undefined : routerLink"
		[target]="target"
	>
		<ng-container [ngTemplateOutlet]="buttonContent"></ng-container>
	</a>
} @else if (href) {
	<a
		[class]="classNames"
		[href]="isDisabled ? undefined : href"
		[ngClass]="{ '--disabled': isDisabled }"
		[rel]="rel"
		[target]="target"
	>
		<ng-container [ngTemplateOutlet]="buttonContent"></ng-container>
	</a>
} @else {
	<button
		[class]="classNames"
		[type]="isSubmit ? 'submit' : 'button'"
		[disabled]="isDisabled ? 'disabled' : undefined"
	>
		<ng-container [ngTemplateOutlet]="buttonContent"></ng-container>
	</button>
}
