import { AccuracyEnemySpotted } from './skills/aim/accuracy-enemy-spotted';
import { Accuracy } from './skills/aim/accuracy';
import { AccuracyHead } from './skills/aim/accuracy-head';
import { Adr } from './skills/match-details/adr';
import { AdvProportionRate } from './skills/teamplay/situation/adv-proportion-rate';
import { AdvWinRate } from './skills/teamplay/situation/adv-win-rate';
import { AimRating } from './skills/match-details/aim-rating';
import { AirborneKillsPerGame } from './skills/progress-report-2024/airborne-kills-per-game';
import { BlindedKillsPerGame } from './skills/progress-report-2024/blinded-kills-per-game';
import { CounterStrafingShotsGoodRatio } from './skills/aim/counter-strafing-shots-good-ratio';
import { CtBombsiteHoldRate } from './skills/teamplay/side/ct-bombsite-hold-rate';
import { CtBombsiteRetakeRate } from './skills/teamplay/side/ct-bombsite-retake-rate';
import { CtKdRatio } from './skills/teamplay/side/ct-kd-ratio';
import { CtLeetifyRating } from './skills/teamplay/side/ct-leetify-rating';
import { CtOpeningAggressionSuccessRate } from './skills/positioning/ct-opening-aggression-success-rate';
import { CtOpeningDuelAttemptsPercentage } from './skills/positioning/ct-opening-duel-attempts-percentage';
import { CtOpeningDuelsRating } from './skills/positioning/ct-opening-duels-rating';
import { CtOpeningDuelSuccessPercentage } from './skills/positioning/ct-opening-duel-success-percentage';
import { CtOpeningDuelTradePercentage } from './skills/positioning/ct-opening-duel-trade-percentage';
import { CtOpeningRating } from './skills/positioning/ct-opening-rating';
import { CtPositioningRating } from './skills/teamplay/side/ct-positioning-rating';
import { CtRoundWinRateAncient } from './skills/focus-area/map-side-win-rates/ct-round-win-rate-ancient';
import { CtRoundWinRateDust2 } from './skills/focus-area/map-side-win-rates/ct-round-win-rate-dust2';
import { CtRoundWinRate } from './skills/teamplay/side/ct-round-win-rate';
import { CtRoundWinRateInferno } from './skills/focus-area/map-side-win-rates/ct-round-win-rate-inferno';
import { CtRoundWinRateMirage } from './skills/focus-area/map-side-win-rates/ct-round-win-rate-mirage';
import { CtRoundWinRateNuke } from './skills/focus-area/map-side-win-rates/ct-round-win-rate-nuke';
import { CtRoundWinRateOverpass } from './skills/focus-area/map-side-win-rates/ct-round-win-rate-overpass';
import { CtRoundWinRateVertigo } from './skills/focus-area/map-side-win-rates/ct-round-win-rate-vertigo';
import { CtTradedDeathAttemptsPercentage } from './skills/positioning/ct-traded-death-attempts-percentage';
import { CtTradedDeathsOpportunitiesPerRound } from './skills/positioning/ct-traded-deaths-opportunities-per-round';
import { CtTradedDeathsSuccessPercentage } from './skills/positioning/ct-traded-deaths-success-percentage';
import { CtTradeKillAttemptsPercentage } from './skills/positioning/ct-trade-kill-attempts-percentage';
import { CtTradeKillOpportunitiesPerRound } from './skills/positioning/ct-trade-kill-opportunities-per-round';
import { CtTradeKillsSuccessPercentage } from './skills/positioning/ct-trade-kills-success-percentage';
import { CtTradeRating } from './skills/teamplay/duels/ct-trade-rating';
import { DamagePerShot } from './skills/match-details/damage-per-shot';
import { DisadvProportionRate } from './skills/teamplay/situation/disadv-proportion-rate';
import { DisadvWinRate } from './skills/teamplay/situation/disadv-win-rate';
import { FlashbangHitFoeAvgDuration } from './skills/utility/flashbang-hit-foe-avg-duration';
import { FlashbangHitFoe } from './skills/match-details/flashbang-hit-foe';
import { FlashbangHitFoePerFlashbang } from './skills/utility/flashbang-hit-foe-per-flashbang';
import { FlashbangHitFriendPerFlashbang } from './skills/utility/flashbang-hit-friend-per-flashbang';
import { FlashbangLeadingToKill } from './skills/utility/flashbang-leading-to-kill';
import { FlashbangScore } from './skills/focus-area/flashbang-score';
import { FlashbangThrown } from './skills/match-details/flashbang-thrown';
import { HeadshotKillPercentage } from './skills/match-details/headshot-kill-percentage';
import { HeFoesDamageAvg } from './skills/utility/he-foes-damage-avg';
import { HeFoesDamage } from './skills/match-details/he-foes-damage';
import { HeFriendsDamageAvg } from './skills/utility/he-friends-damage-avg';
import { HeThrown } from './skills/match-details/he-thrown';
import { HltvRating } from './skills/match-details/hltv-rating';
import { KdRatio } from './skills/match-details/kd-ratio';
import { KillDifference } from './skills/match-details/kill-difference';
import { KillsThroughSmokePerGame } from './skills/progress-report-2024/kills-through-smoke-per-game';
import { KnifeKillsPerGame } from './skills/progress-report-2024/knife-kills-per-game';
import { LeetifyRating } from './skills/general/leetify-rating';
import { LeetifyRatingNormalized } from './skills/general/leetify-rating-normalized';
import { MolotovFoesDamage } from './skills/match-details/molotov-foes-damage';
import { MolotovThrown } from './skills/match-details/molotov-thrown';
import { Multikill2Rounds } from './skills/match-details/multikill-2-rounds';
import { Multikill3Rounds } from './skills/match-details/multikill-3-rounds';
import { Multikill4Rounds } from './skills/match-details/multikill-4-rounds';
import { Multikill5Rounds } from './skills/match-details/multikill-5-rounds';
import { OpeningDuelAttemptsPercentageBenchmarkCt } from './skills/match-details/opening-duel-attempts-percentage-benchmark-ct';
import { OpeningDuelAttemptsPercentageBenchmarkT } from './skills/match-details/opening-duel-attempts-percentage-benchmark-t';
import { OpeningDuelBestWeapon } from './skills/match-details/opening-duel-best-weapon';
import { OpeningDuelMostDiedToWeapon } from './skills/match-details/opening-duel-most-died-to-weapon';
import { OpeningDuelMostKilledPlayer } from './skills/match-details/opening-duel-most-killed-player';
import { OpeningDuelSuccessPercentageBenchmarkCt } from './skills/match-details/opening-duel-success-percentage-benchmark-ct';
import { OpeningDuelSuccessPercentageBenchmarkT } from './skills/match-details/opening-duel-success-percentage-benchmark-t';
import { OpeningDuelTradePercentageBenchmarkCt } from './skills/match-details/opening-duel-trade-percentage-benchmark-ct';
import { OpeningDuelTradePercentageBenchmarkT } from './skills/match-details/opening-duel-trade-percentage-benchmark-t';
import { OpeningRating } from './skills/general/opening-rating';
import { PersonalPerformanceRating } from './skills/general/personal-performance-rating';
import { PlayerClutch1v1KillsPercentage } from './skills/clutch/player-clutch-1v1-kills-percentage';
import { PlayerClutch1v1SavePercentage } from './skills/clutch/player-clutch-1v1-save-percentage';
import { PlayerClutch1v1WinPercentage } from './skills/clutch/player-clutch-1v1-win-percentage';
import { PlayerClutch1v2KillsPercentage } from './skills/clutch/player-clutch-1v2-kills-percentage';
import { PlayerClutch1v2SavePercentage } from './skills/clutch/player-clutch-1v2-save-percentage';
import { PlayerClutch1v2WinPercentage } from './skills/clutch/player-clutch-1v2-win-percentage';
import { PlayerClutch1v3KillsPercentage } from './skills/clutch/player-clutch-1v3-kills-percentage';
import { PlayerClutch1v3SavePercentage } from './skills/clutch/player-clutch-1v3-save-percentage';
import { PlayerClutch1v3WinPercentage } from './skills/clutch/player-clutch-1v3-win-percentage';
import { PlayerClutch1v4KillsPercentage } from './skills/clutch/player-clutch-1v4-kills-percentage';
import { PlayerClutch1v4SavePercentage } from './skills/clutch/player-clutch-1v4-save-percentage';
import { PlayerClutch1v4WinPercentage } from './skills/clutch/player-clutch-1v4-win-percentage';
import { PlayerClutch1v5KillsPercentage } from './skills/clutch/player-clutch-1v5-kills-percentage';
import { PlayerClutch1v5SavePercentage } from './skills/clutch/player-clutch-1v5-save-percentage';
import { PlayerClutch1v5WinPercentage } from './skills/clutch/player-clutch-1v5-win-percentage';
import { PlayerClutchKillsPercentage } from './skills/clutch/player-clutch-kills-percentage';
import { PlayerClutchRating } from './skills/clutch/player-clutch-rating';
import { PlayerClutchWinPercentage } from './skills/clutch/player-clutch-win-percentage';
import { PositioningRating } from './skills/match-details/positioning-rating';
import { Preaim } from './skills/aim/preaim';
import { ReactionTime } from './skills/aim/reaction-time';
import { RoundsSurvived } from './skills/match-details/rounds-survived';
import { RoundsSurvivedPercentage } from './skills/match-details/rounds-survived-percentage';
import { ShotsFired } from './skills/match-details/shots-fired';
import { Skill, SkillId } from './skills/skill';
import { SmokeThrown } from './skills/match-details/smoke-thrown';
import { SprayAccuracyAk47 } from './skills/focus-area/spray-accuracy/spray-accuracy-ak47';
import { SprayAccuracyAug } from './skills/focus-area/spray-accuracy/spray-accuracy-aug';
import { SprayAccuracy } from './skills/aim/spray-accuracy';
import { SprayAccuracyM4A1S } from './skills/focus-area/spray-accuracy/spray-accuracy-m4a1-s';
import { SprayAccuracyM4A4 } from './skills/focus-area/spray-accuracy/spray-accuracy-m4a4';
import { SprayAccuracySg553 } from './skills/focus-area/spray-accuracy/spray-accuracy-sg553';
import { TaserKillsPerGame } from './skills/progress-report-2024/taser-kills-per-game';
import { TBombsiteAfterplantRate } from './skills/teamplay/side/t-bombsite-afterplant-rate';
import { TBombsiteTakeRate } from './skills/teamplay/side/t-bombsite-take-rate';
import { TeamClutchAttemptsPercentage } from './skills/clutch/team-clutch-attempts-percentage';
import { TeamClutchKillsPercentage } from './skills/clutch/team-clutch-kills-percentage';
import { TKdRatio } from './skills/teamplay/side/t-kd-ratio';
import { TLeetifyRating } from './skills/teamplay/side/t-leetify-rating';
import { TOpeningAggressionSuccessRate } from './skills/positioning/t-opening-aggression-success-rate';
import { TOpeningDuelAttemptsPercentage } from './skills/positioning/t-opening-duel-attempts-percentage';
import { TOpeningDuelsRating } from './skills/teamplay/duels/t-opening-duels-rating';
import { TOpeningDuelSuccessPercentage } from './skills/positioning/t-opening-duel-success-percentage';
import { TOpeningDuelTradePercentage } from './skills/positioning/t-opening-duel-trade-percentage';
import { TOpeningRating } from './skills/positioning/t-opening-rating';
import { TotalAssists } from './skills/match-details/total-assists';
import { TotalDamage } from './skills/match-details/total-damage';
import { TotalDeaths } from './skills/match-details/total-deaths';
import { TotalGrenadesThrownPerRound } from './skills/focus-area/total-grenades-thrown-per-round';
import { TotalKills } from './skills/match-details/total-kills';
import { TPositioningRating } from './skills/teamplay/side/t-positioning-rating';
import { TradedDeathAttempts } from './skills/match-details/traded-death-attempts';
import { TradedDeathAttemptsPercentage } from './skills/positioning/traded-death-attempts-percentage';
import { TradedDeathRate } from './skills/positioning/traded-death-rate';
import { TradedDeathsOpportunities } from './skills/match-details/traded-deaths-opportunities';
import { TradedDeathsOpportunitiesPerRound } from './skills/positioning/traded-deaths-opportunities-per-round';
import { TradedDeathsSuccess } from './skills/match-details/traded-deaths-success';
import { TradedDeathsSuccessPercentage } from './skills/positioning/traded-deaths-success-percentage';
import { TradeKillAttempts } from './skills/match-details/trade-kill-attempts';
import { TradeKillAttemptsPercentage } from './skills/positioning/trade-kill-attempts-percentage';
import { TradeKillOpportunities } from './skills/match-details/trade-kill-opportunities';
import { TradeKillOpportunitiesPerRound } from './skills/positioning/trade-kill-opportunities-per-round';
import { TradeKillsSuccess } from './skills/match-details/trade-kills-success';
import { TradeKillsSuccessPercentage } from './skills/positioning/trade-kills-success-percentage';
import { TRoundWinRateAncient } from './skills/focus-area/map-side-win-rates/t-round-win-rate-ancient';
import { TRoundWinRateDust2 } from './skills/focus-area/map-side-win-rates/t-round-win-rate-dust2';
import { TRoundWinRate } from './skills/teamplay/side/t-round-win-rate';
import { TRoundWinRateInferno } from './skills/focus-area/map-side-win-rates/t-round-win-rate-inferno';
import { TRoundWinRateMirage } from './skills/focus-area/map-side-win-rates/t-round-win-rate-mirage';
import { TRoundWinRateNuke } from './skills/focus-area/map-side-win-rates/t-round-win-rate-nuke';
import { TRoundWinRateOverpass } from './skills/focus-area/map-side-win-rates/t-round-win-rate-overpass';
import { TRoundWinRateVertigo } from './skills/focus-area/map-side-win-rates/t-round-win-rate-vertigo';
import { TTradedDeathAttemptsPercentage } from './skills/positioning/t-traded-death-attempts-percentage';
import { TTradedDeathsOpportunitiesPerRound } from './skills/positioning/t-traded-deaths-opportunities-per-round';
import { TTradedDeathsSuccessPercentage } from './skills/positioning/t-traded-deaths-success-percentage';
import { TTradeKillAttemptsPercentage } from './skills/positioning/t-trade-kill-attempts-percentage';
import { TTradeKillOpportunitiesPerRound } from './skills/positioning/t-trade-kill-opportunities-per-round';
import { TTradeKillsSuccessPercentage } from './skills/positioning/t-trade-kills-success-percentage';
import { TTradeRating } from './skills/positioning/t-trade-rating';
import { UntradedKillsRate } from './skills/teamplay/situation/untraded-kills-rate';
import { UtilityDamage } from './skills/match-details/utility-damage';
import { UtilityOnDeathAvg } from './skills/utility/utility-on-death-avg';
import { UtilityRating } from './skills/match-details/utility-rating';
import { WallbangKillsPerGame } from './skills/progress-report-2024/wallbang-kills-per-game';
import { WinRateWithoutPlayer } from './skills/teamplay/situation/player/win-rate-without-player';
import { WinRateWithPlayer } from './skills/teamplay/situation/player/win-rate-with-player';

// TODO deprecated
export { Skill, SkillId };

export class Skills {
	static skillConfigs: Skill[] = [
		new AccuracyHead(),
		new AccuracyEnemySpotted(),
		new Accuracy(),
		new SprayAccuracy(),
		new CounterStrafingShotsGoodRatio(),
		new Preaim(),
		new ReactionTime(),
		new FlashbangLeadingToKill(),
		new FlashbangHitFoePerFlashbang(),
		new FlashbangHitFriendPerFlashbang(),
		new FlashbangHitFoeAvgDuration(),
		new HeFoesDamageAvg(),
		new HeFriendsDamageAvg(),
		new UtilityOnDeathAvg(),
		new CtTradeRating(),
		new TTradeRating(),
		new TradeKillAttemptsPercentage(),
		new CtTradeKillAttemptsPercentage(),
		new TTradeKillAttemptsPercentage(),
		new TradeKillsSuccessPercentage(),
		new CtTradeKillsSuccessPercentage(),
		new TTradeKillsSuccessPercentage(),
		new TradeKillOpportunitiesPerRound(),
		new CtTradeKillOpportunitiesPerRound(),
		new TTradeKillOpportunitiesPerRound(),
		new TradedDeathAttemptsPercentage(),
		new CtTradedDeathAttemptsPercentage(),
		new TTradedDeathAttemptsPercentage(),
		new TradedDeathsSuccessPercentage(),
		new CtTradedDeathsSuccessPercentage(),
		new TTradedDeathsSuccessPercentage(),
		new TradedDeathsOpportunitiesPerRound(),
		new CtTradedDeathsOpportunitiesPerRound(),
		new TTradedDeathsOpportunitiesPerRound(),
		new CtOpeningDuelsRating(),
		new TOpeningDuelsRating(),
		new CtOpeningDuelAttemptsPercentage(),
		new TOpeningDuelAttemptsPercentage(),
		new CtOpeningDuelSuccessPercentage(),
		new TOpeningDuelSuccessPercentage(),
		new CtOpeningDuelTradePercentage(),
		new TOpeningDuelTradePercentage(),
		new CtOpeningAggressionSuccessRate(),
		new TOpeningAggressionSuccessRate(),
		new CtRoundWinRate(),
		new TRoundWinRate(),
		new CtBombsiteHoldRate(),
		new TBombsiteTakeRate(),
		new CtBombsiteRetakeRate(),
		new TBombsiteAfterplantRate(),
		new CtLeetifyRating(),
		new TLeetifyRating(),
		new CtKdRatio(),
		new TKdRatio(),
		new CtPositioningRating(),
		new TPositioningRating(),
		new AdvWinRate(),
		new DisadvWinRate(),
		new TradedDeathRate(),
		new UntradedKillsRate(),
		new AdvProportionRate(),
		new DisadvProportionRate(),
		new WinRateWithPlayer(),
		new WinRateWithoutPlayer(),
		new TeamClutchAttemptsPercentage(),
		new TeamClutchKillsPercentage(),
		new PlayerClutchKillsPercentage(),
		new PlayerClutch1v1KillsPercentage(),
		new PlayerClutch1v2KillsPercentage(),
		new PlayerClutch1v3KillsPercentage(),
		new PlayerClutch1v4KillsPercentage(),
		new PlayerClutch1v5KillsPercentage(),
		new PlayerClutchWinPercentage(),
		new PlayerClutch1v1WinPercentage(),
		new PlayerClutch1v2WinPercentage(),
		new PlayerClutch1v3WinPercentage(),
		new PlayerClutch1v4WinPercentage(),
		new PlayerClutch1v5WinPercentage(),
		new PlayerClutch1v1SavePercentage(),
		new PlayerClutch1v2SavePercentage(),
		new PlayerClutch1v3SavePercentage(),
		new PlayerClutch1v4SavePercentage(),
		new PlayerClutch1v5SavePercentage(),
		new LeetifyRating(),
		new CtRoundWinRateAncient(),
		new CtRoundWinRateDust2(),
		new CtRoundWinRateInferno(),
		new CtRoundWinRateMirage(),
		new CtRoundWinRateNuke(),
		new CtRoundWinRateOverpass(),
		new CtRoundWinRateVertigo(),
		new FlashbangScore(),
		new SprayAccuracyAk47(),
		new SprayAccuracyAug(),
		new SprayAccuracyM4A1S(),
		new SprayAccuracyM4A4(),
		new SprayAccuracySg553(),
		new TotalGrenadesThrownPerRound(),
		new TRoundWinRateAncient(),
		new TRoundWinRateDust2(),
		new TRoundWinRateInferno(),
		new TRoundWinRateMirage(),
		new TRoundWinRateNuke(),
		new TRoundWinRateOverpass(),
		new TRoundWinRateVertigo(),
		new OpeningRating(),
		new CtOpeningRating(),
		new TOpeningRating(),
		new PlayerClutchRating(),

		new Adr(),
		new AimRating(),
		new DamagePerShot(),
		new FlashbangHitFoe(),
		new FlashbangThrown(),
		new HeadshotKillPercentage(),
		new HeFoesDamage(),
		new HeThrown(),
		new HltvRating(),
		new KdRatio(),
		new KillDifference(),
		new LeetifyRatingNormalized(),
		new PersonalPerformanceRating(),
		new MolotovFoesDamage(),
		new MolotovThrown(),
		new Multikill2Rounds(),
		new Multikill3Rounds(),
		new Multikill4Rounds(),
		new Multikill5Rounds(),
		new OpeningDuelAttemptsPercentageBenchmarkCt(),
		new OpeningDuelAttemptsPercentageBenchmarkT(),
		new OpeningDuelBestWeapon(),
		new OpeningDuelMostDiedToWeapon(),
		new OpeningDuelMostKilledPlayer(),
		new OpeningDuelSuccessPercentageBenchmarkCt(),
		new OpeningDuelSuccessPercentageBenchmarkT(),
		new OpeningDuelTradePercentageBenchmarkCt(),
		new OpeningDuelTradePercentageBenchmarkT(),
		new PositioningRating(),
		new RoundsSurvived(),
		new RoundsSurvivedPercentage(),
		new ShotsFired(),
		new SmokeThrown(),
		new TotalAssists(),
		new TotalDamage(),
		new TotalDeaths(),
		new TotalKills(),
		new TradedDeathAttempts(),
		new TradedDeathsOpportunities(),
		new TradedDeathsSuccess(),
		new TradeKillAttempts(),
		new TradeKillOpportunities(),
		new TradeKillsSuccess(),
		new UtilityDamage(),
		new UtilityRating(),

		new AirborneKillsPerGame(),
		new BlindedKillsPerGame(),
		new KillsThroughSmokePerGame(),
		new KnifeKillsPerGame(),
		new TaserKillsPerGame(),
		new WallbangKillsPerGame(),
	];

	static getSkillConfigs(): Skill[] {
		return Skills.skillConfigs.slice();
	}

	static getSkillById(id: SkillId): Skill {
		return Skills.getSkillConfigs().find((s) => s.id === id);
	}

	static getSkillConfigsByGroup(groupId: Skill['groupId']): Skill[] {
		return this.getSkillConfigs().filter((s) => s.groupId === groupId);
	}

	static getSkillIds(): string[] {
		return Skills.skillConfigs.map((skill) => skill.id);
	}
}
