import { Inject, Injectable, PLATFORM_ID } from '@angular/core';
import { StorageService } from './storage.service';

@Injectable({
	providedIn: 'root',
})
export class LocalStorageService extends StorageService {
	public constructor(
		@Inject(PLATFORM_ID) platformId: Record<string, any>,
	) {
		super(platformId);
		if (!this.isBrowser) return;

		this.storage = localStorage;
	}
}
