import { Skill, SkillId } from '../skill';

export class KnifeKillsPerGame extends Skill {
	public readonly benchmarkKeyAvg = undefined;
	public readonly benchmarkKeyStd = undefined;
	public readonly decimalPlaces = 2;
	public readonly dependsOn = null;
	public readonly description = '';
	public readonly detailsInfo = false;
	public readonly displayInGoodBadSummary = false;
	public readonly groupId = null;
	public readonly groupTitle = 'Progress Report 2024';
	public readonly id = SkillId.KNIFE_KILLS_PER_GAME;
	public readonly improveTip = false;
	public readonly inversed = false;
	public readonly isRatio = false;
	public readonly shortTitle = 'Knife Kills';
	public readonly smallDescription = '';
	public readonly title = 'Knife Kills per Match';
}
