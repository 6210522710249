import { Component, OnInit, Input } from '@angular/core';

@Component({
	selector: 'app-tooltip',
	templateUrl: './tooltip.component.html',
	styleUrls: ['./tooltip.component.scss'],
})
export class TooltipComponent implements OnInit {
	@Input() public content: string;
	@Input() public dashboardConsistencyAllMatches: number;
	@Input() public dashboardConsistencyCurrentMatches: number;
	@Input() public extra: string;
	@Input() public header: string;
	@Input() public iconClass?: string;
	@Input() public placement: string | string[] = ['bottom', 'right', 'left', 'top'];
	@Input() public type: string;

	protected selectedIconClass: string;

	public ngOnInit(): void {
		this.selectedIconClass = this.iconClass || 'text-white';
	}
}
