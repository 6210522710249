import { Benchmark } from '../../benchmarks-repository';
import { Skill, SkillGroupId, SkillId } from '../skill';

export class FlashbangHitFriendPerFlashbang extends Skill {
	public readonly benchmarkKeyAvg: keyof Benchmark = 'flashbangHitFriendPerFlashbangAvg';
	public readonly benchmarkKeyStd: keyof Benchmark = 'flashbangHitFriendPerFlashbangStd';
	public readonly decimalPlaces = 2;
	public readonly dependsOn = 'flashbangThrown';
	public readonly descriptionExtra = 'Same as for the Enemies Flashed per Flashbang but for teammates.';
	public readonly description = 'This stat displays how many teammates (including yourself) you on average blind with every flashbang you throw, the maximum possible number being 5. High numbers here means you might want to be a bit more careful with your flashbangs. Only includes people that were really flashed, not people who for example turned their back to the flash.';
	public readonly detailsInfo = false;
	public readonly displayInGoodBadSummary = true;
	public readonly fallbackValue = 'n/a';
	public readonly groupIcon = 'mdi mdi-progress-wrench';
	public readonly groupId: SkillGroupId = 'utilitySkillGroup';
	public readonly groupTitle = 'Utility group';
	public readonly icon = 'mdi mdi-lightbulb-outline';
	public readonly id = SkillId.FLASHBANG_HIT_FRIEND_PER_FLASHBANG;
	public readonly improveTip = false;
	public readonly inversed = true;
	public readonly isRatio = true;
	public readonly minValue = 0;
	public readonly shortTitle = 'Friends flashed';
	public readonly smallDescription = 'How many teammates you on average blind with every flashbang you throw.';
	public readonly title = 'Teammates Flashed per Flashbang';
}
