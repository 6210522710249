import { CalculatorHelper } from '../calculator.helper';
import { Skill } from './skill';

export abstract class FocusAreaSkill extends Skill {
	public abstract readonly focusAreaLowerBound: number;
	public abstract readonly focusAreaUpperBound: number;
	public readonly showChart: boolean = true;

	public calculateScore(value: number): number {
		return CalculatorHelper.limit((value - this.focusAreaLowerBound) / (this.focusAreaUpperBound - this.focusAreaLowerBound) * 100);
	}
}
