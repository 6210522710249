import { Benchmark } from '../../../benchmarks-repository';
import { PostSign, Skill, SkillGroupId, SkillId } from '../../skill';

export class AdvWinRate extends Skill {
	public readonly benchmarkKeyAvg: keyof Benchmark = 'advWinRateAvg';
	public readonly benchmarkKeyStd: keyof Benchmark = 'advWinRateStd';
	public groupId: SkillGroupId = 'teamplaySituationGroup';
	public postSign: PostSign = '%';

	dependsOn = null;
	description = 'The number of rounds your team won with a 5v4 advantage divided by total rounds played with a 5v4 advantage.';
	detailsInfo = false;
	displayInGoodBadSummary = false;
	groupTitle = '5v4';
	id = SkillId.ADV_WIN_RATE;
	improveTip = false;
	inversed = false;
	isRatio = true;
	minValue = 0;
	smallDescription = 'Percentage of rounds your team won with a player advantage.';
	title = 'Round Win Rate';
}
