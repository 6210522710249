import { Benchmark } from '../../benchmarks-repository';
import { PlayerClutchKillsPercentage } from './player-clutch-kills-percentage';
import { SkillId } from '../skill';

export class PlayerClutch1v4KillsPercentage extends PlayerClutchKillsPercentage {
	public readonly benchmarkKeyAvg: keyof Benchmark = 'playerClutch1v4KillsPercentageAvg';
	public readonly benchmarkKeyStd: keyof Benchmark = 'playerClutch1v4KillsPercentageStd';
	id = SkillId.PLAYER_CLUTCH_1V4_KILLS_PERCENTAGE;
	title = '1v4 Clutch Kills';

	focusAreaLowerBound = 9;
	focusAreaUpperBound = 19;
}
