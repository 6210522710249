import { Benchmark } from '../../benchmarks-repository';
import { PostSign, Skill, SkillGroupId } from '../skill';

export abstract class TradeKillAttemptsPercentageBase extends Skill {
	public readonly benchmarkKeyAvg: keyof Benchmark = 'tradeKillAttemptsPercentageAvg';
	public readonly benchmarkKeyStd: keyof Benchmark = 'tradeKillAttemptsPercentageStd';
	public readonly dependsOn = null;
	public readonly description = '';
	public readonly descriptionExtra = '';
	public readonly detailsInfo = false;
	public readonly displayInGoodBadSummary = false;
	public readonly groupIcon = 'mdi mdi-progress-wrench';
	public readonly groupId: SkillGroupId = 'positioningSkillGroup';
	public readonly groupTitle = 'Positioning group';
	public readonly icon = 'mdi mdi-weather-cloudy';
	public readonly improveTip = false;
	public readonly inversed = false;
	public readonly isRatio = false;
	public readonly minValue = 0;
	public readonly postSign: PostSign = '%';
	public readonly shortTitle = 'Trade Kill Attempts %';
	public readonly smallDescription = '';
	public readonly title = 'Trade Kill Attempts Percentage';
}
