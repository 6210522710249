import { Benchmark } from '../../benchmarks-repository';
import { PostSign, Skill, SkillId } from '../skill';

export class OpeningDuelAttemptsPercentageBenchmarkT extends Skill {
	public readonly benchmarkKeyAvg: keyof Benchmark = 'tOpeningDuelAttemptsPercentageAvg';
	public readonly benchmarkKeyStd: keyof Benchmark = 'tOpeningDuelAttemptsPercentageStd';
	public postSign: PostSign = '%';

	dependsOn = null;
	description = '';
	descriptionExtra = '';
	detailsInfo = false;
	displayInGoodBadSummary = false;
	groupId = null;
	groupTitle = 'General';
	id = SkillId.OPENING_DUEL_ATTEMPTS_PERCENTAGE_BENCHMARK_T;
	improveTip = false;
	inversed = false;
	isRatio = false;
	keepValuesVerbatim = true;
	minValue = 0;
	shortTitle = 'Attempts';
	smallDescription = '';
	title = 'Opening Duel Attempts Percentage';
}
