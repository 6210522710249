<ng-container *ngFor="let toast of filteredToasts" [ngSwitch]="toast.type">
	<ngb-toast
		[ngSwitch]="toast.type"
		[@inOutAnimation]
		[header]="toast.header"
		[autohide]="toast.autohide === null || toast.autohide === undefined ? true : toast.autohide"
		[class]="toast.class"
		[delay]="toast.delay || 5000"
		(hidden)="toastService.remove(toast)"
	>
		<ng-container *ngSwitchCase="'focusAreaPassed'" [ngSwitch]="toast.templateId">
			<ng-container *ngSwitchCase="0">
				<h2>Calm down!</h2>

				<p>
					Your
					<a [routerLink]="['/app/focus-areas', toast.passedFocusArea.id]" (click)="dismissFocusArea(toast)">{{ toast.passedFocusArea.title }}</a>
					is looking mighty fine. Nice improvement!

					<ng-container *ngIf="toast.replacedBy">
						We've swapped it for
						<a [routerLink]="['/app/focus-areas', toast.replacedBy.id]" (click)="dismissFocusArea(toast)">{{ toast.replacedBy.title }}</a>
						to help you improve further.
					</ng-container>
				</p>
			</ng-container>

			<ng-container *ngSwitchCase="1">
				<h2>Rookie no more</h2>

				<p>
					You cranked those
					<a [routerLink]="['/app/focus-areas', toast.passedFocusArea.id]" (click)="dismissFocusArea(toast)">{{ toast.passedFocusArea.title }}</a>
					numbers up.

					<ng-container *ngIf="toast.replacedBy">
						We've swapped it out for
						<a [routerLink]="['/app/focus-areas', toast.replacedBy.id]" (click)="dismissFocusArea(toast)">{{ toast.replacedBy.title }}</a>
						to better reflect your current weaknesses.
					</ng-container>

					Great work!
				</p>
			</ng-container>

			<ng-container *ngSwitchDefault>
				<h2>Bomb has been defused</h2>

				<p>
					No sweat. You hit your goal for
					<a [routerLink]="['/app/focus-areas', toast.passedFocusArea.id]" (click)="dismissFocusArea(toast)">{{ toast.passedFocusArea.title }}</a>.

					<ng-container *ngIf="toast.replacedBy">
						We've given you a new Focus Area,
						<a [routerLink]="['/app/focus-areas', toast.replacedBy.id]" (click)="dismissFocusArea(toast)">{{ toast.replacedBy.title }}</a>.
					</ng-container>

					Keep up the good work!
				</p>
			</ng-container>

			<button class="close" aria-label="Dismiss" title="Dismiss" (click)="dismissFocusArea(toast)">
				<i class="mdi mdi-close"></i>
			</button>

			<ng-lottie [options]="{ path: '/assets/lottie/confetti.json' }"></ng-lottie>
		</ng-container>

		<ng-container *ngSwitchCase="'content-notification'">
			<div class="image" (click)="interactCallback(toast)">
				<img [src]="toast.contentNotification.imageUrl || '/assets/leetify/leetify-icon.svg'" alt="">
			</div>
			<div class="text" (click)="interactCallback(toast)">
				{{ toast.contentNotification.text }}
			</div>

			<button class="close" aria-label="Dismiss" title="Dismiss" (click)="dismissFocusArea(toast)">
				<i class="mdi mdi-close"></i>
			</button>
		</ng-container>

		<ng-container *ngSwitchCase="'highlightPinned'">
			<div class="title">
				<leetify-icon [icon]="Icon.CHECKMARK"></leetify-icon>
				Highlight Pinned
			</div>

			<a routerLink="/app/profile">View Profile</a>
		</ng-container>

		<ng-container *ngSwitchDefault>
			{{ toast.text }}
		</ng-container>
	</ngb-toast>
</ng-container>
