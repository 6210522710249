import { NgModule } from '@angular/core';
import { Routes, RouterModule } from '@angular/router';

import { AppLayoutComponent } from 'src/app/layouts/app-layout/app-layout.component';
// import { OnboardingLayoutComponent } from 'src/app/layouts/onboarding-layout/onboarding-layout.component';
// import { PlainLayoutComponent } from 'src/app/layouts/plain-layout/plain-layout.component';

// import { ChangePasswordRedirectComponent } from 'src/app/pages/user/redirect/change-password-redirect.component';

// import { ErrorNotFoundComponent } from 'src/app/pages/error/not-found/not-found.component';

const routes: Routes = [
	/* eslint-disable @typescript-eslint/explicit-function-return-type */
	// main app pages
	{ path: '', redirectTo: '/lol', pathMatch: 'full' },
	{
		path: 'lol',
		component: AppLayoutComponent,
		// canActivate: [AuthGuard],
		// resolve: { user: AuthResolver },

		children: [
			{ path: '', loadChildren: () => import('src/app/pages/user/matches/matches.module').then((m) => m.MatchesModule) },
			// { path: 'profile', loadChildren: () => import('src/app/pages/user/profile/profile.module').then((m) => m.ProfileModule) },
			// { path: 'account', loadChildren: () => import('src/app/pages/user/account/account.module').then((m) => m.AccountModule) },
			{ path: 'match-details/:id', loadChildren: () => import('src/app/pages/user/match-details/match-details.module').then((m) => m.MatchDetailsModule) },
			{ path: 'matches', loadChildren: () => import('src/app/pages/user/matches/matches.module').then((m) => m.MatchesModule) },
			// { path: 'profile', loadChildren: () => import('src/app/pages/user/profile/profile.module').then((m) => m.ProfileModule) },
			// { path: 'receipts', loadChildren: () => import('src/app/pages/user/receipt/receipt.module').then((m) => m.ReceiptModule) },
			// { path: 'support', loadChildren: () => import('src/app/pages/user/support/support.module').then((m) => m.SupportModule) },
		],
	},

	// onboarding pages
	// {
	// 	path: 'app/onboarding',
	// 	component: OnboardingLayoutComponent,

	// 	children: [
	// 		{ path: '', loadChildren: () => import('src/app/pages/user/onboarding/onboarding.module').then((m) => m.OnboardingModule) },
	// 		{ path: 'support', loadChildren: () => import('src/app/pages/user/support/support.module').then((m) => m.SupportModule) },
	// 	],
	// },

	// auth pages
	// {
	// 	path: 'auth',
	// 	component: PlainLayoutComponent,

	// 	children: [
	// 		{ path: '', loadChildren: () => import('src/app/pages/user/auth/auth.module').then((m) => m.AuthModule) },
	// 	],
	// },

	// publicly available app pages
	// {
	// 	component: AppLayoutComponent,
	// 	path: 'public',
	// 	// canActivate: [AuthGuard],

	// 	children: [
	// 		{ path: 'match-details/:id', loadChildren: () => import('src/app/pages/user/match-details/match-details.module').then((m) => m.MatchDetailsModule) },
	// 		{ path: 'profile', loadChildren: () => import('src/app/pages/user/profile/profile.module').then((m) => m.ProfileModule) },
	// 	],
	// },

	// internal redirects
	// { path: '.well-known/change-password', component: ChangePasswordRedirectComponent },
	// { path: 'profile', redirectTo: '/app/profile' },

	// backwards compatibility redirects
	// { path: 'login', redirectTo: '/auth/login' },
	// { path: 'reset-password', redirectTo: '/auth/reset-password' },
	// { path: 'signin', redirectTo: '/auth/login' },
	// { path: 'signup', redirectTo: '/auth/signup' },

	// errors
	// {
	// 	path: '',
	// 	component: PlainLayoutComponent,
	// 	children: [
	// 		{ path: 'error/duplicate-account-deleted', loadChildren: () => import('src/app/pages/error/duplicate-account-deleted/duplicate-account-deleted.module').then((m) => m.ErrorDuplicateAccountDeletedModule) },
	// 		{ path: 'maintenance', loadChildren: () => import('src/app/pages/error/maintenance/maintenance.module').then((m) => m.MaintenanceModule) },
	// 		{ path: '**', component: ErrorNotFoundComponent },
	// 	],
	// },
	/* eslint-enable @typescript-eslint/explicit-function-return-type */
];

@NgModule({
	imports: [
		RouterModule.forRoot(routes, {
			initialNavigation: 'enabledNonBlocking',
			paramsInheritanceStrategy: 'always',
			scrollPositionRestoration: 'enabled',
		}),
	],
	exports: [RouterModule],
})
export class AppRoutingModule {
	//
}
