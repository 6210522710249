@for (option of options; track option.value) {
	<label class="label-wrapper">
		<input type="radio" [name]="name" [value]="option.value" [ngModel]="value" (ngModelChange)="change($event)">
		<span class="custom-radio" (click)="onChange($event)"></span>
		<span class="label-text">{{ option.label }}</span>
		@if (option.description) {
			<span class="label-description">{{ option.description }}</span>
		}
	</label>
}
