import { Benchmark } from '../../../benchmarks-repository';
import { Skill, SkillGroupId } from '../../skill';

export abstract class KdRatio extends Skill {
	public readonly benchmarkKeyAvg: keyof Benchmark = 'kdRatioAvg';
	public readonly benchmarkKeyStd: keyof Benchmark = 'kdRatioStd';
	public groupId: SkillGroupId = 'teamplaySideGroup';

	dependsOn = null;
	description = 'The amount of your enemy kills, divided by your deaths.';
	detailsInfo = false;
	displayInGoodBadSummary = false;
	improveTip = false;
	inversed = false;
	isRatio = true;
	minValue = 0;
	smallDescription = 'The amount of your enemy kills, divided by your deaths.';
	title = 'K/D Ratio';
	isScaledForMatch = true;
}
