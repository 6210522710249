import { Benchmark } from '../../benchmarks-repository';
import { PostSign, Skill, SkillGroupId, SkillId } from '../skill';

export class TradedDeathRate extends Skill {
	public readonly benchmarkKeyAvg: keyof Benchmark = 'tradedDeathRateAvg';
	public readonly benchmarkKeyStd: keyof Benchmark = 'tradedDeathRateStd';
	public groupId: SkillGroupId = 'teamplaySituationGroup';
	public postSign: PostSign = '%';

	dependsOn = null;
	description = 'Percentage of rounds where someone on the team died but was traded by a teammate when your team had a 5v4 advantage.';
	detailsInfo = false;
	displayInGoodBadSummary = false;
	groupTitle = '5v4';
	id = SkillId.TRADED_DEATH_RATE;
	improveTip = false;
	inversed = false;
	isRatio = true;
	minValue = 0;
	smallDescription = 'Percentage of your teams deaths that were traded when you died during a 5v4.';
	title = 'Traded Deaths';
}
