import { Benchmark } from '../../benchmarks-repository';
import { Skill, SkillId } from '../skill';

export class KdRatio extends Skill {
	public readonly benchmarkKeyAvg: keyof Benchmark = 'kdRatioAvg';
	public readonly benchmarkKeyStd: keyof Benchmark = 'kdRatioStd';

	decimalPlaces = 2;
	dependsOn = null;
	description = 'The amount of your enemy kills, divided by your deaths.';
	detailsInfo = false;
	displayInGoodBadSummary = false;
	groupId = null;
	groupTitle = 'General';
	id = SkillId.KD_RATIO;
	improveTip = false;
	inversed = false;
	isRatio = false;
	isScaledForMatch = true;
	minValue = 0;
	shortTitle = 'K/D';
	smallDescription = 'The amount of your enemy kills, divided by your deaths.';
	title = 'Kill/Death Ratio';
}
