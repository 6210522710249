import { Benchmark } from '../../benchmarks-repository';
import { OpeningDuelTradePercentage } from './opening-duel-trade-percentage';
import { SkillId } from '../skill';
import { TeamName } from '../../enums';

export class TOpeningDuelTradePercentage extends OpeningDuelTradePercentage {
	public readonly benchmarkKeyAvg: keyof Benchmark = 'tOpeningDuelTradePercentageAvg';
	public readonly benchmarkKeyStd: keyof Benchmark = 'tOpeningDuelTradePercentageStd';
	id = SkillId.T_OPENING_DUEL_TRADE_PERCENTAGE;
	side = TeamName.TERRORIST;
	title = 'T Opening Duel Traded Percentage';
}
