import { Benchmark } from '../../../../benchmarks-repository';
import { PostSign, Skill, SkillGroupId, SkillId } from '../../../skill';

export class WinRateWithPlayer extends Skill {
	public readonly benchmarkKeyAvg: keyof Benchmark = 'winRateWithPlayerAvg';
	public readonly benchmarkKeyStd: keyof Benchmark = 'winRateWithPlayerStd';
	public groupId: SkillGroupId = 'teamplaySituationPlayerGroup';
	public postSign: PostSign = '%';

	dependsOn = null;
	description = 'Percentage of rounds where you won a 4v5 situation when you were alive.';
	detailsInfo = false;
	displayInGoodBadSummary = false;
	groupTitle = '4v5 - with Teammate';
	id = SkillId.WIN_RATE_WITH_PLAYER;
	improveTip = false;
	inversed = false;
	isRatio = true;
	minValue = 0;
	smallDescription = 'Percentage of rounds won when you were alive during a 4v5 situation.';
	title = 'Round Win Rate';
}
