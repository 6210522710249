import { Benchmark } from '../../benchmarks-repository';
import { Skill, SkillId } from '../skill';

export class Adr extends Skill {
	public readonly benchmarkKeyAvg: keyof Benchmark = 'adrAvg';
	public readonly benchmarkKeyStd: keyof Benchmark = 'adrStd';
	public readonly decimalPlaces = 0;
	public readonly dependsOn = null;
	public readonly description = '';
	public readonly detailsInfo = false;
	public readonly displayInGoodBadSummary = false;
	public readonly groupId = null;
	public readonly groupTitle = 'General';
	public readonly id = SkillId.ADR;
	public readonly improveTip = false;
	public readonly inversed = false;
	public readonly isRatio = false;
	public readonly isScaledForMatch = true;
	public readonly shortTitle = 'ADR';
	public readonly smallDescription = '';
	public readonly title = 'Avg Damage per Round';
}
