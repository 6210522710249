<aside>
	<div class="mobile-nav-toggle">
		<button aria-label="Toggle Menu" title="Toggle Menu" (click)="toggleNav($event)">
			<i class="mdi {{ navigationActive ? 'mdi-close' : 'mdi-menu' }}"></i>
		</button>

		<a [routerLink]="user ? '/app' : '/'">
			<img class="desktop" src="/assets/images/leetify-logo-primary-white.svg" alt="Leetify">
			<img class="mobile" src="/assets/leetify/leetify-icon.svg" alt="Leetify">
		</a>
	</div>

	<div *ngIf="user" class="right">

		<!-- <app-import-status-menu></app-import-status-menu> -->
		<app-user-menu></app-user-menu>

		<a routerLink="/app/profile" class="user-account-settings-link">
			<img [src]="user.steamAvatarUrl || '/assets/images/anon.png'" alt="" class="rounded-circle">
		</a>
	</div>

	<div *ngIf="!user" class="right --unauthenticated">
		<div class="socials">
			<a *ngFor="let social of socialMediaLinks" [href]="social.link" target="_blank" [attr.aria-label]="social.title">
				<leetify-icon [icon]="social.icon"></leetify-icon>
			</a>
		</div>

		<div class="spacer"></div>

		<div class="auth-buttons">
			<a routerLink="/auth/login" class="login-button">Log In</a>
			<a routerLink="/auth/signup" class="signup-button">Sign Up</a>
		</div>
	</div>
</aside>
