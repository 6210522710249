<div *ngIf="showBanner" class="referrer-domain-blacklist-banner">
	<p class="text-danger font-weight-bold">
		You've been redirected to Leetify from a suspicious site. If you just logged into Steam, your Steam account may be compromised.
	</p>
	<p>
		Check your browser history (Ctrl+H) to confirm that you were on a suspicious site (we only use leetify.com)
		If you logged into Steam on anything other than steampowered.com, take steps to secure your account.
	</p>
	<ol>
		<li>
			Change your Steam password (<a target="_blank" href="https://help.steampowered.com/en/wizard/HelpWithAccountStolen">see Valve's guide</a>)
		</li>
		<li>
			Deauthorize all devices you have authorized on Steam Guard (<a target="_blank" href="https://help.steampowered.com/en/wizard/HelpWithAccountStolen">see Valve's guide</a>)
		</li>
		<li>
			If you've already lost access to your Steam account, here are <a target="_blank" href="https://help.steampowered.com/en/faqs/view/0A94-F308-34A5-1988">Valve's recommended steps</a> to retrieve your account.
		</li>
		<li>
			Please email details about this scam attempt to support&#64;leetify.com so we can try to prevent future scams like it.
		</li>
	</ol>
</div>