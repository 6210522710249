import { Component, ElementRef, HostBinding, HostListener, Inject, Input, OnChanges, OnDestroy, OnInit, PLATFORM_ID, ViewChild } from '@angular/core';
import { mapValues } from 'lodash-es';
import { Icon } from 'src/constants';
import { isPlatformBrowser } from '@angular/common';
import { Router } from '@angular/router';
import { Subject } from 'rxjs';
import { takeUntil } from 'rxjs/operators';
import { BenchmarksRepository } from 'leetify-shared-utils/benchmarks-repository';
import { Dictionary } from 'leetify-shared-utils/types';
import { CalculatorHelper } from 'leetify-shared-utils/calculator.helper';
import { DataSource, MatchResult } from 'leetify-shared-utils/enums';
import { MiniProfileDTO } from 'leetify-shared-utils/dto';
import { FillStyle } from 'src/app/widgets/progress-bar-chart/progress-bar-chart.component';
import { GamesService } from 'src/app/services/games.service';
import { MiniProfilesService } from 'src/app/services/mini-profiles.service';
import { User } from 'src/app/models/user.model';
import { UserService } from 'src/app/services/user.service';
import { ViewHelper } from 'src/app/helpers/view.helper';
import { ZScoreHelper } from 'leetify-shared-utils/z-score-helper';
import { PrivacySettingsHelper } from 'src/app/helpers/privacy-settings.helper';

type UserLevelClass = '--founder' | '--pro' | '--staff';
type AvatarAccentClass = UserLevelClass | '--ban';

interface Stat {
	formattedValue?: string;
	label: string;
	scale: [number, number];
	value: number;
}

interface RanksViewModel {
	[DataSource.FACEIT]?: number;
	[DataSource.GAMERS_CLUB]?: number;
	[DataSource.MATCHMAKING_WINGMAN]?: number;
	perMapCompetitive: Map<string, number>; // mapName => number
	premier?: number;

	primary?: {
		dataSource: DataSource;
		skillLevel: number;
		type?: string;
	};
}

@Component({
	selector: 'app-mini-profile',
	templateUrl: './mini-profile.component.html',
	styleUrls: ['./mini-profile.component.scss'],
})
export class MiniProfileComponent implements OnChanges, OnDestroy, OnInit {
	public readonly DataSource = DataSource;
	public readonly MatchResult = MatchResult;
	public readonly onMapIconErrored = ViewHelper.onMapIconErrored;
	public readonly onPlayerHeaderErrored = ViewHelper.onPlayerHeaderErrored;
	public readonly onProfilePictureErrored = ViewHelper.onProfilePictureErrored;

	@HostBinding('class.--static')
	@Input()
	public static = false;

	@Input() public anchor: HTMLElement;
	@Input() public compareRedirectUrlCallback: (miniProfile: MiniProfileDTO) => string[];
	@Input() public loadProfileManuallyOnly = true;
	@Input() public openCompareInNewTab = false;
	@Input() public primaryRankOverride: { dataSource: DataSource; type: 'csgo' | 'premier' | string };
	@Input() public spectateRedirectUrlCallback: (miniProfile: MiniProfileDTO) => string[];
	@Input() public steam64Id: string;

	@ViewChild('wrapper') protected wrapper: ElementRef<HTMLElement>;

	public avatarAccentClass: AvatarAccentClass;
	public compareRedirectUrl: string[];
	public headerImageUrl: string;
	public isBannedOnDataSource: { [dataSource in DataSource]?: boolean };
	public isLoading = true;
	public isRankOverlayActive = false;
	public leetifyRating: string;
	public leetifyRatingZScoreLabel: string;
	public player: MiniProfileDTO;
	public progressBarChartFillStyle: FillStyle;
	public ranks: RanksViewModel;
	public stats: Stat[];
	public steamAvatarUrl: string;
	public transform: string;
	public user: User;
	public userLevelClass: UserLevelClass;
	public spectateTooltip: string;
	public matchHistoryTooltip: string;

	protected readonly ngUnsubscribe = new Subject<void>();
	protected readonly Icon = Icon;

	protected isBrowser: boolean;
	protected profilePictures: Dictionary<string> = {};

	public constructor(
		@Inject(PLATFORM_ID) protected readonly platformId: Record<string, any>,
		protected readonly gamesService: GamesService,
		protected readonly miniProfilesService: MiniProfilesService,
		protected readonly router: Router,
		protected readonly userService: UserService,
	) {
		// bind because we need to remove this as an event listener
		this.setTransform = this.setTransform.bind(this);
	}

	public async loadMiniProfile(): Promise<void> {
		try {
			const miniProfile = await this.miniProfilesService.getMiniProfile(this.steam64Id);
			if (miniProfile) this.handleMiniProfileUpdated(miniProfile);
		} finally {
			this.isLoading = false;
		}
	}

	public spectate(miniProfile: MiniProfileDTO): void {
		if (!miniProfile.leetifyUserId || !miniProfile.isSensitiveDataVisible) return;

		const redirectUrl = this.spectateRedirectUrlCallback && this.spectateRedirectUrlCallback(miniProfile);
	}

	protected handleMiniProfileUpdated(miniProfile: MiniProfileDTO): void {
		this.player = miniProfile;

		this.userLevelClass = this.getUserLevelClass(miniProfile);

		this.avatarAccentClass = this.getAvatarAccentClass(miniProfile);
		this.headerImageUrl = this.userService.getHeaderImageUrl(miniProfile.leetifyUserId);
		this.isBannedOnDataSource = this.getIsBannedOnDataSource(miniProfile);
		this.progressBarChartFillStyle = this.getProgressBarChartFillStyle(this.userLevelClass);
		this.stats = this.getStats(miniProfile);
		this.steamAvatarUrl = miniProfile.steamAvatarUrl || ViewHelper.PROFILE_PICTURE_FALLBACK;
		this.spectateTooltip = PrivacySettingsHelper.getSpectateTooltip(this.player.isSensitiveDataVisible, this.player.leetifyUserId);
		this.matchHistoryTooltip = PrivacySettingsHelper.getMatchHistoryTooltip(this.player.isSensitiveDataVisible, this.player.leetifyUserId);

		this.setLeetifyRating(miniProfile);
		this.evaluateCompareUrl();

		setTimeout(() => this.setTransform());
	}

	protected getAvatarAccentClass(miniProfile: MiniProfileDTO): AvatarAccentClass {
		if (miniProfile.isLeetifyStaff) return '--staff';
		if (miniProfile.isCollector) return '--founder';
		if (miniProfile.isProPlan) return '--pro';
		if (miniProfile.platformBans.length) return '--ban';
	}

	protected getUserLevelClass(miniProfile: MiniProfileDTO): UserLevelClass {
		if (miniProfile.isLeetifyStaff) return '--staff';
		if (miniProfile.isCollector) return '--founder';
		if (miniProfile.isProPlan) return '--pro';
	}

	protected getProgressBarChartFillStyle(userLevelClass: UserLevelClass): FillStyle {
		switch (userLevelClass) {
			case '--founder': return FillStyle.FOUNDER;
			case '--pro': return FillStyle.PRO;
			case '--staff': return FillStyle.STAFF;
		}
	}

	protected getIsBannedOnDataSource(miniProfile: MiniProfileDTO): { [dataSource in DataSource]?: boolean } {
		const isBannedOnDataSource: { [dataSource in DataSource]?: boolean } = {};
		for (const dataSource of miniProfile.platformBans) isBannedOnDataSource[dataSource] = true;

		return isBannedOnDataSource;
	}

	protected getStats(miniProfile: MiniProfileDTO): Stat[] {
		return [
			{
				label: 'Aim',
				scale: [0, 100],
				value: Math.round(miniProfile.ratings.aim),
			},

			{
				label: 'Utility',
				scale: [0, 100],
				value: Math.round(miniProfile.ratings.utility),
			},

			{
				label: 'Positioning',
				scale: [0, 100],
				value: Math.round(miniProfile.ratings.positioning),
			},

			{
				formattedValue: ViewHelper.formatLeetifyRating(miniProfile.ratings.clutch),
				label: 'Clutching',
				scale: [0, 0.16],
				value: miniProfile.ratings.clutch,
			},

			{
				formattedValue: ViewHelper.formatLeetifyRating(miniProfile.ratings.opening),
				label: 'Opening Duels',
				scale: [-0.1, 0.1],
				value: miniProfile.ratings.opening,
			},
		];
	}

	protected async setLeetifyRating(miniProfile: MiniProfileDTO): Promise<void> {
		const unifiedBenchmark = await BenchmarksRepository.unifiedBenchmark;

		this.leetifyRating = ViewHelper.formatLeetifyRating(miniProfile.ratings.leetify);
		this.leetifyRatingZScoreLabel = (ZScoreHelper.getZScoreLabel(
			ZScoreHelper.getZScoreDeviationStep(
				miniProfile.ratings.leetify,
				unifiedBenchmark.leetifyRatingAvg,
				unifiedBenchmark.leetifyRatingStd,
				miniProfile.ratings.gamesPlayed,
				false,
			),
		) || '').toLowerCase();
	}

	protected async getLoadedMiniProfile(): Promise<void> {
		const miniProfile = await this.miniProfilesService.getLoadedMiniProfile(this.steam64Id);
		if (miniProfile) this.handleMiniProfileUpdated(miniProfile);
	}

	protected evaluateCompareUrl(): void {
		this.compareRedirectUrl = null;

		if (!this.player) return;

		if (this.compareRedirectUrlCallback) {
			const url = this.compareRedirectUrlCallback(this.player);
			if (url?.length) this.compareRedirectUrl = url;
			return;
		}

		if (this.user?.steam64Id && this.user?.steam64Id !== this.player?.steam64Id) {
			this.compareRedirectUrl = ['/app/compare', this.user?.steam64Id, this.player?.steam64Id];
		}
	}

	@HostListener('window:resize')
	@HostListener('window:scroll')
	protected setTransform(): void {
		if (!this.isBrowser || !this.anchor || !this.wrapper?.nativeElement) return;

		const padding = 16;

		const anchor = this.anchor.getBoundingClientRect();
		const wrapper = this.wrapper.nativeElement.getBoundingClientRect();

		const minX = CalculatorHelper.limit(padding, 0, window.innerWidth);
		const maxX = CalculatorHelper.limit(window.innerWidth - wrapper.width - padding, 0, window.innerWidth);

		const minY = CalculatorHelper.limit(padding, 0, window.innerHeight);
		const maxY = CalculatorHelper.limit(window.innerHeight - wrapper.height - padding, 0, window.innerHeight);

		const x = CalculatorHelper.limit(anchor.x + anchor.width, minX, maxX);
		const y = CalculatorHelper.limit(anchor.y + anchor.height / 2 - wrapper.height / 2, minY, maxY);

		this.transform = `translate(calc(100vw + ${x}px), ${y}px)`;
	}

	public ngOnInit(): void {
		this.isBrowser = isPlatformBrowser(this.platformId);

		this.miniProfilesService.miniProfile$.pipe(takeUntil(this.ngUnsubscribe)).subscribe((miniProfile) => {
			if (miniProfile.steam64Id === this.steam64Id) this.handleMiniProfileUpdated(miniProfile);
		});

		this.userService.user$.pipe(takeUntil(this.ngUnsubscribe)).subscribe((user) => this.user = user);

		// this.gamesService.steamProfiles$.pipe(takeUntil(this.ngUnsubscribe)).subscribe((profiles) => {
		// 	this.profilePictures = mapValues(profiles, (sp) => sp.steamAvatarUrl);
		// });

		if (typeof document !== 'undefined') {
			const main = document.querySelector('main');
			if (main) main.addEventListener('scroll', this.setTransform);
		}

		this.user = this.userService.user;

		this.getLoadedMiniProfile();
		if (this.static || !this.loadProfileManuallyOnly) this.loadMiniProfile();

		this.setTransform();
		setTimeout(() => this.setTransform());
	}

	public ngOnChanges(): void {
		this.setTransform();
		this.evaluateCompareUrl();
	}

	public ngOnDestroy(): void {
		if (typeof document !== 'undefined') {
			const main = document.querySelector('main');
			if (main) main.removeEventListener('scroll', this.setTransform);
		}

		this.ngUnsubscribe.next();
		this.ngUnsubscribe.complete();
	}
}
