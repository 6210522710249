import { Benchmark } from '../../benchmarks-repository';
import { Skill, SkillGroupId, SkillId } from '../skill';

export class FlashbangHitFoePerFlashbang extends Skill {
	public readonly benchmarkKeyAvg: keyof Benchmark = 'flashbangHitFoePerFlashbangAvg';
	public readonly benchmarkKeyStd: keyof Benchmark = 'flashbangHitFoePerFlashbangStd';
	public readonly decimalPlaces = 2;
	public readonly dependsOn = 'flashbangThrown';
	public readonly descriptionExtra = 'Counts the total amount of enemies flashed for a duration of more than 1.1 sec (in order to exclude half-blind enemies). The sum is then divided by the total number of flashbangs thrown.';
	public readonly description = 'This stat displays how many enemies you on average blind with every flashbang you throw, the maximum possible number being 5. This is an indication of how efficient you are with your flashbangs. Only includes people that were really flashed, not people who for example turned their back to the flash.';
	public readonly detailsInfo = false;
	public readonly displayInGoodBadSummary = true;
	public readonly fallbackValue = 'n/a';
	public readonly groupIcon = 'mdi mdi-progress-wrench';
	public readonly groupId: SkillGroupId = 'utilitySkillGroup';
	public readonly groupTitle = 'Utility group';
	public readonly icon = 'mdi mdi-lightbulb-on';
	public readonly id = SkillId.FLASHBANG_HIT_FOE_PER_FLASHBANG;
	public readonly improveTip = true;
	public readonly inversed = false;
	public readonly isRatio = true;
	public readonly minValue = 0;
	public readonly shortTitle = 'Enemies flashed';
	public readonly smallDescription = 'How many enemies you on average blind with every flashbang you throw';
	public readonly title = 'Enemies Flashed per Flashbang';
}
