import { ActivatedRoute, Router } from '@angular/router';
import { Meta, Title } from '@angular/platform-browser';
import { OnInit, Directive } from '@angular/core';
import { AuthService } from 'src/app/auth/auth.service';
import { User } from 'src/app/models/user.model';
import { UserService } from 'src/app/services/user.service';

@Directive()
export abstract class RedirectComponent implements OnInit {
	// the path of this redirect, so we can redirect unauthenticated users back here after they've logged in
	protected abstract readonly path: string;

	// returns the url to redirect to
	protected abstract getRedirectUrl(user: User): Promise<string[]> | string[];

	// sets any kind of meta (page title, open graph images, etc.)
	protected abstract setPageMeta(): void;

	public constructor(
		protected readonly metaService: Meta,
		protected readonly route: ActivatedRoute,
		protected readonly router: Router,
		protected readonly titleService: Title,
		protected readonly userService: UserService,
	) {
		//
	}

	protected async navigateAuthenticated(user: User): Promise<void> {
		const redirectUrl = await this.getRedirectUrl(user);
		this.router.navigate(redirectUrl);
	}

	protected navigateUnauthenticated(): void {
		this.router.navigate(['/auth/login'], { queryParams: { t: this.getPath() }, replaceUrl: true });
	}

	protected getUser(): Promise<User> {
		return this.userService.myself();
	}

	protected getPath(): string {
		return this.path;
	}

	public async ngOnInit(): Promise<void> {
		this.setPageMeta();

		if (!AuthService.getToken()) return this.navigateUnauthenticated();

		try {
			const user = await this.getUser();
			if (!user) return this.navigateUnauthenticated();

			await this.navigateAuthenticated(user);
		} catch {
			return this.navigateUnauthenticated();
		}
	}
}
