import { SkillId } from '../skill';
import { LeetifyRatingNormalizedBase } from './leetify-rating-normalized-base';

export class PersonalPerformanceRating extends LeetifyRatingNormalizedBase {
	public readonly descriptionExtra = 'We take into account your historical average for LR, the map you played and what friends you queued with, and based on those factors set an expected LR for you. We then compare your actual LR for the match to your expected LR, and the result is your PP. As an example, if your expected LR for this match was +3.00 and your actual LR was +3.50, your PP would be +0.50.';
	public readonly description = 'Personal Performance (PP) is your Leetify Rating (LR) adjusted based on your history. It is a great tool for comparing you to yourself, and seeing if this was a particularly good or bad game for you.';
	public readonly fallbackValue = 'Not Enough Data';
	public readonly id = SkillId.PERSONAL_PERFORMANCE_RATING;
	public readonly title = 'Personal Performance';
}
