<app-attempting-login *ngIf="attemptingLogin"></app-attempting-login>

<main *ngIf="!attemptingLogin">
	<router-outlet></router-outlet>
</main>

<div class="socials">
	<a *ngFor="let social of socialMediaLinks" [href]="social.link" target="_blank" [attr.aria-label]="social.title">
		<leetify-icon [icon]="social.icon"></leetify-icon>
	</a>
</div>
