<ng-template [ngIf]="isImportStatusWidget === false">
	<div class="message-wrapper">
		<div class="message" [ngClass]="warning.style">
			<leetify-icon *ngIf="warning.style === CriticalWarningStyle.WARNING || warning.style === CriticalWarningStyle.DANGER" icon="alert_circle_outline" class="message-icon"></leetify-icon>
			<leetify-icon *ngIf="warning.style === CriticalWarningStyle.SUCCESS" icon="check" class="message-icon"></leetify-icon>
			<p>{{ warning.text }}</p>
			<a *ngIf="warning.destinationUrl && !warning.isInternalUrl"
				target="_blank"
				[href]="warning.destinationUrl"
				(click)="trackLinkClick(warning)">{{ warning.urlText }}</a>
			<a *ngIf="warning.destinationUrl && warning.isInternalUrl"
				[routerLink]="warning.destinationUrl"
				(click)="trackLinkClick(warning)">{{ warning.urlText }}</a>
		</div>

		<button *ngIf="isDismissable" class="btn-close" aria-label="Dismiss" title="Dismiss" (click)="dismissWarning(warning)"><i class="mdi mdi-close mdi-12px"></i></button>
	</div>
</ng-template>

<ng-template [ngIf]="isImportStatusWidget === true">
	<div class="message-wrapper import-status">
		<div class="message" [ngClass]="warning.style">
			<leetify-icon *ngIf="warning.style === CriticalWarningStyle.WARNING || warning.style === CriticalWarningStyle.DANGER" icon="alert_circle_outline" class="message-icon"></leetify-icon>
			<leetify-icon *ngIf="warning.style === CriticalWarningStyle.SUCCESS" icon="check" class="message-icon"></leetify-icon>
			<div class="message-body">
				<p class="text-dismissed">{{ warning.text }}</p>
				<a *ngIf="warning.destinationUrl && !warning.isInternalUrl"
					target="_blank"
					[href]="warning.destinationUrl"
					(click)="trackLinkClick(warning)">{{ warning.urlText }}</a>
				<a *ngIf="warning.destinationUrl && warning.isInternalUrl"
					[routerLink]="warning.destinationUrl"
					(click)="trackLinkClick(warning)">{{ warning.urlText }}</a>
			</div>
		</div>
	</div>
</ng-template>
