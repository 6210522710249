import { Benchmark } from '../../../benchmarks-repository';
import { SkillId } from '../../skill';
import { TBombsiteAttemptRate } from './t-bombsite-attempt-rate';

export class TBombsiteAfterplantRate extends TBombsiteAttemptRate {
	public readonly benchmarkKeyAvg: keyof Benchmark = 'tBombsiteAfterplantAvg';
	public readonly benchmarkKeyStd: keyof Benchmark = 'tBombsiteAfterplantStd';

	description = 'The amount of times you successfully defended the planted bomb, divided by all times you tried to defend the bombsite against retaking CTs.';
	id = SkillId.T_BOMBSITE_AFTERPLANT_RATE;
	smallDescription = 'The amount of times you successfully defended the planted bomb, divided by all times you tried to defend the bombsite against retaking CTs.';
	title = 'Afterplants';
}
