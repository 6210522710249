import { NgModule } from '@angular/core';
import { provideLottieOptions, LottieComponent, provideCacheableAnimationLoader } from 'ngx-lottie';

@NgModule({
	imports: [
		LottieComponent,
	],

	providers: [
		provideLottieOptions({
			player: () => import('lottie-web/build/player/lottie_light'),
		}),
		provideCacheableAnimationLoader(),
	],

	exports: [
		LottieComponent,
	],
})
export class LottieModule {
	//
}
