import { Benchmark } from '../../benchmarks-repository';
import { FocusAreaSkill } from '../focus-area-skill';
import { PostSign, SkillGroupId } from '../skill';

export abstract class TradeKillsSuccessPercentageBase extends FocusAreaSkill {
	public readonly benchmarkKeyAvg: keyof Benchmark = 'tradeKillsSuccessPercentageAvg';
	public readonly benchmarkKeyStd: keyof Benchmark = 'tradeKillsSuccessPercentageStd';
	public readonly dependsOn = null;
	public readonly description = '';
	public readonly descriptionExtra = '';
	public readonly detailsInfo = false;
	public readonly displayInGoodBadSummary = false;
	public readonly focusAreaLowerBound = 8;
	public readonly focusAreaUpperBound = 82;
	public readonly groupIcon = 'mdi mdi-progress-wrench';
	public readonly groupId: SkillGroupId = 'positioningSkillGroup';
	public readonly groupTitle = 'Positioning group';
	public readonly icon = 'mdi mdi-weather-cloudy';
	public readonly improveTip = false;
	public readonly inversed = false;
	public readonly isRatio = false;
	public readonly minValue = 0;
	public readonly postSign: PostSign = '%';
	public readonly shortTitle = 'Trade Kill Success %';
	public readonly smallDescription = '';
	public readonly title = 'Trade Kill Success Percentage';
}
