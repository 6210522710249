import { Benchmark } from '../../../benchmarks-repository';
import { MapSideWinrate } from './map-side-win-rate';
import { SkillId } from '../../skill';
import { TeamName } from '../../../enums';

export class TRoundWinRateDust2 extends MapSideWinrate {
	public readonly benchmarkKeyAvg: keyof Benchmark = 'tRoundWinRateDust2Avg';
	public readonly benchmarkKeyStd: keyof Benchmark = 'tRoundWinRateDust2Std';

	id = SkillId.T_ROUND_WIN_RATE_DUST2;
	side = TeamName.TERRORIST;
	title = 'T Round Win Rate Dust 2';
}
