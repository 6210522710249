import { Benchmark } from '../../benchmarks-repository';
import { PostSign, SkillGroupId, SkillId } from '../skill';
import { FocusAreaSkill } from '../focus-area-skill';

export class Preaim extends FocusAreaSkill {
	public readonly assumeNotApplicableIfZero = true;
	public readonly benchmarkKeyAvg: keyof Benchmark = 'preaimAvg';
	public readonly benchmarkKeyStd: keyof Benchmark = 'preaimStd';
	public readonly calculationChangeDates = [{ date: '04.10.2020', url: 'https://leetify.com/blog/enemy-actually-spotted/' }];
	public readonly dependsOn = 'shotsHitFoe';
	public readonly description = 'Crosshair placement measures the average distance (in degrees) your crosshair moved from first seeing the enemy player until you did damage to them. The lower your value is, the better your crosshair placement.';
	public readonly descriptionExtra = 'Difference between the player eye angles from the moment of the enemy’s first appearance in your field of view and the first shot hit on the enemy. Outliers are excluded by taking the median.';
	public readonly detailsInfo = false;
	public readonly displayInGoodBadSummary = true;
	public readonly fallbackValue = 'n/a';
	public readonly focusAreaLowerBound = 20;
	public readonly focusAreaUpperBound = 5;
	public readonly groupIcon = 'mdi mdi-crosshairs-gps';
	public readonly groupId: SkillGroupId = 'aimSkillGroup';
	public readonly groupTitle = 'Aim group';
	public readonly icon = 'mdi mdi-ruler';
	public readonly id = SkillId.PREAIM;
	public readonly improveTip = true;
	public readonly inversed = true;
	public readonly isRatio = false;
	public readonly minValue = 0;
	public readonly postSign: PostSign = '°';
	public readonly shortTitle = 'Cross. Placement';
	public readonly smallDescription = 'Difference between the player eye angles from the moment of the enemy’s first appearance in your field of view and the first shot hit on the enemy.';
	public readonly title = 'Crosshair Placement';
}
