import { PostSign, Skill, SkillId } from '../skill';

export class RoundsSurvivedPercentage extends Skill {
	public readonly benchmarkKeyAvg = undefined;
	public readonly benchmarkKeyStd = undefined;
	public readonly postSign: PostSign = '%';

	dependsOn = null;
	description = '';
	detailsInfo = false;
	displayInGoodBadSummary = false;
	groupId = null;
	groupTitle = 'General';
	id = SkillId.ROUNDS_SURVIVED_PERCENTAGE;
	improveTip = false;
	inversed = false;
	isRatio = false;
	shortTitle = 'Survived %';
	smallDescription = '';
	title = 'Rounds Survived %';
}
