import { Directive, TemplateRef } from '@angular/core';

@Directive({
	selector: '[leetifyCardBodyOuter]',
})
export class CardBodyOuterDirective {
	public constructor(
		public readonly templateRef: TemplateRef<unknown>,
	) {
		//
	}
}
