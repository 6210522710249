import { Benchmark } from '../../benchmarks-repository';
import { PostSign, Skill, SkillGroupId, SkillId } from '../skill';

export class ReactionTime extends Skill {
	public readonly assumeNotApplicableIfZero = true;
	public readonly benchmarkKeyAvg: keyof Benchmark = 'reactionTimeAvg';
	public readonly benchmarkKeyStd: keyof Benchmark = 'reactionTimeStd';
	public readonly calculationChangeDates = [{ date: '04.10.2020', url: 'https://leetify.com/blog/enemy-actually-spotted/' }];
	public readonly dependsOn = 'shotsHitFoe';
	public readonly descriptionExtra = 'Time to damage is NOT reaction time. It\'s a combination of your accuracy, crosshair placement, your weapons fire rate and your reaction time, so don\'t expect it to be near 200 ms.';
	public readonly description = 'Time to damage measures the average time it took you from seeing the enemy player to first dealing damage to them. Trigger discipline plays are excluded.';
	public readonly detailsInfo = false;
	public readonly displayInGoodBadSummary = true;
	public readonly fallbackValue = 'n/a';
	public readonly groupIcon = 'mdi mdi-crosshairs-gps';
	public readonly groupId: SkillGroupId = 'aimSkillGroup';
	public readonly groupTitle = 'Aim group';
	public readonly icon = 'mdi mdi-av-timer';
	public readonly id = SkillId.REACTION_TIME;
	public readonly improveTip = false;
	public readonly inversed = true;
	public readonly isRatio = false;
	public readonly minValue = 0;
	public readonly postSign: PostSign = 'ms';
	public readonly smallDescription = 'Time to damage measures the average time it took you from seeing the enemy player to first dealing damage to them. Trigger discipline plays are excluded.';
	public readonly title = 'Time to Damage';
}
