import { Injectable } from '@angular/core';
import { Subject } from 'rxjs';
import { ContentNotification } from 'leetify-shared-utils/dto';
import { FocusArea } from 'leetify-shared-utils/focus-areas/focus-area';

export interface BaseToast {
	autohide?: boolean;
	class?: string;
	delay?: number;
	dismissCallback?: () => void;
	interactCallback?: () => void;
	type: undefined | 'content-notification' | 'focusAreaPassed' | 'highlightPinned';
}

export interface ContentNotificationToast extends BaseToast {
	contentNotification: ContentNotification;
	type: 'content-notification';
}

export interface FocusAreaToast extends BaseToast {
	passedFocusArea: FocusArea;
	replacedBy: FocusArea;
	templateId: number;
	type: 'focusAreaPassed';
}

export interface HighlightPinnedToast extends BaseToast {
	type: 'highlightPinned';
}

export interface PlainTextToast extends BaseToast {
	text: string;
	type: undefined;
}

export type Toast = ContentNotificationToast | FocusAreaToast | HighlightPinnedToast | PlainTextToast;

@Injectable({ providedIn: 'root' })
export class ToastService {
	public readonly toastsUpdated$ = new Subject<void>();

	public toasts: Toast[] = [];

	public error(text: string, delay = 5000): void {
		this.show(text, {
			class: 'bg-danger text-white',
			delay,
		});
	}

	public warning(text: string, delay = 5000): void {
		this.show(text, {
			class: 'bg-warning text-white',
			delay,
		});
	}

	public success(text: string, delay = 5000): void {
		this.show(text, {
			class: 'bg-success text-white',
			delay,
		});
	}

	public focusAreaPassed(dismissCallback: () => void, passedFocusArea: FocusArea, replacedBy?: FocusArea): void {
		this.toasts.push({
			dismissCallback,
			passedFocusArea,
			replacedBy,

			autohide: false,
			class: 'focus-area-toast',
			templateId: Math.floor(Math.random() * 2),
			type: 'focusAreaPassed',
		});
		this.toastsUpdated$.next();
	}

	public showContentNotification(
		interactCallback: () => void,
		dismissCallback: () => void,
		contentNotification: ContentNotification,
	): void {
		const isAlreadyActive = this.toasts.some((toast) => {
			if (!toast.hasOwnProperty('contentNotification')) return false;
			return ((toast as ContentNotificationToast)?.contentNotification?.id === contentNotification.id);
		});
		if (isAlreadyActive) return;

		this.toasts.push({
			contentNotification,
			dismissCallback,
			interactCallback,
			autohide: false,
			class: 'content-notification-toast',
			type: 'content-notification',
		});
		this.toastsUpdated$.next();
	}

	public highlightPinned(): void {
		this.toasts.push({
			class: 'highlight-pinned-toast bg-success',
			delay: 5000,
			type: 'highlightPinned',
		});
		this.toastsUpdated$.next();
	}

	private show(text: string, options: Partial<BaseToast> = {}): void {
		this.toasts.push({ text, ...options as any });
		this.toastsUpdated$.next();
	}

	public remove(toast: any): void {
		this.toasts = this.toasts.filter((t) => t !== toast);
		this.toastsUpdated$.next();
	}
}
