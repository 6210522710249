import { Benchmark } from '../../../benchmarks-repository';
import { PostSign, Skill, SkillGroupId, SkillId } from '../../skill';

export class DisadvProportionRate extends Skill {
	public readonly benchmarkKeyAvg: keyof Benchmark = 'disadvProportionRateAvg';
	public readonly benchmarkKeyStd: keyof Benchmark = 'disadvProportionRateStd';
	public groupId: SkillGroupId = 'teamplaySituationGroup';
	public postSign: PostSign = '%';

	dependsOn = null;
	description = 'Number of rounds where your team has a 4v5 disadvantage from the opening duel divided by all rounds played.';
	detailsInfo = false;
	displayInGoodBadSummary = false;
	groupTitle = '4v5';
	id = SkillId.DISADV_PROPORTION_RATE;
	improveTip = false;
	inversed = true;
	isRatio = true;
	minValue = 0;
	smallDescription = 'Percentage of rounds where your team lost the opening duel to make it a 4v5.';
	title = '% of Rounds';
}
