import { Component, Input } from '@angular/core';
import { CriticalWarning } from 'leetify-shared-utils/dto';
import { PosthogTrackingValue, CriticalWarningStyle } from 'leetify-shared-utils/enums';
import { CriticalWarningsService } from 'src/app/services/critical-warnings.service';
import { TrackerService } from 'src/app/services/tracker.service';

@Component({
	selector: 'app-critical-warning',
	templateUrl: './critical-warning.component.html',
	styleUrls: ['./critical-warning.component.scss'],
})
export class CriticalWarningWidget {
	@Input() public warning: CriticalWarning;
	@Input() public isImportStatusWidget = true;
	@Input() public isDismissable = true;

	public CriticalWarningStyle = CriticalWarningStyle;

	public constructor(
		protected readonly criticalWarningService: CriticalWarningsService,
		protected readonly trackerService: TrackerService,
	) {
		//
	}

	public dismissWarning(warning: CriticalWarning): void {
		this.criticalWarningService.dismissWarning(warning);
	}

	public trackLinkClick(warning: CriticalWarning): void {
		this.trackerService.track(PosthogTrackingValue.CRITICAL_WARNING_LINK_CLICKED, {
			id: warning.id,
			url: warning.destinationUrl,
		});
	}
}
