import { Benchmark } from '../../benchmarks-repository';
import { Skill, SkillId } from '../skill';

export class HltvRating extends Skill {
	public readonly benchmarkKeyAvg: keyof Benchmark = 'hltvRatingAvg';
	public readonly benchmarkKeyStd: keyof Benchmark = 'hltvRatingStd';

	decimalPlaces = 2;
	dependsOn = null;
	description = '';
	detailsInfo = false;
	displayInGoodBadSummary = false;
	groupId = null;
	groupTitle = 'Ratings';
	id = SkillId.HLTV_RATING;
	improveTip = false;
	inversed = false;
	isRatio = false;
	isScaledForMatch = true;
	shortTitle = 'HLTV Rating';
	smallDescription = '';
	title = 'HLTV Rating 1.0';
}
