<aside class="sidebar-container d-none d-lg-flex">
	<a routerLink="/app/onboarding" class="onboarding-logo">
		<img src="/assets/images/leetify-logo-primary-white.svg" alt="Leetify">
	</a>

	<ol class="sidebar-menu">
		<li>
			<i
				*ngIf="isOnboardingStateActive(OnboardingStates.STEAM)"
				class="fas fa-circle fa-xs text-primary"
			></i>

			<i
				*ngIf="user && user.steam64Id && !isOnboardingStateActive(OnboardingStates.STEAM)"
				class="fas fa-check-square text-success"
			></i>

			<span [ngClass]="getOnboardingStateLabelClass(OnboardingStates.STEAM)">Steam Account</span>
		</li>

		<li>
			<i
				*ngIf="isOnboardingStateActive(OnboardingStates.DATA_SOURCES) || isOnboardingStateActive(OnboardingStates.MATCHMAKING) || isOnboardingStateActive(OnboardingStates.ESPORTAL)"
				class="fas fa-circle fa-xs text-primary"
			></i>

			<i
				*ngIf="user && user.steam64Id && (!isOnboardingStateActive(OnboardingStates.DATA_SOURCES) && !isOnboardingStateActive(OnboardingStates.MATCHMAKING) && !isOnboardingStateActive(OnboardingStates.ESPORTAL))"
				class="fas fa-check-square text-success"
			></i>

			<span [ngClass]="getOnboardingStateLabelClass(OnboardingStates.DATA_SOURCES)">Data Source</span>
		</li>

		<li>
			<i
				*ngIf="isOnboardingStateActive(OnboardingStates.STEAM_BOT)"
				class="fas fa-circle fa-xs text-primary"
			></i>

			<i
				*ngIf="!isOnboardingStateActive(OnboardingStates.STEAM_BOT) && isOnboardingStateActive(OnboardingStates.DISCORD)"
				class="fas fa-check-square text-success"
			></i>

			<span [ngClass]="getOnboardingStateLabelClass(OnboardingStates.STEAM_BOT)">Steam Notifications</span>
		</li>

		<li>
			<i
				*ngIf="isOnboardingStateActive(OnboardingStates.DISCORD)"
				class="fas fa-circle fa-xs text-primary"
			></i>

			<span [ngClass]="getOnboardingStateLabelClass(OnboardingStates.DISCORD)">Discord Community</span>
		</li>
	</ol>

	<div></div>
	<div></div>

	<div class="px-2">
		<a routerLink="/app/onboarding/support" class="btn btn-base btn-block" routerLinkActive="active" queryParamsHandling="merge">
			<i class="leet-support sidebar-icon"></i>
			Support
		</a>

		<button (click)="onLogout()" class="btn btn-base btn-block">
			<i class="fas fa-sign-out-alt sidebar-icon"></i>
			Logout
		</button>
	</div>
</aside>

<a routerLink="/app/onboarding" class="onboarding-logo d-lg-none mobile-logo">
	<img src="/assets/images/leetify-logo-primary-white.svg" alt="Leetify">
</a>

<main class="container-fluid pb-4">
	<div *ngIf="user" class="pl-2 pl-lg-4">
		<div class="onboarding-spacer d-none d-lg-block"></div>
		<router-outlet></router-outlet>
	</div>

	<div class="px-2 d-lg-none mt-4">
		<a routerLink="/app/onboarding/support" class="btn btn-base btn-block mb-2" routerLinkActive="active" queryParamsHandling="merge">
			<i class="leet-support sidebar-icon"></i>
			Support
		</a>

		<button (click)="onLogout()" class="btn btn-base btn-block">
			<i class="fas fa-sign-out-alt sidebar-icon"></i>
			Logout
		</button>
	</div>
</main>
