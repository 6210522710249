import { Skill } from '../skill';

export abstract class LeetifyRatingBase extends Skill {
	public readonly dependsOn = null;
	public readonly detailsInfo = false;
	public readonly displayInGoodBadSummary = false;
	public readonly groupId = null;
	public readonly groupTitle = 'Ratings';
	public readonly improveTip = false;
	public readonly inversed = false;
	public readonly isRatio = true;
	public readonly isScaledForMatch = true;
	public readonly keepValuesVerbatim = true;
	public readonly postSign = '+';
	public readonly smallDescription = '';
	public readonly isNaForLeavers = true;
	public readonly leaverReasoning = 'Player was disconnected for more than half of the match, so we can\'t calculate an accurate Leetify Rating.';
}
