import { Benchmark } from '../../benchmarks-repository';
import { OpeningDuelSuccessPercentage } from './opening-duel-success-percentage';
import { SkillId } from '../skill';
import { TeamName } from '../../enums';

export class CtOpeningDuelSuccessPercentage extends OpeningDuelSuccessPercentage {
	public readonly benchmarkKeyAvg: keyof Benchmark = 'ctOpeningDuelSuccessPercentageAvg';
	public readonly benchmarkKeyStd: keyof Benchmark = 'ctOpeningDuelSuccessPercentageStd';
	id = SkillId.CT_OPENING_DUEL_SUCCESS_PERCENTAGE;
	side = TeamName.COUNTER_TERRORIST;
	title = 'CT Opening Duel Success Percentage';
	public readonly showChart: boolean = false;

	focusAreaLowerBound = 1;
	focusAreaUpperBound = 96;
}
