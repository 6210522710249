import { Benchmark } from '../../../benchmarks-repository';
import { MapSideWinrate } from './map-side-win-rate';
import { SkillId } from '../../skill';
import { TeamName } from '../../../enums';

export class TRoundWinRateNuke extends MapSideWinrate {
	public readonly benchmarkKeyAvg: keyof Benchmark = 'tRoundWinRateNukeAvg';
	public readonly benchmarkKeyStd: keyof Benchmark = 'tRoundWinRateNukeStd';

	id = SkillId.T_ROUND_WIN_RATE_NUKE;
	side = TeamName.TERRORIST;
	title = 'T Round Win Rate Nuke';
}
