import { Benchmark } from '../../benchmark-interfaces';
import { SkillId } from '../skill';
import { PlayerClutchWinPercentageBase } from './player-clutch-win-percentage-base';

export class PlayerClutchWinPercentage extends PlayerClutchWinPercentageBase {
	public readonly benchmarkKeyAvg: keyof Benchmark = 'playerClutchWinPercentageAvg';
	public readonly benchmarkKeyStd: keyof Benchmark = 'playerClutchWinPercentageStd';
	public readonly focusAreaLowerBound = 9;
	public readonly focusAreaUpperBound = 22;
	public readonly id = SkillId.PLAYER_CLUTCH_WIN_PERCENTAGE;
	public readonly title = 'Overall Clutch Wins';
}
