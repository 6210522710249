import { Skill, SkillId } from '../skill';

export class Multikill5Rounds extends Skill {
	public readonly benchmarkKeyAvg = undefined;
	public readonly benchmarkKeyStd = undefined;

	dependsOn = null;
	description = '';
	detailsInfo = false;
	displayInGoodBadSummary = false;
	groupId = null;
	groupTitle = 'General';
	id = SkillId.MULTIKILL_5_ROUNDS;
	improveTip = false;
	inversed = false;
	isRatio = false;
	shortTitle = '5K';
	smallDescription = '';
	title = 'Total of rounds with 5 kills';
}
