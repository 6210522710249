import { Benchmark } from '../../benchmarks-repository';
import { SkillId } from '../skill';
import { PlayerClutchWinPercentageBase } from './player-clutch-win-percentage-base';

export class PlayerClutch1v3WinPercentage extends PlayerClutchWinPercentageBase {
	public readonly benchmarkKeyAvg: keyof Benchmark = 'playerClutch1v3WinPercentageAvg';
	public readonly benchmarkKeyStd: keyof Benchmark = 'playerClutch1v3WinPercentageStd';
	public readonly focusAreaLowerBound = 2;
	public readonly focusAreaUpperBound = 11;
	public readonly id = SkillId.PLAYER_CLUTCH_1V3_WIN_PERCENTAGE;
	public readonly title = '1v3 Clutch Wins';
}
