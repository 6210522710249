import { Injectable } from '@angular/core';
import { Subject } from 'rxjs';
import { HttpClient } from '@angular/common/http';
import { PosthogTrackingValue } from 'leetify-shared-utils/enums';
import { CriticalWarning, WarningsResponse, GroupedWarnings } from 'leetify-shared-utils/dto';
import { LocalStorageService } from 'src/app/services/local-storage.service';
import { TrackerService } from 'src/app/services/tracker.service';
import { StorageKey, StorageService } from './storage.service';
import { SessionStorageService } from './session-storage.service';

@Injectable({
	providedIn: 'root',
})
export class CriticalWarningsService {
	private criticalWarningsSource = new Subject<GroupedWarnings>();
	public criticalWarnings$ = this.criticalWarningsSource.asObservable();

	private newCriticalWarningsSource = new Subject<CriticalWarning & { isDismissed: boolean; }>();
	public newCriticalWarnings$ = this.newCriticalWarningsSource.asObservable();

	public constructor(
		private readonly http: HttpClient,
		private readonly localStorageService: LocalStorageService,
		private readonly sessionStorageService: SessionStorageService,
		private readonly trackerService: TrackerService,
	) {
		//
	}

	public newWarning(warning: CriticalWarning): void {
		const isDismissed = warning?.id ? this.isWarningDismissed(warning.id) : false;
		this.newCriticalWarningsSource.next({ ...warning, isDismissed });
	}

	public reloadWarnings(): void {
		// this.http.get<WarningsResponse>('/api/critical-warnings').subscribe((warnings: WarningsResponse) => {
		// 	this.criticalWarningsSource.next(this.handleWarnings(warnings));
		// });
	}

	private handleWarnings(criticalWarningsResponse: WarningsResponse): GroupedWarnings {
		const dismissedWarnings = this.getDismissedWarnings();

		// Filter out warnings that have already been dismissed, then return the rest
		const activeWarnings = criticalWarningsResponse.content.filter((criticalWarning) => !dismissedWarnings.includes(criticalWarning.id));
		const dismissedActiveWarnings = criticalWarningsResponse.content.filter((criticalWarning) => dismissedWarnings.includes(criticalWarning.id));

		return { activeWarnings, dismissedActiveWarnings };
	}

	public dismissWarning(criticalWarning: CriticalWarning): void {
		const storageService: StorageService = criticalWarning.isDismissedSessionally ? this.sessionStorageService : this.localStorageService;

		const dismissedWarnings = storageService.getValue<string[]>(StorageKey.DISMISSED_WARNINGS) || [];
		dismissedWarnings.push(criticalWarning.id);

		storageService.saveValue<string[]>(StorageKey.DISMISSED_WARNINGS, dismissedWarnings);
		this.trackerService.track(PosthogTrackingValue.CRITICAL_WARNING_DISMISSED);
		this.reloadWarnings();
	}

	public getDismissedWarnings(): string[] {
		return [
			...(this.localStorageService.getValue<string[]>(StorageKey.DISMISSED_WARNINGS) || []),
			...(this.sessionStorageService.getValue<string[]>(StorageKey.DISMISSED_WARNINGS) || []),
		];
	}

	public isWarningDismissed(id: string): boolean {
		return this.getDismissedWarnings().includes(id);
	}
}
