import { Pipe, PipeTransform } from '@angular/core';
import { ViewHelper } from 'src/app/helpers/view.helper';

@Pipe({
	name: 'leetifyRating',
})
export class LeetifyRatingPipe implements PipeTransform {
	public transform(value: number): string {
		return ViewHelper.formatLeetifyRating(value);
	}
}
