import { Directive, ElementRef, HostListener, OnInit } from '@angular/core';
import { Router } from '@angular/router';
import { environment } from 'src/environments/environment';

/** Usage: `<a href="#id" scrollToAnchor>link text</a>` */
@Directive({
	selector: '[scrollToAnchor]',
})
export class ScrollToAnchorDirective implements OnInit {
	protected anchorElementId: string; // this includes a leading `#`

	public constructor(
		protected readonly eref: ElementRef<Element>,
		protected readonly router: Router,
	) {
		//
	}

	public ngOnInit(): void {
		const el = this.eref.nativeElement;
		if (!(el instanceof HTMLAnchorElement)) return;

		const hrefAttribute = el.getAttribute('href');
		if (!hrefAttribute.startsWith('#')) return;

		// set the element's href value to an absolute link on the current url (otherwise, the <base> tag would make it relative to root)
		const href = typeof window === 'undefined'
			? `${environment.baseUrl}${this.router.url}${hrefAttribute}`
			: `${window.location.origin}${window.location.pathname}${hrefAttribute}`;

		el.setAttribute('href', href);

		// save the id of the element we want to scroll to later
		this.anchorElementId = new URL(el.href).hash;
	}

	@HostListener('click', ['$event'])
	public onClick(e: Event): void {
		if (e) e.preventDefault();
		if (!this.anchorElementId) return;

		const el = document.querySelector(this.anchorElementId);
		if (!el) return;

		// replace the current url with the hash
		if (typeof window !== 'undefined') {
			window.history.replaceState(null, null, `${window.location.origin}${window.location.pathname}${this.anchorElementId}`);
		}

		el.scrollIntoView({ behavior: 'smooth' });
	}
}
