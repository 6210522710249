import { Benchmark } from '../../benchmarks-repository';
import { FocusAreaSkill } from '../focus-area-skill';
import { PostSign, SkillGroupId, SkillId } from '../skill';

export class UtilityOnDeathAvg extends FocusAreaSkill {
	public readonly benchmarkKeyAvg: keyof Benchmark = 'utilityOnDeathAvg';
	public readonly benchmarkKeyStd: keyof Benchmark = 'utilityOnDeathStd';
	public groupId: SkillGroupId = 'utilitySkillGroup';
	public postSign: PostSign = '$';

	dependsOn = null;
	description = 'The average value of utility (Smoke, HE, Flashbang, Molotov / Incendiary & Decoy) that you had upon death.';
	detailsInfo = false;
	displayInGoodBadSummary = true;
	fallbackValue = 'n/a';
	groupIcon = 'mdi mdi-progress-wrench';
	groupTitle = 'Utility group';
	icon = 'mdi mdi-cash-100';
	id = SkillId.UTILITY_ON_DEATH_AVG;
	improveTip = false;
	inversed = true;
	isRatio = false;
	minValue = 0;
	shortTitle = 'Avg unused utility';
	smallDescription = 'The average value of utility (Smoke, HE, Flashbang, Molotov / Incendiary & Decoy) that you had upon death.';
	title = 'Unused Utility on Death';

	focusAreaLowerBound = 600;
	focusAreaUpperBound = 0;
}
