import { Benchmark } from '../../benchmarks-repository';
import { FocusAreaSkill } from '../focus-area-skill';
import { SkillGroupId, SkillId } from '../skill';

export class FlashbangScore extends FocusAreaSkill {
	public readonly benchmarkKeyAvg: keyof Benchmark = 'flashbangScoreAvg';
	public readonly benchmarkKeyStd: keyof Benchmark = 'flashbangScoreStd';
	public groupId: SkillGroupId = 'focusAreaSkillGroup';

	dependsOn = null;
	description = '';
	descriptionExtra = '';
	detailsInfo = false;
	displayInGoodBadSummary = false;
	groupIcon = '';
	groupTitle = 'Focus Area group';
	icon = '';
	id = SkillId.FLASHBANG_SCORE;
	improveTip = true;
	inversed = false;
	isRatio = true;
	minValue = 0;
	smallDescription = '';
	title = 'Average Flashbang Score';

	focusAreaLowerBound = 1;
	focusAreaUpperBound = 14;
}
