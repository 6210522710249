import { Component, HostBinding, Input, OnChanges } from '@angular/core';
import { isNil } from 'lodash-es';
import { CalculatorHelper } from 'leetify-shared-utils/calculator.helper';

export enum FillStyle {
	CT = 'ct',
	FOUNDER = 'founder',
	HEAD_TO_HEAD_GRAY = 'head-to-head-gray',
	LANDING_HOME_COMPARE_MUTED = 'landing-home-compare-muted',
	PINK = 'pink',
	PRO = 'pro',
	SOLID_AVERAGE = 'solid-average',
	SOLID_CT = 'solid-ct',
	SOLID_GOOD = 'solid-good',
	SOLID_GREAT = 'solid-great',
	SOLID_PINK = 'solid-pink',
	SOLID_POOR = 'solid-poor',
	SOLID_PURPLE = 'solid-purple',
	SOLID_SUBPAR = 'solid-subpar',
	SOLID_T = 'solid-t',
	STAFF = 'staff',
	T = 't',
}

export enum BackgroundStyle {
	SURFACE1 = 'surface1',
	SURFACE2 = 'surface2',
	SURFACE3 = 'surface3',
	SURFACE4 = 'surface4',
	SURFACE5 = 'surface5',
}

@Component({
	selector: 'app-progress-bar-chart',
	templateUrl: './progress-bar-chart.component.html',
	styleUrls: ['./progress-bar-chart.component.scss'],
})
export class ProgressBarChartComponent implements OnChanges {
	@Input() protected readonly backgroundStyle: BackgroundStyle = BackgroundStyle.SURFACE3;
	@Input() protected readonly fillStyle?: FillStyle;
	@Input() protected readonly lineScale?: [number, number];
	@Input() protected readonly lineValue?: number;
	@Input() protected readonly scale: [number, number] = [0, 1];
	@Input() protected readonly value: number;

	@HostBinding('class') protected backgroundStyleClass: string;

	protected fill: number;
	protected lineTranslate: number;

	public ngOnChanges(): void {
		this.fill = CalculatorHelper.rescale(this.value, this.scale[0], this.scale[1], 0, 1);

		this.lineTranslate = !isNil(this.lineValue)
			? CalculatorHelper.rescale(this.lineValue, (this.lineScale || this.scale)[0], (this.lineScale || this.scale)[1], 0, 100)
			: undefined;

		this.backgroundStyleClass = `--${this.backgroundStyle}`;
	}
}
