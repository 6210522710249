import { EventEmitter, Injectable } from '@angular/core';
import { OnboardingStates } from 'leetify-shared-utils/enums';

@Injectable({
	providedIn: 'root',
})
export class OnboardingStateService {
	public nextOnboardingState$ = new EventEmitter<OnboardingStates>();

	constructor() {}

	public emitNextState(state: OnboardingStates): void {
		this.nextOnboardingState$.emit(state);
	}
}
