import { MapName } from '../enums';

export enum PracticeServerType {
	DUELS = 'duels', // does not exist anymore, kept so we don't need to update the database
	KZ = 'kz',
	MICRODM = 'microdm', // does not exist anymore, kept so we don't need to update the database
	PRACTICE = 'practice',
	RETAKES = 'retakes',
	SCRIM = 'match',
	SURF = 'surf',
}

export enum PracticeServerLocation {
	AMSTERDAM = 'amsterdam',
	ATLANTA = 'atlanta',
	BARCELONA = 'barcelona',
	BEAUHARNOIS = 'beauharnois',
	BRISTOL = 'bristol',
	CHICAGO = 'chicago',
	COPENHAGEN = 'copenhagen',
	DALLAS = 'dallas',
	DENVER = 'denver',
	DUSSELDORF = 'dusseldorf',
	HELSINKI = 'helsinki',
	ISTANBUL = 'istanbul',
	JOHANNESBURG = 'johannesburg',
	LOS_ANGELES = 'los_angeles',
	MIAMI = 'miami',
	MUMBAI = 'mumbai',
	NEW_YORK_CITY = 'new_york_city',
	PORTLAND = 'portland',
	SANTIAGO = 'santiago',
	SAO_PAULO = 'sao_paulo',
	SINGAPORE = 'singapore',
	STOCKHOLM = 'stockholm',
	STRASBOURG = 'strasbourg',
	SYDNEY = 'sydney',
	TOKYO = 'tokyo',
	WARSAW = 'warsaw',
}

export enum PracticeServerMapName {
	DE_ANCIENT = MapName.DE_ANCIENT,
	DE_ANUBIS = MapName.DE_ANUBIS,
	DE_DUST2 = MapName.DE_DUST2,
	DE_INFERNO = MapName.DE_INFERNO,
	DE_MIRAGE = MapName.DE_MIRAGE,
	DE_NUKE = MapName.DE_NUKE,
	DE_OVERPASS = MapName.DE_OVERPASS,
	DE_VERTIGO = MapName.DE_VERTIGO,
	KZ_COMMUNITYJUMP3 = 'kz_communityjump3',
	KZ_LETO_V2 = 'kz_leto_v2',
	KZ_NICHE = 'kz_niche',
	KZ_ONLYUP = 'kz_onlyup',
	KZ_PHAMOUS = 'kz_phamous',
	SURF_BEGINNER = 'surf_beginner',
	SURF_ELYSIUM = 'surf_elysium',
	SURF_KITSUNE = 'surf_kitsune',
	SURF_ME = 'surf_me',
	SURF_ROOKIE = 'surf_rookie',
}
