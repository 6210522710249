import { Benchmark } from '../../../benchmarks-repository';
import { PostSign, Skill, SkillGroupId, SkillId } from '../../skill';

export class DisadvWinRate extends Skill {
	public readonly benchmarkKeyAvg: keyof Benchmark = 'disadvWinRateAvg';
	public readonly benchmarkKeyStd: keyof Benchmark = 'disadvWinRateStd';
	public groupId: SkillGroupId = 'teamplaySituationGroup';
	public postSign: PostSign = '%';

	dependsOn = null;
	description = 'The number of rounds your team won with a 4v5 disadvantage divided by total rounds played with a 4v5 disadvantage.';
	detailsInfo = false;
	displayInGoodBadSummary = false;
	groupTitle = '4v5';
	id = SkillId.DISADV_WIN_RATE;
	improveTip = false;
	inversed = false;
	isRatio = true;
	minValue = 0;
	smallDescription = 'Percentage of rounds your team won with a player disadvantage.';
	title = 'Round Win Rate';
}
