import { Injectable } from '@angular/core';
import { HttpClient } from '@angular/common/http';
import { Router } from '@angular/router';
import { ContentNotificationType, PosthogTrackingValue } from 'leetify-shared-utils/enums';
import { ContentNotification, NotificationsResponse } from 'leetify-shared-utils/dto';
import { ToastService } from 'src/app/toast/toast.service';
import { LocalStorageService } from 'src/app/services/local-storage.service';
import { TrackerService } from 'src/app/services/tracker.service';
import { StorageKey } from './storage.service';

@Injectable({
	providedIn: 'root',
})
export class NotificationsService {
	public constructor(
		private readonly http: HttpClient,
		private readonly localStorageService: LocalStorageService,
		private readonly router: Router,
		private readonly toastService: ToastService,
		private readonly trackerService: TrackerService,
	) {
		//
	}

	public reloadNotifications(): void {
		this.http.get('/api/notifications').subscribe((notificationsResponse: NotificationsResponse) => {
			this.handleContentNotifications(notificationsResponse.content);
		});
	}

	private handleContentNotifications(contentNotifications: ContentNotification[]): void {
		const dismissedNotifications = this.localStorageService.getValue<string[]>(StorageKey.DISMISSED_NOTIFICATIONS) || [];

		// Filter out notifications that have already been dismissed, then show the rest
		contentNotifications
			.filter((contentNotification) => !dismissedNotifications.includes(contentNotification.id))
			.forEach((contentNotification) => {

				if (contentNotification.type === ContentNotificationType.TOAST) {
					this.showContentNotificationToast(contentNotification);
				}
			});
	}

	private showContentNotificationToast(contentNotification: ContentNotification): void {

		this.toastService.showContentNotification(
			() => {
				this.dismissNotification(contentNotification);
				this.trackerService.track(PosthogTrackingValue.CONTENT_NOTIFICATION_CLICKED, {
					id: contentNotification.id,
					url: contentNotification.destinationUrl,
				});

				if (contentNotification.destinationUrl.startsWith('http')) {
					window.open(contentNotification.destinationUrl, '_blank');
				} else {
					this.router.navigate([contentNotification.destinationUrl]);
				}
			},
			() => {
				this.dismissNotification(contentNotification);
				this.trackerService.track(PosthogTrackingValue.CONTENT_NOTIFICATION_DISMISSED, {
					id: contentNotification.id,
				});
			},
			contentNotification,
		);
	}

	private dismissNotification(contentNotification: ContentNotification): void {
		const dismissedNotifications = this.localStorageService.getValue<string[]>(StorageKey.DISMISSED_NOTIFICATIONS) || [];
		dismissedNotifications.push(contentNotification.id);

		this.localStorageService.saveValue<string[]>(StorageKey.DISMISSED_NOTIFICATIONS, dismissedNotifications);
	}
}
