import { AvailableProgressReportDTO } from 'leetify-shared-utils/dto';
import { ProgressReportQuarter } from 'leetify-shared-utils/enums';
import { ProgressReportsHelper as SharedProgressReportHelper } from 'leetify-shared-utils/progress-reports-helper';

export class ProgressReportsHelper extends SharedProgressReportHelper {
	public static getFirstMonthName(quarter: ProgressReportQuarter): 'January' | 'April' | 'July' | 'October' {
		switch (quarter) {
			case ProgressReportQuarter.H1: return 'January';
			case ProgressReportQuarter.H2: return 'July';

			case ProgressReportQuarter.Q1: return 'January';
			case ProgressReportQuarter.Q2: return 'April';
			case ProgressReportQuarter.Q3: return 'July';
			case ProgressReportQuarter.Q4: return 'October';
		}
	}

	public static getProgressReportTitleWithoutNickname(year: number, quarter: ProgressReportQuarter): string {
		if (year === 2023 && quarter === ProgressReportQuarter.Q3) return 'Final CS:GO Progress Report';
		if (quarter === ProgressReportQuarter.ANNUAL) return `${year} Recap`;
		return `${year} ${ProgressReportsHelper.getQuarterLabel(quarter)} Progress Report`;
	}

	public static getUrlSegments(report: AvailableProgressReportDTO): (number | string)[] {
		if (report.quarter === ProgressReportQuarter.ANNUAL) return [`/${report.year}`, report.id];
		return ['/progress-report', report.year, report.quarter, report.id];
	}
}
