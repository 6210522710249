import { isNil } from 'lodash-es';
import moment from 'moment';
import { GameDTO } from 'leetify-shared-utils/dto';
import { Skill } from 'leetify-shared-utils/skills-repository';
import { TeamName, MatchResult, DataSource, MapName } from 'leetify-shared-utils/enums';
import { Dictionary } from 'leetify-shared-utils/types';

export class ViewHelper {
	public static readonly MAP_ICON_FALLBACK = '/assets/images/map-icons/none.png';
	public static readonly MAP_IMAGE_FALLBACK = '/assets/images/maps/default.svg';
	public static readonly PLAYER_HEADER_FALLBACK = '/assets/images/player-header.svg';
	public static readonly PROFILE_PICTURE_FALLBACK = '/assets/images/anon.png';
	public static readonly AVAILABLE_MAP_IMAGE_VARIANTS = {
		[MapName.DE_VERTIGO]: 4,
		[MapName.DE_OVERPASS]: 5,
		[MapName.DE_NUKE]: 4,
		[MapName.DE_MIRAGE]: 4,
		[MapName.DE_INFERNO]: 4,
		[MapName.DE_DUST2]: 3,
		[MapName.DE_ANUBIS]: 5,
		[MapName.DE_ANCIENT]: 4,
		[MapName.CS_OFFICE]: 4,
		cs_italy: 4,
	};

	public static getFormattedDataSourceName(dataSource: 'premier' | DataSource): string {
		switch (dataSource) {
			case 'premier': return 'Premier';
			case DataSource.ESPORTAL: return 'Esportal';
			case DataSource.FACEIT: return 'Faceit';
			case DataSource.GAMERS_CLUB: return 'Gamers Club';
			case DataSource.HLTV: return 'HLTV';
			case DataSource.LEETIFY_MATCH: return 'Leetify Match';
			case DataSource.MANUAL: return 'Manual Upload';
			case DataSource.MATCHMAKING_WINGMAN: return 'Matchmaking (Wingman)';
			case DataSource.MATCHMAKING: return 'Matchmaking';
			default: return dataSource;
		}
	}

	static getRankImagePath(dataSource: string, rank: number, forcePng?: boolean): string {
		if (![DataSource.FACEIT, DataSource.MATCHMAKING, DataSource.GAMERS_CLUB, DataSource.MATCHMAKING_WINGMAN].includes(dataSource as DataSource) || rank === undefined || rank === null) return '/assets/images/rank-icons/na.png';

		let extension = dataSource === DataSource.FACEIT || dataSource === DataSource.GAMERS_CLUB ? 'svg' : 'png';
		if (forcePng) extension = 'png';

		return `/assets/images/rank-icons/${dataSource}${rank}.${extension}`;
	}

	static getRatingImagePath(rank: number): string {
		if (rank === undefined || rank === null) return '/assets/images/rank-icons/na.png';

		if (rank === 0) return '/assets/images/rank-icons/cs_rating_unrated.svg';

		// Matchmaking ranks are in 5000 intervals
		let rankBracket = Math.floor(rank / 5000);
		rankBracket = rankBracket > 6 ? 6 : rankBracket;

		return `/assets/images/rank-icons/cs_rating_${rankBracket}.svg`;
	}

	static getPlayerTeam(ps: GameDTO['playerStats'][0]): string {
		if (ps.matchResult === MatchResult.WIN) return 'W';
		if (ps.matchResult === MatchResult.LOSS) return 'L';

		// In case of draw use A or B
		if (ps.initialTeamNumber === TeamName.TERRORIST) return 'A';
		if (ps.initialTeamNumber === TeamName.COUNTER_TERRORIST) return 'B';

		return ps.initialTeamNumber;
	}

	static isUserInputLengthValid(input: string, notLonger: number, notEmpty?: boolean, notShorter?: number): boolean {
		if (notEmpty && !input.length) return false;
		if (notShorter) return input.length < notLonger && input.length > notShorter;
		return input.length < notLonger;
	}

	static getGameMapImage(game: { mapName: string, id: string }, small = true): string {
		return game && game.mapName
			? ViewHelper.getMapImageVariant(game.mapName, game.id, small)
			: '';
	}

	static getMapImageVariant(mapName: string, seed: string, small = true) {
		if (!this.AVAILABLE_MAP_IMAGE_VARIANTS?.[mapName] || !seed) return this.getMapImage(mapName, small);

		const availableVariants = this.AVAILABLE_MAP_IMAGE_VARIANTS[mapName];
		const index = (seed.charCodeAt(0) % (availableVariants + 1));
		if (!index) return this.getMapImage(mapName, small); // Return default if seed is NaN or it happens to roll 0

		return this.getMapImage(mapName, small, `_${index}`);
	}

	static getMapImage(mapName: string, small = true, variant = ''): string {
		return `/assets/images/maps-webp/${small ? 'small/' : ''}${ViewHelper.getFormattedMapNameWithPrefix(mapName)}${variant}${small ? '_small' : ''}.webp`;
	}

	static getGameMapIcon(game: { mapName: string }): string {
		return game && game.mapName
			? ViewHelper.getMapIcon(game.mapName)
			: '';
	}

	static getMapIcon(mapName: string): string {
		return `/assets/images/map-icons/${ViewHelper.getFormattedMapNameWithPrefix(mapName)}.svg`;
	}

	static getFormattedMapNameWithPrefix(mapName: string): string {
		if (!mapName) return '';

		const segments = mapName.split('/');
		return segments[segments.length - 1];
	}

	static getFormattedGameMapName(game: { mapName: string }): string {
		return game && game.mapName
			? ViewHelper.getFormattedMapName(game.mapName)
			: '';
	}

	static getFormattedMapName(mapName: string): string {
		const segments = ViewHelper.getFormattedMapNameWithPrefix(mapName).split('_', 2);
		return segments[segments.length - 1];
	}

	static getSkillHref(skill: Skill): string {
		if (skill.groupId === 'aimSkillGroup') return '/app/aim';
		if (skill.groupId === 'utilitySkillGroup') return '/app/utility';
		return '/lol';
	}

	static getMatchScoreString(playerStats: { ctRoundsLost: number; ctRoundsWon: number; tRoundsLost: number; tRoundsWon: number }, game: GameDTO): string {
		if (!playerStats) return 'n/a';

		const won = playerStats.ctRoundsWon + playerStats.tRoundsWon;
		const lost = playerStats.ctRoundsLost + playerStats.tRoundsLost;

		// Reconciliation logic, in case of late round demo recording starts and other edge cases
		// if (game?.teamScores && (won + lost) !== (game.teamScores[0] + game.teamScores[1])) {
		// 	[won, lost] = game.teamScores;
		// 	if (won > lost && game.teamScores[0] < game.teamScores[1]) [lost, won] = game.teamScores;
		// 	if (won < lost && game.teamScores[0] > game.teamScores[1]) [lost, won] = game.teamScores;
		// }

		return Number.isNaN(won) || Number.isNaN(lost)
			? 'n/a'
			: `${won} : ${lost}`;
	}

	static getMatchResultClass(result: MatchResult): string {
		if (result === MatchResult.WIN) return 'text-great';
		if (result === MatchResult.LOSS) return 'text-poor';

		return 'text-average';
	}

	static getTimeSince(gameTime: Date): string {
		const currentTime = moment();
		const difference = currentTime.diff(gameTime, 'hours');

		let returnString: string;

		if (difference > 24) {
			returnString = `${currentTime.diff(gameTime, 'days')} days`;
		} else if (difference >= 1) {
			returnString = `${difference} hours`;
		} else {
			returnString = 'less than an hour';
		}

		return returnString;
	}

	public static formatLeetifyRating(number: number): string {
		if (number === null || number === undefined) return '-';
		if (number >= 0) return `+${(number * 100).toFixed(2)}`;
		return (number * 100).toFixed(2);
	}

	public static formatCsRating(premierRating: number): string {
		return new Intl.NumberFormat('en-US').format(premierRating);
	}

	public static formatCsRatingChange(number: number): string {
		if (isNil(number)) return;
		if (number === 0) return '0';
		return number > 0 ? `+${number.toFixed(0)}` : number.toFixed(0);
	}

	public static getCsRatingChangeColorClass(number: number): string {
		if (number <= -300) return 'text-poor';
		if (number > -300 && number <= -200) return 'text-subpar';
		if (number > -200 && number <= 99) return 'text-average';
		if (number > 99 && number <= 190) return 'text-good';
		if (number > 190) return 'text-great';
		return 'text-average';
	}

	public static scrollToHashAnchor(): void {
		if (typeof window === 'undefined') return;
		if (!window.location.hash) return;

		const el = document.querySelector(window.location.hash);
		if (el) el.scrollIntoView({ behavior: 'smooth' });
	}

	public static getProfilePictureUrl(profilePictures: Dictionary<string>, steam64Id: string): string {
		return profilePictures && profilePictures[steam64Id] ? profilePictures[steam64Id] : ViewHelper.PROFILE_PICTURE_FALLBACK;
	}

	public static onMapIconErrored(e: ErrorEvent): void {
		ViewHelper.onImageErrored(e, ViewHelper.MAP_ICON_FALLBACK);
	}

	public static onMapImageErrored(e: ErrorEvent): void {
		ViewHelper.onImageErrored(e, ViewHelper.MAP_IMAGE_FALLBACK);
	}

	public static onPlayerHeaderErrored(e: ErrorEvent): void {
		ViewHelper.onImageErrored(e, ViewHelper.PLAYER_HEADER_FALLBACK);
	}

	public static onProfilePictureErrored(e: ErrorEvent): void {
		ViewHelper.onImageErrored(e, ViewHelper.PROFILE_PICTURE_FALLBACK);
	}

	public static onImageErrored(e: ErrorEvent, fallback: string): void {
		if (e.target && e.target instanceof HTMLImageElement && e.target.getAttribute('src') !== fallback) {
			e.target.setAttribute('src', fallback);
		}
	}

	public static trackByIndex(i: number): number {
		return i;
	}

	public static trackById(i: number, obj: { id: string }): string | number {
		return obj?.id ?? i;
	}
}
