import { Benchmark } from '../../../benchmarks-repository';
import { Skill, SkillGroupId, SkillId } from '../../skill';
import { TeamName } from '../../../enums';

export class CtTradeRating extends Skill {
	public readonly benchmarkKeyAvg: keyof Benchmark = 'ctTradeRatingAvg';
	public readonly benchmarkKeyStd: keyof Benchmark = 'ctTradeRatingStd';
	public groupId: SkillGroupId = 'teamplayDuelsGroup';

	dependsOn = null;
	description = '';
	detailsInfo = false;
	displayInGoodBadSummary = false;
	groupTitle = 'Teamplay CT Side';
	id = SkillId.CT_TRADE_RATING;
	improveTip = false;
	inversed = false;
	isRatio = true;
	minValue = 0;
	side = TeamName.COUNTER_TERRORIST;
	smallDescription = '';
	title = 'Trade Rating';
}
