import { Benchmark } from '../../benchmarks-repository';
import { PostSign, Skill, SkillGroupId, SkillId } from '../skill';

export class SprayAccuracy extends Skill {
	public readonly assumeNotApplicableIfZero = true;
	public readonly benchmarkKeyAvg: keyof Benchmark = 'sprayAccuracyAvg';
	public readonly benchmarkKeyStd: keyof Benchmark = 'sprayAccuracyStd';
	public readonly calculationChangeDates = [{ date: '04.10.2020', url: 'https://leetify.com/blog/enemy-actually-spotted/' }];
	public readonly dependsOn = 'shotsHitFoe';
	public readonly descriptionExtra = 'A spray is defined as a sequence of 3+ shots. Spray accuracy is the shots in the sprays that hit the enemy divided by all spray shots. Only counts against spotted enemies.';
	public readonly description = 'When you have an enemy spotted and you’re spraying, how many of your bullets hit an enemy? Includes rifles only.';
	public readonly detailsInfo = true;
	public readonly displayInGoodBadSummary = true;
	public readonly fallbackValue = 'n/a';
	public readonly groupIcon = 'mdi mdi-crosshairs-gps';
	public readonly groupId: SkillGroupId = 'aimSkillGroup';
	public readonly groupTitle = 'Aim group';
	public readonly icon = 'mdi mdi-spray';
	public readonly id = SkillId.SPRAY_ACCURACY;
	public readonly improveTip = true;
	public readonly inversed = false;
	public readonly isRatio = true;
	public readonly minValue = 0;
	public readonly postSign: PostSign = '%';
	public readonly smallDescription = 'Spray accuracy is the shots in the sprays that hit the enemy divided by all spray shots. Only counts against spotted enemies.';
	public readonly title = 'Spray Accuracy';
}
