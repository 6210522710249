import { PostSign, Skill, SkillGroupId } from '../../skill';

export abstract class BombsiteAttemptRate extends Skill {
	public groupId: SkillGroupId = 'teamplaySideGroup';
	public postSign: PostSign = '%';

	dependsOn = null;
	detailsInfo = false;
	displayInGoodBadSummary = false;
	improveTip = false;
	inversed = false;
	isRatio = true;
	isScaledForMatch = true;
	minValue = 0;
}
