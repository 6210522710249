import { Benchmark } from '../../../benchmarks-repository';
import { SkillId } from '../../skill';
import { SprayAccuracyPerWeapon } from './spray-accuracy-per-weapon';
import { Weapon } from '../../../enums';

export class SprayAccuracyM4A4 extends SprayAccuracyPerWeapon {
	public readonly benchmarkKeyAvg: keyof Benchmark = 'sprayAccuracyM4A4Avg';
	public readonly benchmarkKeyStd: keyof Benchmark = 'sprayAccuracyM4A4Std';

	id = SkillId.SPRAY_ACCURACY_M4A4;
	title = 'Spray Accuracy (M4A4)';
	weapon = Weapon.M4A4;

	focusAreaLowerBound = 11;
	focusAreaUpperBound = 56;
}
