import { FocusAreaSkill } from '../focus-area-skill';
import { PostSign, SkillGroupId } from '../skill';

export abstract class PlayerClutchWinPercentageBase extends FocusAreaSkill {
	public readonly dependsOn = null;
	public readonly description = '';
	public readonly detailsInfo = false;
	public readonly displayInGoodBadSummary = false;
	public readonly groupId: SkillGroupId = 'clutchSkillGroup';
	public readonly groupTitle = 'Clutching';
	public readonly improveTip = false;
	public readonly inversed = false;
	public readonly isRatio = true;
	public readonly minValue = 0;
	public readonly postSign: PostSign = '%';
	public readonly smallDescription = '';
}
