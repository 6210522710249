import { Benchmark } from '../../benchmarks-repository';
import { PlayerClutchSavePercentage } from './player-clutch-save-percentage';
import { SkillId } from '../skill';

export class PlayerClutch1v1SavePercentage extends PlayerClutchSavePercentage {
	public readonly benchmarkKeyAvg: keyof Benchmark = 'playerClutch1v1SavePercentageAvg';
	public readonly benchmarkKeyStd: keyof Benchmark = 'playerClutch1v1SavePercentageStd';
	id = SkillId.PLAYER_CLUTCH_1V1_SAVE_PERCENTAGE;
	title = '1v1 Save Rate';
}
