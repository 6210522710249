import { FocusAreaSkill } from '../../focus-area-skill';
import { PostSign, SkillGroupId } from '../../skill';

export abstract class MapSideWinrate extends FocusAreaSkill {
	public groupId: SkillGroupId = 'focusAreaSkillGroup';
	public postSign: PostSign = '%';

	dependsOn = null;
	description = '';
	descriptionExtra = '';
	detailsInfo = false;
	displayInGoodBadSummary = false;
	groupIcon = '';
	groupTitle = 'Focus Area group';
	icon = '';
	improveTip = true;
	inversed = false;
	isRatio = true;
	isScaledForMatch = true;
	minValue = 0;
	smallDescription = '';

	focusAreaLowerBound = 10;
	focusAreaUpperBound = 60;
}
