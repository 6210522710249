import { Benchmark } from '../../benchmarks-repository';
import { PostSign, Skill, SkillGroupId, SkillId } from '../skill';

export class FlashbangHitFoeAvgDuration extends Skill {
	public readonly benchmarkKeyAvg: keyof Benchmark = 'flashbangHitFoeDurationAvg';
	public readonly benchmarkKeyStd: keyof Benchmark = 'flashbangHitFoeDurationStd';
	public readonly dependsOn = 'flashbangThrown';
	public readonly descriptionExtra = 'For each flashbang, we take the longest blinded enemy. We then average over all flashbangs that caused an enemy to be blind.';
	public readonly description = 'This stat displays how long, on average, enemies stay blind whilst affected by your flashbang. This is an indication of how efficient you are at blinding a target with a flashbang. (Note: Only includes the longest blind duration caused by any single flashbang).';
	public readonly detailsInfo = false;
	public readonly displayInGoodBadSummary = true;
	public readonly fallbackValue = 'n/a';
	public readonly groupIcon = 'mdi mdi-progress-wrench';
	public readonly groupId: SkillGroupId = 'utilitySkillGroup';
	public readonly groupTitle = 'Utility group';
	public readonly icon = 'mdi mdi-progress-clock';
	public readonly id = SkillId.FLASHBANG_HIT_FOE_AVG_DURATION;
	public readonly improveTip = false;
	public readonly inversed = false;
	public readonly isRatio = false;
	public readonly minValue = 0;
	public readonly postSign: PostSign = 'sec';
	public readonly shortTitle = 'Avg blind time';
	public readonly smallDescription = "How long enemies stay blind on average, when they get hit with a flashbang that you've thrown.";
	public readonly title = 'Flash Blind Duration per Flashed Enemy';
}
