import { Benchmark } from '../../../benchmarks-repository';
import { Skill, SkillGroupId } from '../../skill';

export abstract class PositioningRating extends Skill {
	public readonly benchmarkKeyAvg: keyof Benchmark = 'positioningRatingAvg';
	public readonly benchmarkKeyStd: keyof Benchmark = 'positioningRatingStd';
	public groupId: SkillGroupId = 'teamplaySideGroup';

	dependsOn = null;
	description = '';
	detailsInfo = false;
	displayInGoodBadSummary = false;
	improveTip = false;
	inversed = false;
	isRatio = false;
	isScaledForMatch = true;
	minValue = 0;
	smallDescription = '';
	title = 'Positioning';
	fallbackValue = 'n/a';
}
