// everything related to this date rage picker is beyond repair -- if you touch it, you'll regret it
/* eslint-disable */
import { InjectionToken } from '@angular/core';
import _moment from 'moment';
const moment = _moment;

export const LOCALE_CONFIG = new InjectionToken<LocaleConfig>('daterangepicker.config');
/**
 *  LocaleConfig Interface
 */
export interface LocaleConfig {
	direction?: string;
	separator?: string;
	weekLabel?: string;
	applyLabel?: string;
	cancelLabel?: string;
	clearLabel?: string;
	customRangeLabel?: string;
	daysOfWeek?: string[];
	monthNames?: string[];
	firstDay?: number;
	format?: string;
	displayFormat?: string;
}
/**
 *  DefaultLocaleConfig
 */
export const DefaultLocaleConfig: LocaleConfig = {
	direction: 'ltr',
	separator: ' - ',
	weekLabel: 'W',
	applyLabel: 'Apply',
	cancelLabel: 'Cancel',
	clearLabel: 'Clear',
	customRangeLabel: 'Custom range',
	daysOfWeek: moment.weekdaysMin(),
	monthNames: moment.monthsShort(),
	firstDay: moment.localeData().firstDayOfWeek(),
};
