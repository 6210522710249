import { Component } from '@angular/core';

@Component({
	selector: 'app-attempting-login',
	templateUrl: './attempting-login.component.html',
	styleUrls: ['./attempting-login.component.scss'],
})
export class AttemptingLoginComponent {
	//
}
