import { OnChanges, OnDestroy, OnInit, Component } from '@angular/core';
import { Subject } from 'rxjs';
import { takeUntil } from 'rxjs/operators';
import { ReferrerDomainBlacklistService } from 'src/app/services/referrer-domain-blacklist.service';

@Component({
	selector: 'app-referrer-domain-blacklist-banner',
	templateUrl: './referrer-domain-blacklist-banner.component.html',
	styleUrls: ['./referrer-domain-blacklist-banner.component.scss'],
})
export class ReferrerDomainBlacklistBannerComponent implements OnDestroy, OnInit {
	protected readonly ngUnsubscribe = new Subject<void>();
	public showBanner = false;
	public domain;

	public constructor(
		private referrerDomainBlacklistService: ReferrerDomainBlacklistService,
	) {
	}

	public ngOnInit(): void {
		// this.referrerDomainBlacklistService.blacklist$.pipe(takeUntil(this.ngUnsubscribe)).subscribe(
		// 	(domain) => {
		// 		this.domain = domain;
		// 		this.showBanner = true;
		// 	},
		// );

		// this.referrerDomainBlacklistService.checkDomain();
	}

	public ngOnDestroy(): void {
		this.ngUnsubscribe.next();
		this.ngUnsubscribe.complete();
	}
}
