import { animate, group, query, style, transition, trigger } from '@angular/animations';
import { Component, ElementRef, EventEmitter, HostListener, Input, OnDestroy, OnInit, Output, ViewChild } from '@angular/core';
import { Subject } from 'rxjs';
import { takeUntil } from 'rxjs/operators';
import { ModalsService } from 'src/app/services/modals.service';

@Component({
	selector: 'app-modal',
	templateUrl: './modal.component.html',
	styleUrls: ['./modal.component.scss'],

	animations: [
		trigger('inOutAnimation', [
			transition(':enter', [
				group([
					query(':self', [
						style({ opacity: 0 }),
						animate('0.2s ease', style({ opacity: 1 })),
					]),

					query('.animation-container', [
						style({ transform: 'scale(0.75)' }),
						animate('0.2s ease', style({ transform: 'none' })),
					]),
				]),
			]),

			transition(':leave', [
				group([
					query(':self', [
						style({ opacity: 1 }),
						animate('0.2s ease', style({ opacity: 0 })),
					]),

					query('.animation-container', [
						style({ transform: 'none' }),
						animate('0.2s ease', style({ transform: 'scale(0.75)' })),
					]),
				]),
			]),
		]),
	],
})
export class ModalComponent implements OnDestroy, OnInit {
	@Input()
	public active: boolean;

	@Input()
	public useContainer = true;

	@Output()
	public hide = new EventEmitter();

	@ViewChild('backdrop')
	public backdrop: ElementRef<HTMLDivElement>;

	protected readonly ngUnsubscribe = new Subject<void>();

	public constructor(
		protected readonly modalsService: ModalsService,
	) {
		//
	}

	public onBackdropClick(e: MouseEvent): void {
		if (e.target === this.backdrop.nativeElement) this.hide.emit();
	}

	public onCloseButtonClick(): void {
		this.hide.emit();
	}

	protected elementIsInput(element: HTMLElement): boolean {
		return element.tagName === 'INPUT'
			|| element.tagName === 'TEXTAREA'
			|| element.isContentEditable;
	}

	@HostListener('document:keyup', ['$event'])
	protected handleKeyup(e: KeyboardEvent) {
		if (!this.active || e.key !== 'Escape' || (e.target instanceof HTMLElement && this.elementIsInput(e.target))) return;

		e.preventDefault();
		this.hide.emit();
	}

	public ngOnInit(): void {
		this.modalsService.closeAllModals$.pipe(takeUntil(this.ngUnsubscribe)).subscribe(() => this.hide.emit());
	}

	public ngOnDestroy(): void {
		this.ngUnsubscribe.next();
		this.ngUnsubscribe.complete();
	}
}
