import { Benchmark } from '../../benchmarks-repository';
import { Skill, SkillGroupId, SkillId } from '../skill';
import { TeamName } from '../../enums';

export class CtOpeningDuelsRating extends Skill {
	public readonly benchmarkKeyAvg: keyof Benchmark = 'ctOpeningDuelsRatingAvg';
	public readonly benchmarkKeyStd: keyof Benchmark = 'ctOpeningDuelsRatingStd';
	public groupId: SkillGroupId = 'teamplayDuelsGroup';

	dependsOn = null;
	description = '';
	detailsInfo = false;
	displayInGoodBadSummary = false;
	groupTitle = 'Teamplay CT Side';
	id = SkillId.CT_OPENING_DUELS_RATING;
	improveTip = false;
	inversed = false;
	isRatio = true;
	minValue = 0;
	side = TeamName.COUNTER_TERRORIST;
	smallDescription = '';
	title = 'Opening Duels Rating';
}
