import { Benchmark } from '../../../benchmarks-repository';
import { SkillId } from '../../skill';
import { SprayAccuracyPerWeapon } from './spray-accuracy-per-weapon';
import { Weapon } from '../../../enums';

export class SprayAccuracyM4A1S extends SprayAccuracyPerWeapon {
	public readonly benchmarkKeyAvg: keyof Benchmark = 'sprayAccuracyM4A1SAvg';
	public readonly benchmarkKeyStd: keyof Benchmark = 'sprayAccuracyM4A1SStd';

	id = SkillId.SPRAY_ACCURACY_M4A1S;
	title = 'Spray Accuracy (M4A1-S)';
	weapon = Weapon.M4A1S;

	focusAreaLowerBound = 13;
	focusAreaUpperBound = 60;
}
