import { Benchmark } from '../../../benchmarks-repository';
import { PostSign, Skill, SkillGroupId, SkillId } from '../../skill';

export class AdvProportionRate extends Skill {
	public readonly benchmarkKeyAvg: keyof Benchmark = 'advProportionRateAvg';
	public readonly benchmarkKeyStd: keyof Benchmark = 'advProportionRateStd';
	public groupId: SkillGroupId = 'teamplaySituationGroup';
	public postSign: PostSign = '%';

	dependsOn = null;
	description = 'Number of rounds where your team has a 5v4 advantage from the opening duel divided by all rounds played.';
	detailsInfo = false;
	displayInGoodBadSummary = false;
	groupTitle = '5v4';
	id = SkillId.ADV_PROPORTION_RATE;
	improveTip = false;
	inversed = false;
	isRatio = true;
	minValue = 0;
	smallDescription = 'Percentage of rounds where your team got the opening kill to make it a 5v4.';
	title = '% of Rounds';
}
