import { Benchmark } from '../../../benchmarks-repository';
import { SkillId } from '../../skill';
import { TBombsiteAttemptRate } from './t-bombsite-attempt-rate';

export class TBombsiteTakeRate extends TBombsiteAttemptRate {
	public readonly benchmarkKeyAvg: keyof Benchmark = 'tBombsiteTakeAvg';
	public readonly benchmarkKeyStd: keyof Benchmark = 'tBombsiteTakeStd';

	description = 'The amount of times you successfully planted the bomb, divided by all times you tried to take a bombsite.';
	id = SkillId.T_BOMBSITE_TAKE_RATE;
	smallDescription = 'The amount of times you successfully planted the bomb, divided by all times you tried to take a bombsite.';
	title = 'Bombsite Takes';
}
