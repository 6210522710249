import { Benchmark } from '../../../benchmarks-repository';
import { SkillId } from '../../skill';
import { SprayAccuracyPerWeapon } from './spray-accuracy-per-weapon';
import { Weapon } from '../../../enums';

export class SprayAccuracySg553 extends SprayAccuracyPerWeapon {
	public readonly benchmarkKeyAvg: keyof Benchmark = 'sprayAccuracySg553Avg';
	public readonly benchmarkKeyStd: keyof Benchmark = 'sprayAccuracySg553Std';

	id = SkillId.SPRAY_ACCURACY_SG553;
	title = 'Spray Accuracy (SG 553)';
	weapon = Weapon.SG553;

	focusAreaLowerBound = 12;
	focusAreaUpperBound = 63;
}
