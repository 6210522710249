import { Benchmark } from '../../benchmarks-repository';
import { FocusAreaSkill } from '../focus-area-skill';
import { PostSign, SkillGroupId, SkillId } from '../skill';
import { TeamName } from '../../enums';

export class CtOpeningRating extends FocusAreaSkill {
	public readonly benchmarkKeyAvg: keyof Benchmark = 'openingCtAvg';
	public readonly benchmarkKeyStd: keyof Benchmark = 'openingCtStd';
	public groupId: SkillGroupId = 'positioningSkillGroup';
	public postSign?: PostSign = '+';

	dependsOn = null;
	description = '';
	descriptionExtra = '';
	detailsInfo = false;
	displayInGoodBadSummary = false;
	groupIcon = 'mdi mdi-progress-wrench';
	groupTitle = 'Positioning group';
	icon = 'mdi mdi-weather-cloudy';
	id = SkillId.CT_OPENING_RATING;
	improveTip = false;
	inversed = false;
	isRatio = false;
	side = TeamName.COUNTER_TERRORIST;
	smallDescription = '';
	title = 'CT Opening Leetify Rating';

	focusAreaLowerBound = -21;
	focusAreaUpperBound = 18;
}
