export const leetifyRatingRoundAvg = -0.0000000001;
export const leetifyRatingRoundStd = 0.21238020503440413;

export const leetifyRatingRoundsPlayedAvg = 19.89416305256038;
export const leetifyRatingRoundsPlayedStd = 4.892268106562846;
export const leetifyRatingRoundsPlayedTotal = 1000000;

export const leetifyRatingMatchAvg = leetifyRatingRoundAvg;
export const leetifyRatingMatchStd = leetifyRatingRoundStd / Math.sqrt(leetifyRatingRoundsPlayedAvg);

export const leetifyRatingNormalizedAvg = leetifyRatingMatchAvg * 100;
export const leetifyRatingNormalizedStd = leetifyRatingMatchStd * 100;

export const leetifyRatingBenchmark = {
	leetifyRatingNormalizedAvg,
	leetifyRatingNormalizedStd,
	leetifyRatingAvg: leetifyRatingMatchAvg,
	leetifyRatingStd: leetifyRatingMatchStd,
};
