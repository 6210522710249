import { Benchmark } from '../../benchmarks-repository';
import { OpeningDuelTradePercentageBenchmarkT } from './opening-duel-trade-percentage-benchmark-t';
import { SkillId } from '../skill';

export class OpeningDuelTradePercentageBenchmarkCt extends OpeningDuelTradePercentageBenchmarkT {
	public readonly benchmarkKeyAvg: keyof Benchmark = 'ctOpeningDuelTradePercentageAvg';
	public readonly benchmarkKeyStd: keyof Benchmark = 'ctOpeningDuelTradePercentageStd';

	id = SkillId.OPENING_DUEL_TRADE_PERCENTAGE_BENCHMARK_CT;
	keepValuesVerbatim = true;
}
