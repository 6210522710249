import { Benchmark } from '../../benchmarks-repository';
import { PlayerClutchKillsPercentage } from './player-clutch-kills-percentage';
import { SkillId } from '../skill';

export class PlayerClutch1v5KillsPercentage extends PlayerClutchKillsPercentage {
	public readonly benchmarkKeyAvg: keyof Benchmark = 'playerClutch1v5KillsPercentageAvg';
	public readonly benchmarkKeyStd: keyof Benchmark = 'playerClutch1v5KillsPercentageStd';
	id = SkillId.PLAYER_CLUTCH_1V5_KILLS_PERCENTAGE;
	title = '1v5 Clutch Kills';

	focusAreaLowerBound = 6;
	focusAreaUpperBound = 16;
}
