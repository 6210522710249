import { Benchmark } from '../../../benchmarks-repository';
import { SkillId } from '../../skill';
import { SprayAccuracyPerWeapon } from './spray-accuracy-per-weapon';
import { Weapon } from '../../../enums';

export class SprayAccuracyAk47 extends SprayAccuracyPerWeapon {
	public readonly benchmarkKeyAvg: keyof Benchmark = 'sprayAccuracyAk47Avg';
	public readonly benchmarkKeyStd: keyof Benchmark = 'sprayAccuracyAk47Std';

	id = SkillId.SPRAY_ACCURACY_AK47;
	title = 'Spray Accuracy (AK-47)';
	weapon = Weapon.AK47;

	focusAreaLowerBound = 9;
	focusAreaUpperBound = 53;
}
