import { Benchmark } from '../../../../benchmarks-repository';
import { PostSign, Skill, SkillGroupId, SkillId } from '../../../skill';

export class WinRateWithoutPlayer extends Skill {
	public readonly benchmarkKeyAvg: keyof Benchmark = 'winRateWithoutPlayerAvg';
	public readonly benchmarkKeyStd: keyof Benchmark = 'winRateWithoutPlayerStd';
	public groupId: SkillGroupId = 'teamplaySituationPlayerGroup';
	public postSign: PostSign = '%';

	dependsOn = null;
	description = 'Percentage of rounds your team won when you died from the opening duel.';
	detailsInfo = false;
	displayInGoodBadSummary = false;
	groupTitle = '4v5 - without Teammate';
	id = SkillId.WIN_RATE_WITHOUT_PLAYER;
	improveTip = false;
	inversed = true;
	isRatio = true;
	minValue = 0;
	smallDescription = 'Percentage of rounds your team won when you died from the opening duel.';
	title = 'Round Win Rate';
}
