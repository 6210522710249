import { Component, Input, OnChanges } from '@angular/core';
import { ActivatedRoute, Params, QueryParamsHandling } from '@angular/router';
import { Icon } from 'src/constants';

export enum ButtonSize {
	LARGE = 'large',
	MEDIUM = 'medium',
	SMALL = 'small',
}

export enum ButtonType {
	DANGER = 'danger',
	GHOST = 'ghost',
	PRIMARY = 'primary',
	SECONDARY = 'secondary',
	TEXT = 'text',
}

export enum ButtonRoundedCorners {
	ALL = 'all',
	LEFT = 'left',
	RIGHT = 'right',
}

/**
 * Optionally accepts template content (e.g. plain text or HTML), which will be be shown between the left icon and the label.
 *
 * To listen to `click` events, just use the `click` event emitted by the underlying Angular component.
 */
@Component({
	selector: 'leetify-button',
	styleUrls: ['./button.component.scss'],
	templateUrl: './button.component.html',
})
export class ButtonComponent implements OnChanges {
	@Input() public iconLeft: Icon;
	@Input() public iconRight: Icon;
	@Input() public size: ButtonSize = ButtonSize.MEDIUM;
	@Input() public type: ButtonType = ButtonType.PRIMARY;

	/** Ignored if `routerLink` is not also set. */
	@Input() public fragment: string;

	/**
	 * Use `routerLink` instead if possible.
	 * Only use `href` if you really need to set the native href attribute, e.g. for external links, or when the href link should only be opened on a middle-click.
	 *
	 * If set, the component will render an HTML `<a>` element instead of a `<button>`.
	 */
	@Input() public href: string;

	/**
	 * NB! It's currently UNTESTED whether or not clicking a disabled link-type button (i.e. if `href` or `routerLink` is set) emits an event!
	 * Clicking a disabled button-type button DOES NOT emit an event.
	 */
	@Input() public isDisabled: boolean = false;

	/** If `true`, the internal `<button>` will use the attribute `type="submit"`. Ignored if `href` or `routerLink` are also set. */
	@Input() public isSubmit = false;

	/** If `true`, the component will have padding on the left and right side of the content as if icons were visible, even if no icons are set. */
	@Input() public isWide = false;

	/** Will _not_ be hidden if content is passed into the component. */
	@Input() public label: string;

	/** Ignored if `routerLink` is not also set. */
	@Input() public queryParams: Params;

	/** Ignored if `routerLink` is not also set. */
	@Input() public queryParamsHandling: QueryParamsHandling;

	/** Ignored if `routerLink` is not also set. */
	@Input() public relativeTo: ActivatedRoute;

	/** Ignored if `href` is not also set. */
	@Input() public rel: 'noopener' | 'noreferrer' | string;

	/** Which corners to apply rounding to. E.g. when using together with a text input. */
	@Input() public roundedCorners: ButtonRoundedCorners = ButtonRoundedCorners.ALL;

	/** If set, the component will render an HTML `<a>` element instead of a `<button>`. */
	@Input() public routerLink: string | string[];

	/** Ignored if neither `href` or `routerLink` are also set. */
	@Input() public target: '_blank' | '_parent' | '_self' | '_top' | '_unfencedTop' | string;

	protected readonly Icon = Icon;

	protected classNames: string;

	public ngOnChanges(): void {
		this.classNames = `--size-${this.size} --type-${this.type} --rounded-corners-${this.roundedCorners}`;
	}
}
