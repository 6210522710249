import { Inject, Injectable, PLATFORM_ID } from '@angular/core';
import { isPlatformBrowser } from '@angular/common';
import { HttpClient } from '@angular/common/http';
import { Subject } from 'rxjs';
import { omit } from 'lodash-es';
import { OnboardingStatus, ProgressReportQuarter, PrivacySettingsMode } from 'leetify-shared-utils/enums';
import { EsportalUserDTO } from 'leetify-shared-utils/dto';
import { AuthService } from 'src/app/auth/auth.service';
import { environment } from 'src/environments/environment';
import { ToastService } from 'src/app/toast/toast.service';
import { User } from 'src/app/models/user.model';
import { ViewHelper } from 'src/app/helpers/view.helper';
import { WebsocketService } from 'src/app/services/websocket.service';

export {
	User,
};

@Injectable({
	providedIn: 'root',
})
export class UserService {
	public user$ = new Subject<User>();
	public user: User;

	private readonly faceitUserExistsSource = new Subject<boolean>();
	public readonly faceitUserExists$ = this.faceitUserExistsSource.asObservable();

	public esportalUser$ = new Subject<EsportalUserDTO>();
	public esportalUser: EsportalUserDTO;

	public existingEsportalUser$ = new Subject<any>();
	public existingEsportalUser = this.existingEsportalUser$.asObservable();

	public gamersClubUserConnected$ = new Subject<boolean>();
	public gamersClubUserConnected: boolean;

	public matchmakingUserConnected$ = new Subject<{ steamLastShareCode: string, steamAuthCode: string }>();

	private compareFriendSource = new Subject<User>();
	public compareFriend$ = this.compareFriendSource.asObservable();

	private readonly headerImageCacheBusting: { [userId: string]: number } = {};
	private readonly isBrowser: boolean;

	public constructor(
		@Inject(PLATFORM_ID) platformId: Record<string, any>,
		private http: HttpClient,
		private toastService: ToastService,
		private websocketService: WebsocketService,
	) {
		this.isBrowser = isPlatformBrowser(platformId);

		this.user$.subscribe((user) => (this.user = user));
		this.esportalUser$.subscribe((esportalUser) => (this.esportalUser = esportalUser));
		this.gamersClubUserConnected$.subscribe((gamersClubUserConnected) => (this.gamersClubUserConnected = gamersClubUserConnected));

		// TODO (maybe?) move this to the individual components
		this.websocketService.newGameAvailable$.subscribe(() => {
			// update the benchmark for users who haven't set it manually
			if (!this.user?.uiSettings || !this.user?.uiSettings?.skillLevelSelectedManually) this.reloadUser();
		});

		this.websocketService.freeProReceived$.subscribe(() => {
			if (this.user) this.reloadUser();
		});
	}

	public async myselfThrowingErrors(): Promise<User> {
		if (!this.isBrowser) {
			this.user$.next(null);
			return null;
		}

		try {
			const user = await this.http.get<User>('/api/myself').toPromise();

			this.user$.next(user);
			this.reloadEsportalUser();
			if (user.steam64Id) this.websocketService.connect();

			return user;
		} catch (err) {
			if (err?.status === 403) AuthService.removeToken();
			throw err;
		}
	}

	public async myself(): Promise<User> {
		return this.myselfThrowingErrors().catch(() => null);
	}

	public reloadUser(): void {
		if (!this.isBrowser) return this.user$.next(null);

		this.http.get('/api/myself').subscribe(
			(user: User) => this.user$.next(user),
			(e) => {
				if (e.status === 403) AuthService.removeToken();
				return null;
			},
		);
	}

	// uses a simplified endpoint that doesn't return any data except the User model itself and doesn't do any background work, and also doesn't sign out on errors
	public async reloadUserSimple(): Promise<User> {
		if (!this.isBrowser) {
			this.user$.next(null);
			return Promise.resolve(null);
		}

		try {
			const user = await this.http.get<User>('/api/am-in').toPromise();
			this.user$.next(user);
			return user;
		} catch (err) {
			console.error(err);
			return null;
		}
	}

	public reloadEsportalUser(): void {
		this.esportalUser$.next(null);
	}

	public async checkFaceit(): Promise<boolean> {
		const response = await this.http.get<{ exists: boolean }>('/api/faceit/onboarding-check').toPromise().catch(() => null);

		const exists = response?.exists || false;
		this.faceitUserExistsSource.next(exists);

		return exists;
	}

	public async checkEsportal(): Promise<boolean> {
		const response = await this.http.get('/api/esportal/onboarding-check').toPromise().catch(() => null);

		if (!response?.esportalUser) {
			this.existingEsportalUser$.next(undefined);
			return false;
		}

		this.existingEsportalUser$.next(response.esportalUser);
		return true;
	}

	public async checkGamersClub(): Promise<boolean> {
		try {
			const response: any = await this.http.get('/api/gamersclub/onboarding-check').toPromise();
			this.gamersClubUserConnected$.next(response?.enabled);
			return response?.enabled;
		} catch {
			return null;
		}
	}

	public async connectFaceit(): Promise<boolean> {
		const response = await this.http.get<{ exists: boolean }>('/api/faceit/connect').toPromise().catch(() => null);

		if (!response?.exists) {
			this.toastService.error('Could not connect Faceit :(');
			return false;
		}

		this.reloadUser();
		this.websocketService.authenticate();
		this.websocketService.syncGames();

		return true;
	}

	public async connectEsportal(): Promise<boolean> {
		const response: any = await this.http.get('/api/esportal/connect').toPromise();

		this.websocketService.authenticate();
		this.websocketService.syncGames();

		if (response.result === 'ok') {
			this.reloadEsportalUser();
			return true;
		}

		this.toastService.error('Could not find an Esportal account associated with your Steam ID.');
		this.reloadEsportalUser();

		return false;
	}

	public async connectGamersClub(): Promise<boolean> {
		try {
			await this.http.get('/api/gamersclub/connect').toPromise();

			this.gamersClubUserConnected$.next(true);
			return true;
		} catch (err) {
			if (err.status === 500) {
				this.toastService.error('An error occurred on Leetify\'s side. Please send this issue to Support!');
			} else if (err.status === 404) {
				this.toastService.error('Could not find a Gamers Club account associated with your Steam ID. Please check to make sure they are the same.');
			}
			return false;
		}
	}

	public async disconnectSteam(): Promise<boolean> {
		try {
			const response: any = await this.http.post('/api/steam/disconnect', {}).toPromise();

			this.websocketService.authenticate();
			this.websocketService.syncGames();

			if (response.result !== 'ok') return false;

			this.reloadUser();
			return true;
		} catch {
			return false;
		}
	}

	public async disconnectMatchmaking(): Promise<boolean> {
		try {
			const response: any = await this.http.post('/api/matchmaking/disconnect', {}).toPromise();

			this.websocketService.authenticate();
			this.websocketService.syncGames();

			if (response.result !== 'ok') return false;

			this.reloadUser();
			return true;
		} catch {
			return false;
		}
	}

	public async disconnectDiscord(): Promise<boolean> {
		try {
			const response: any = await this.http.post('/api/discord/disconnect', {}).toPromise();
			if (response.result !== 'ok') return false;

			this.reloadUser();
			return true;
		} catch {
			return false;
		}
	}

	public async disconnectFaceit(): Promise<boolean> {
		try {
			const response: any = await this.http.post('/api/faceit/disconnect', {}).toPromise();

			this.websocketService.authenticate();
			this.websocketService.syncGames();

			if (response.result !== 'ok') return false;

			this.reloadUser();
			return true;
		} catch {
			return false;
		}
	}

	public async disconnectEsportal(): Promise<boolean> {
		try {
			const response: any = await this.http.post('/api/esportal/disconnect', {}).toPromise();

			this.websocketService.authenticate();
			this.websocketService.syncGames();

			if (response.result !== 'ok') return false;

			this.esportalUser$.next(null);
			return true;
		} catch {
			return false;
		}
	}

	public async disconnectGamersClub(): Promise<boolean> {
		try {
			await this.http.post('/api/gamersclub/disconnect', {}).toPromise();

			this.websocketService.authenticate();
			this.websocketService.syncGames();
			this.gamersClubUserConnected$.next(false);
			return true;
		} catch {
			return false;
		}
	}

	public updateUser(user: User): void {
		this.http.post('/api/user/update-settings', user, { responseType: 'text' }).subscribe(() => null);
		this.user$.next(user);
	}

	public updatePrivacySettings(privacySettingsMode: PrivacySettingsMode): void {
		this.http.post('/api/user/update-privacy-settings', { privacySettingsMode }).subscribe(
			() => {
				this.toastService.success('Privacy settings successfully updated!');
				this.reloadUser();
			},
			() => this.toastService.error('Failed to update privacy settings.'),
		);
	}

	public async changeEmailAsPartOfOnboarding(email: string): Promise<string | void> {
		try {
			await this.http.post<unknown>('/api/user/change-email-as-part-of-onboarding', { email }).toPromise();
		} catch (err) {
			console.log(err);
			return err?.error?.error || 'Something went wrong :( Please try again.';
		}
	}

	public updateOnboarding(onboardingStatus: OnboardingStatus): Promise<unknown> {
		return this.http.post('/api/user/update-onboarding', { onboardingStatus }, { responseType: 'text' }).toPromise();
	}

	public getHeaderImageUrl(userId: string): string {
		if (!userId) return ViewHelper.PLAYER_HEADER_FALLBACK;

		const cacheBustingSuffix = this.headerImageCacheBusting[userId] ? `?${this.headerImageCacheBusting[userId]}` : '';
		// return `${environment.userUploadsPublicUrl}/header-images/${userId}.webp${cacheBustingSuffix}`; // TODO use this when we've got static URLs for header images
		return `${environment.apiUrl}/api/user/header-image/${userId}${cacheBustingSuffix}`;
	}

	public cacheBustHeaderImage(userId: string): string {
		if (userId) this.headerImageCacheBusting[userId] = +new Date();
		return this.getHeaderImageUrl(userId);
	}

	public uploadHeaderImage(file: File): Promise<void> {
		const formData = new FormData();
		formData.append('file', file);

		return this.http.post<void>('/api/user/header-image', formData).toPromise();
	}

	public deleteHeaderImage(): Promise<void> {
		return this.http.delete<void>('/api/user/header-image').toPromise();
	}

	public acknowledgeProgressReport(steam64Id: string, year: number, quarter: ProgressReportQuarter): void {
		this.http.post(`/api/progress-report/${year}/${quarter}/${steam64Id}/acknowledge`, {}).subscribe(() => null);

		if (
			this.user.steam64Id === steam64Id
			&& this.user.progressReport?.year === year
			&& this.user.progressReport?.quarter === quarter
		) {
			this.user$.next(
				omit(this.user, 'progressReport'),
			);
		}
	}
}
