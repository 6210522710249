import { Component, ContentChild, Input } from '@angular/core';
import { Icon } from 'src/constants';
import { CardBodyInnerDirective } from './card-body-inner.directive';
import { CardBodyOuterDirective } from './card-body-outer.directive';
import { CardHeaderInnerDirective } from './card-header-inner.directive';
import { CardHeaderOuterDirective } from './card-header-outer.directive';
import { CardHeaderTextDirective } from './card-header-text.directive';

/**
 * Optionally accepts template content:
 * * Pass in a template directly to use it as the card body.
 * * Pass an `<ng-template leetifyCardHeaderOuter>` to replace the card header's outer HTML.
 * * Pass an `<ng-template leetifyCardHeaderInner>` to replace the card header's inner HTML (preserves the header background but replaces the icon).
 * * Pass an `<ng-template leetifyCardHeaderText>` to replace the card header's text HTML (preserves the header background and icon).
 * * Pass an `<ng-template leetifyCardBodyOuter>` to replace the card body's outer HTML (discards the card body's padding).
 * * Pass an `<ng-template leetifyCardBodyInner>` to replace the card body's inner HTML (identical to passing in content directly).
 */
@Component({
	selector: 'leetify-card',
	styleUrls: ['./card.component.scss'],
	templateUrl: './card.component.html',
})
export class CardComponent {
	@Input() public bodyText: string;
	@Input() public headerText: string;

	/** Ignored unless `headerText` is also set or the `leetifyCardHeaderText` template is provided. To show only the icon but no header text, set `headerText` to `&nbsp;`. */
	@Input() public headerIcon: Icon;

	/** If `true`, the card header will be a semantic `<header>` element. Do this if the card is wrapped in a semantic `<section>` or similar element. */
	@Input() public isHeaderSemanticElement = false;

	/** If `true`, the header element will apply a reasonable amount of padding for normal uses. Set to `false` if custom padding is desired, e.g. when using `<leetify-common-tabs>`. */
	@Input() public showHeaderPadding = true;

	@ContentChild(CardBodyInnerDirective) protected readonly bodyInnerTemplate: CardBodyInnerDirective;
	@ContentChild(CardBodyOuterDirective) protected readonly bodyOuterTemplate: CardBodyOuterDirective;
	@ContentChild(CardHeaderInnerDirective) protected readonly headerInnerTemplate: CardHeaderInnerDirective;
	@ContentChild(CardHeaderOuterDirective) protected readonly headerOuterTemplate: CardHeaderOuterDirective;
	@ContentChild(CardHeaderTextDirective) protected readonly headerTextTemplate: CardHeaderTextDirective;
}
