import { Directive, TemplateRef } from '@angular/core';

@Directive({
	selector: '[leetifyCardHeaderInner]',
})
export class CardHeaderInnerDirective {
	public constructor(
		public readonly templateRef: TemplateRef<unknown>,
	) {
		//
	}
}
