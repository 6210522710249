import { Benchmark } from '../../benchmarks-repository';
import { Skill, SkillId } from '../skill';

export class KillDifference extends Skill {
	public readonly benchmarkKeyAvg: keyof Benchmark = 'killDiffAvg';
	public readonly benchmarkKeyStd: keyof Benchmark = 'killDiffStd';
	public readonly postSign = '+';

	decimalPlaces = 0;
	dependsOn = null;
	description = '';
	detailsInfo = false;
	displayInGoodBadSummary = false;
	groupId = null;
	groupTitle = 'General';
	id = SkillId.KILL_DIFFERENCE;
	improveTip = false;
	inversed = false;
	isRatio = false;
	isScaledForMatch = true;
	shortTitle = 'K-D';
	smallDescription = '';
	title = 'Kill Difference';
}
