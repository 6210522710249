import { Skill, SkillId } from '../skill';

export class HeadshotKillPercentage extends Skill {
	public readonly benchmarkKeyAvg = undefined;
	public readonly benchmarkKeyStd = undefined;
	public readonly dependsOn = null;
	public readonly description = '';
	public readonly detailsInfo = false;
	public readonly displayInGoodBadSummary = false;
	public readonly groupId = null;
	public readonly groupTitle = 'Aim group';
	public readonly id = SkillId.HEADSHOT_KILL_PERCENTAGE;
	public readonly improveTip = false;
	public readonly inversed = false;
	public readonly isRatio = false;
	public readonly postSign = '%';
	public readonly shortTitle = 'HS Kill %';
	public readonly smallDescription = '';
	public readonly title = 'Headshot Kill Percentage';
}
