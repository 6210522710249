import { Component, OnDestroy, OnInit } from '@angular/core';
import { Subject } from 'rxjs';
import { takeUntil } from 'rxjs/operators';
import { ProgressReportQuarter } from 'leetify-shared-utils/enums';
import { UserService } from 'src/app/services/user.service';
import { Icon } from 'src/constants';
import { User } from 'src/app/models/user.model';

@Component({
	selector: 'app-progress-report-banner',
	templateUrl: './progress-report-banner.component.html',
	styleUrls: ['./progress-report-banner.component.scss'],
})
export class ProgressReportBannerWidget implements OnDestroy, OnInit {
	protected readonly Icon = Icon;
	protected readonly ngUnsubscribe = new Subject<void>();
	protected readonly ProgressReportQuarter = ProgressReportQuarter;

	protected quarter: ProgressReportQuarter;
	protected user: User;
	protected year: number;

	public constructor(
		protected readonly userService: UserService,
	) {
		//
	}

	protected handleUser(user: User): void {
		this.user = user;
		this.year = user?.progressReport?.year;
		this.quarter = user?.progressReport?.quarter;
	}

	protected dismiss(): void {
		this.userService.acknowledgeProgressReport(this.user.steam64Id, this.year, this.quarter);
	}

	public ngOnInit(): void {
		this.userService.user$.pipe(takeUntil(this.ngUnsubscribe)).subscribe((user) => this.handleUser(user));
		this.handleUser(this.userService.user);
	}

	public ngOnDestroy(): void {
		this.ngUnsubscribe.next();
		this.ngUnsubscribe.complete();
	}
}
