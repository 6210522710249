import { Benchmark, BombsiteAttemptBenchmark, DeterministicBenchmark, FakeBenchmark, LeetifyOpeningClutchingRatingBenchmark, MapWinRateBenchmark, PlayerStatsBenchmark, SprayAccuracyPerWeaponBenchmark, TeamplayDuelsBenchmark, TeamplaySideBenchmark, TeamplaySideBombsiteAttemptBenchmark, TeamplaySideMapWinRateBenchmark, UtilityGrenadesThrownBenchmark } from './benchmark-interfaces';
import { CalculatorHelper } from './calculator.helper';
import { DataSource, MapName, MatchmakingRankType } from './enums';
import { MapConfigurationInterface } from './interfaces';
import { Skill, Skills } from './skills-repository';
import { leetifyRatingBenchmark } from './benchmarks/leetify-rating';

export {
	BasePlayerStatsBenchmark,
	Benchmark,
	BenchmarkMeta,
	ClutchesStatsBenchmark,
	LeetifyOpeningClutchingRatingBenchmark,
	OpeningDuelStatsBenchmark,
	PlayerStatsBenchmark,
	SprayAccuracyPerWeaponBenchmark,
	TeamplaySituationBenchmark,
	TradeStatsBenchmark,
	UtilityGrenadesThrownBenchmark,
} from './benchmark-interfaces';

export interface BenchmarkMetadata {
	dataSource: DataSource;
	displayMin?: number;
	label: string;
	rankType: MatchmakingRankType | null;
	skillLevelMax: number;
	skillLevelMin: number;
}

const logLabel = '[SharedUtils][BenchmarksRepository]';

export const benchmarkMapNames = [
	MapName.DE_ANCIENT,
	MapName.DE_ANUBIS,
	MapName.DE_INFERNO,
	MapName.DE_MIRAGE,
	MapName.DE_NUKE,
	MapName.DE_OVERPASS,
	MapName.DE_VERTIGO,
];

let unifiedBenchmarkPromise: Promise<Benchmark>;

export class BenchmarksRepository {
	public static readonly fallbackBenchmarkDataSource = DataSource.MATCHMAKING;
	public static readonly fallbackBenchmarkRankType = MatchmakingRankType.CS2_COMPETITIVE;
	public static readonly fallbackBenchmarkSkillLevel = 10000;

	public static readonly hltvRatingBoundaries = {
		low: 0.97,
		average: 1.13,
	};

	public static readonly fakeBenchmark: FakeBenchmark = {
		adrAvg: 77.63,
		adrStd: 24,
		aimRatingAvg: 50,
		aimRatingStd: 10,
		hltvRatingAvg: BenchmarksRepository.hltvRatingBoundaries.average - (BenchmarksRepository.hltvRatingBoundaries.average - BenchmarksRepository.hltvRatingBoundaries.low) / 2,
		hltvRatingStd: BenchmarksRepository.hltvRatingBoundaries.average - BenchmarksRepository.hltvRatingBoundaries.low,
		kdRatioAvg: 1,
		kdRatioStd: 0.05,
		killDiffAvg: 0,
		killDiffStd: 0,
		matchWinRateAvg: 50,
		matchWinRateStd: 5,
		positioningRatingAvg: 50,
		positioningRatingStd: 10,
		utilityRatingAvg: 50,
		utilityRatingStd: 10,
		...leetifyRatingBenchmark,
	};

	public static readonly benchmarkMetadatas: BenchmarkMetadata[] = [
		{ dataSource: DataSource.FACEIT, label: 'Faceit Level 1', rankType: null, skillLevelMin: 1, skillLevelMax: 1 },
		{ dataSource: DataSource.FACEIT, label: 'Faceit Level 2', rankType: null, skillLevelMin: 2, skillLevelMax: 2 },
		{ dataSource: DataSource.FACEIT, label: 'Faceit Level 3', rankType: null, skillLevelMin: 3, skillLevelMax: 3 },
		{ dataSource: DataSource.FACEIT, label: 'Faceit Level 4', rankType: null, skillLevelMin: 4, skillLevelMax: 4 },
		{ dataSource: DataSource.FACEIT, label: 'Faceit Level 5', rankType: null, skillLevelMin: 5, skillLevelMax: 5 },
		{ dataSource: DataSource.FACEIT, label: 'Faceit Level 6', rankType: null, skillLevelMin: 6, skillLevelMax: 6 },
		{ dataSource: DataSource.FACEIT, label: 'Faceit Level 7', rankType: null, skillLevelMin: 7, skillLevelMax: 7 },
		{ dataSource: DataSource.FACEIT, label: 'Faceit Level 8', rankType: null, skillLevelMin: 8, skillLevelMax: 8 },
		{ dataSource: DataSource.FACEIT, label: 'Faceit Level 9', rankType: null, skillLevelMin: 9, skillLevelMax: 9 },
		{ dataSource: DataSource.FACEIT, label: 'Faceit Level 10', rankType: null, skillLevelMin: 10, skillLevelMax: 10 },

		{ dataSource: DataSource.MATCHMAKING, displayMin: 1000, label: '1,000 - 4,999', rankType: MatchmakingRankType.CS2_COMPETITIVE, skillLevelMin: 1, skillLevelMax: 4999 },
		{ dataSource: DataSource.MATCHMAKING, label: '5,000 - 9,999', rankType: MatchmakingRankType.CS2_COMPETITIVE, skillLevelMin: 5000, skillLevelMax: 9999 },
		{ dataSource: DataSource.MATCHMAKING, label: '10,000 - 14,999', rankType: MatchmakingRankType.CS2_COMPETITIVE, skillLevelMin: 10000, skillLevelMax: 14999 },
		{ dataSource: DataSource.MATCHMAKING, label: '15,000 - 19,999', rankType: MatchmakingRankType.CS2_COMPETITIVE, skillLevelMin: 15000, skillLevelMax: 19999 },
		{ dataSource: DataSource.MATCHMAKING, label: '20,000+', rankType: MatchmakingRankType.CS2_COMPETITIVE, skillLevelMin: 20000, skillLevelMax: 1000000 },
	];

	protected static readonly bombsiteAttemptStd = 20;
	protected static readonly roundWinRateStd = 20;

	public static get unifiedBenchmark(): Promise<Benchmark> {
		if (!unifiedBenchmarkPromise) {
			unifiedBenchmarkPromise = BenchmarksRepository.getFullBenchmarkFromMetadata({
				dataSource: DataSource.MATCHMAKING,
				label: null,
				rankType: MatchmakingRankType.CS2_COMPETITIVE,
				skillLevelMax: null,
				skillLevelMin: null,
			});
		}

		return unifiedBenchmarkPromise;
	}

	// helper to set the rank type for benchmark-related functions; should be replaced if/when we have benchmarks for per-map Competitive
	public static benchmarkRankType(dataSource: DataSource): MatchmakingRankType {
		switch (dataSource) {
			case DataSource.MATCHMAKING: return MatchmakingRankType.CS2_COMPETITIVE;
			case DataSource.MATCHMAKING_WINGMAN: return MatchmakingRankType.WINGMAN;
			default: return null;
		}
	}

	public static loadBenchmarkModule(type: 'bombsite-attempts', meta: BenchmarkMetadata, mapName: MapName | null): Promise<BombsiteAttemptBenchmark>;
	public static loadBenchmarkModule(type: 'leetify-opening-clutching-ratings', meta: BenchmarkMetadata, mapName: MapName | null): Promise<LeetifyOpeningClutchingRatingBenchmark>;
	public static loadBenchmarkModule(type: 'map-win-rates', meta: BenchmarkMetadata, mapName: MapName | null): Promise<MapWinRateBenchmark>;
	public static loadBenchmarkModule(type: 'map-zones', meta: null, mapName: MapName): Promise<MapConfigurationInterface>;
	public static loadBenchmarkModule(type: 'player-stats', meta: BenchmarkMetadata, mapName: MapName | null): Promise<PlayerStatsBenchmark>;
	public static loadBenchmarkModule(type: 'spray-accuracy-per-weapon', meta: BenchmarkMetadata, mapName: MapName | null): Promise<SprayAccuracyPerWeaponBenchmark>;
	public static loadBenchmarkModule(type: 'utility-grenades-thrown', meta: BenchmarkMetadata, mapName: MapName | null): Promise<UtilityGrenadesThrownBenchmark>;
	public static async loadBenchmarkModule(
		type: 'bombsite-attempts' | 'leetify-opening-clutching-ratings' | 'map-win-rates' | 'map-zones' | 'player-stats' | 'spray-accuracy-per-weapon' | 'utility-grenades-thrown',
		meta: BenchmarkMetadata,
		mapName: MapName | null,
	): Promise<unknown> {
		try {
			if (type === 'bombsite-attempts' || type === 'map-win-rates') {
				const module = await import(`./benchmarks/${type}/${meta.dataSource}/${meta.rankType}/${meta.skillLevelMin}-${meta.skillLevelMax}`);
				return mapName
					? JSON.parse(JSON.stringify(module.default[mapName] ?? {}))
					: JSON.parse(JSON.stringify(module.default));
			}

			const dataSourceSkillLevelPath = type === 'map-zones' ? '' : `${meta.dataSource}/${meta.rankType}/${meta.skillLevelMin}-${meta.skillLevelMax}/`;
			const module = await import(`./benchmarks/${type}/${dataSourceSkillLevelPath}${mapName}`);

			return JSON.parse(JSON.stringify(module.default));
		} catch (err) {
			console.error(logLabel, 'ERROR while trying to load benchmark:', type, meta.dataSource, meta.skillLevelMin, meta.skillLevelMax, meta.rankType, mapName, err.message);
			return {};
		}
	}

	public static getRankLabel(dataSource: DataSource.FACEIT, skillLevel: number, rankType: null): string;
	public static getRankLabel(dataSource: DataSource.MATCHMAKING, skillLevel: number, rankType: MatchmakingRankType): string;
	public static getRankLabel(dataSource: DataSource.MATCHMAKING_WINGMAN, skillLevel: number, rankType: MatchmakingRankType.WINGMAN): string;
	public static getRankLabel(dataSource: DataSource, skillLevel: number, rankType: MatchmakingRankType | null): string; // will return fallback
	public static getRankLabel(dataSource: DataSource, skillLevel: number, rankType: MatchmakingRankType | null): string {
		switch (dataSource) {
			case DataSource.FACEIT: {
				if (skillLevel < 1 || skillLevel > 10 || !Number.isInteger(skillLevel)) return;
				return `Faceit Level ${skillLevel}`;
			}

			case DataSource.MATCHMAKING:
			case DataSource.MATCHMAKING_WINGMAN: {
				if (rankType === MatchmakingRankType.CS2_COMPETITIVE) {
					return new Intl.NumberFormat('en-US').format(skillLevel);
				}

				switch (skillLevel) {
					case 0: return 'Unranked/Expired';
					case 1: return 'Silver 1';
					case 2: return 'Silver 2';
					case 3: return 'Silver 3';
					case 4: return 'Silver 4';
					case 5: return 'Silver Elite';
					case 6: return 'Silver Elite Master';
					case 7: return 'Gold Nova 1';
					case 8: return 'Gold Nova 2';
					case 9: return 'Gold Nova 3';
					case 10: return 'Gold Nova Master';
					case 11: return 'Master Guardian 1';
					case 12: return 'Master Guardian 2';
					case 13: return 'Master Guardian Elite';
					case 14: return 'Distinguished Master Guardian';
					case 15: return 'Legendary Eagle';
					case 16: return 'Legendary Eagle Master';
					case 17: return 'Supreme Master First Class';
					case 18: return 'The Global Elite';
				}
				// eslint-ignore-line no-fallthrough
			}

			default: return `${dataSource} ${skillLevel}`;
		}
	}

	public static getBenchmarkMetadata(dataSource: DataSource.FACEIT, skillLevel: number, rankType: null): BenchmarkMetadata;
	public static getBenchmarkMetadata(dataSource: DataSource.MATCHMAKING, skillLevel: number, rankType: MatchmakingRankType.CS2_COMPETITIVE): BenchmarkMetadata;
	public static getBenchmarkMetadata(dataSource: DataSource, skillLevel: number, rankType: MatchmakingRankType | null): undefined;
	public static getBenchmarkMetadata(dataSource: DataSource, skillLevel: number, rankType: MatchmakingRankType | null): BenchmarkMetadata {
		rankType = rankType === undefined ? null : rankType; // make sure we're using `null` if we don't want a rank type

		return this.benchmarkMetadatas.find((m) => m.dataSource === dataSource
			&& m.rankType === rankType
			&& m.skillLevelMin <= skillLevel
			&& m.skillLevelMax >= skillLevel);
	}

	public static findPreferredBenchmarkMetadata(dataSource: DataSource.FACEIT, skillLevel: number, rankType: null): BenchmarkMetadata;
	public static findPreferredBenchmarkMetadata(dataSource: DataSource.MATCHMAKING, skillLevel: number, rankType: MatchmakingRankType.CS2_COMPETITIVE): BenchmarkMetadata;
	public static findPreferredBenchmarkMetadata(dataSource: DataSource, skillLevel: number, rankType: MatchmakingRankType | null): BenchmarkMetadata; // will always return fallback benchmark
	public static findPreferredBenchmarkMetadata(dataSource: DataSource, skillLevel: number, rankType: MatchmakingRankType | null): BenchmarkMetadata {
		skillLevel = CalculatorHelper.limit(skillLevel, 1, BenchmarksRepository.getMaxSkillLevel(dataSource, rankType) ?? 0);

		return BenchmarksRepository.getBenchmarkMetadata(dataSource, skillLevel, rankType)
			|| this.getFallbackBenchmarkMetadata();
	}

	public static getFallbackBenchmarkMetadata(): BenchmarkMetadata {
		return BenchmarksRepository.getBenchmarkMetadata(BenchmarksRepository.fallbackBenchmarkDataSource, BenchmarksRepository.fallbackBenchmarkSkillLevel, BenchmarksRepository.fallbackBenchmarkRankType);
	}

	public static getMaxSkillLevel(dataSource: DataSource.FACEIT, rankType: null): number;
	public static getMaxSkillLevel(dataSource: DataSource.GAMERS_CLUB, rankType: null): number;
	public static getMaxSkillLevel(dataSource: DataSource.MATCHMAKING, rankType: MatchmakingRankType): number;
	public static getMaxSkillLevel(dataSource: DataSource, rankType: MatchmakingRankType | null): undefined;
	public static getMaxSkillLevel(dataSource: DataSource, rankType: MatchmakingRankType | null): number {
		switch (dataSource) {
			case DataSource.FACEIT: return 10;
			case DataSource.GAMERS_CLUB: return 20;
			case DataSource.MATCHMAKING_WINGMAN: return 18;

			case DataSource.MATCHMAKING: {
				if (rankType === MatchmakingRankType.CS2_COMPETITIVE) return 1000000;
				return 18;
			}
		}
	}

	public static getNextBenchmarkMetadata(dataSource: DataSource.FACEIT, skillLevel: number, rankType: null): BenchmarkMetadata;
	public static getNextBenchmarkMetadata(dataSource: DataSource.MATCHMAKING, skillLevel: number, rankType: MatchmakingRankType.CS2_COMPETITIVE): BenchmarkMetadata;
	public static getNextBenchmarkMetadata(dataSource: DataSource, skillLevel: number, rankType: MatchmakingRankType | null): BenchmarkMetadata {
		const currentBenchmarkMetadata = BenchmarksRepository.findPreferredBenchmarkMetadata(dataSource, skillLevel, rankType);
		const currentIndex = BenchmarksRepository.benchmarkMetadatas.indexOf(currentBenchmarkMetadata);

		const nextBenchmarkMetadata = BenchmarksRepository.benchmarkMetadatas[currentIndex + 1];
		if (!nextBenchmarkMetadata || nextBenchmarkMetadata.dataSource !== dataSource || nextBenchmarkMetadata.rankType !== rankType) return currentBenchmarkMetadata;

		return nextBenchmarkMetadata;
	}

	// TODO remove skills param? seems like it may be needless optimization
	public static async getPreferredBenchmark(dataSource: DataSource.FACEIT, skillLevel: number, rankType: null, skills?: Skill[]): Promise<Benchmark>;
	public static async getPreferredBenchmark(dataSource: DataSource.MATCHMAKING, skillLevel: number, rankType: MatchmakingRankType.CS2_COMPETITIVE, skills?: Skill[]): Promise<Benchmark>;
	public static async getPreferredBenchmark(dataSource: DataSource, skillLevel: number, rankType: MatchmakingRankType | null, skills?: Skill[]): Promise<Benchmark>; // will always return fallback benchmark
	public static async getPreferredBenchmark(dataSource: DataSource, skillLevel: number, rankType: MatchmakingRankType | null, skills?: Skill[]): Promise<Benchmark> {
		try {
			const meta = BenchmarksRepository.findPreferredBenchmarkMetadata(dataSource, skillLevel, rankType);
			return BenchmarksRepository.getFullBenchmarkFromMetadata(meta, skills);
		} catch (err) {
			console.error(logLabel, 'failed to get preferred benchmark', dataSource, skillLevel, rankType, err);
			return {} as any;
		}
	}

	// TODO remove skills param? seems like it may be needless optimization
	public static async getFullBenchmarkFromMetadata(meta: BenchmarkMetadata, skills: Skill[] = Skills.getSkillConfigs()): Promise<Benchmark> {
		try {
			const benchmark = await BenchmarksRepository.loadBenchmarkModule('player-stats', meta, null);
			if (!benchmark) return;

			return this.buildNormalizedFullBenchmark(meta, benchmark, skills);
		} catch (err) {
			console.error(logLabel, 'failed to get benchmark from metadata', meta.dataSource, meta.skillLevelMin, meta.skillLevelMax, meta.rankType, err);
			return {} as any;
		}
	}

	// TODO remove skills param? seems like it may be needless optimization
	public static async getPreferredBenchmarksForAllMaps(dataSource: DataSource.FACEIT, skillLevel: number, rankType: null, skills?: Skill[]): Promise<{ [mapName: string]: Benchmark }>;
	public static async getPreferredBenchmarksForAllMaps(dataSource: DataSource.MATCHMAKING, skillLevel: number, rankType: MatchmakingRankType.CS2_COMPETITIVE, skills?: Skill[]): Promise<{ [mapName: string]: Benchmark }>;
	public static async getPreferredBenchmarksForAllMaps(dataSource: DataSource, skillLevel: number, rankType: MatchmakingRankType | null, skills?: Skill[]): Promise<{ [mapName: string]: Benchmark }>;
	public static async getPreferredBenchmarksForAllMaps(dataSource: DataSource, skillLevel: number, rankType: MatchmakingRankType | null, skills: Skill[] = Skills.getSkillConfigs()): Promise<{ [mapName: string]: Benchmark }> {
		try {
			const meta = BenchmarksRepository.findPreferredBenchmarkMetadata(dataSource, skillLevel, rankType);

			const benchmarks: PlayerStatsBenchmark[] = await Promise.all(
				[null, ...benchmarkMapNames].map((mapName) => BenchmarksRepository.loadBenchmarkModule('player-stats', meta, mapName)),
			);

			const fullBenchmarks: { [mapName: string]: Benchmark } = {};

			await Promise.all(benchmarks.map(async (benchmark) => {
				try {
					if (!benchmark) return;
					fullBenchmarks[benchmark.mapName] = await this.buildNormalizedFullBenchmark(meta, benchmark, skills);
				} catch (err) {
					console.error(logLabel, 'failed to get preferred benchmark for all maps', dataSource, skillLevel, rankType, benchmark, err);
				}
			}));

			return fullBenchmarks;
		} catch (err) {
			console.error(logLabel, 'failed to get preferred benchmark for all maps', dataSource, skillLevel, rankType, err);
			return {};
		}
	}

	protected static async buildNormalizedFullBenchmark(
		meta: BenchmarkMetadata,
		playerStatsBenchmark: PlayerStatsBenchmark,
		skills: Skill[],
	): Promise<Benchmark> {
		const [
			deterministicBenchmark,
			openingClutchingRatingsBenchmark,
			sprayAccuracyPerWeaponBenchmark,
			teamplayDuelsBenchmark,
			teamplaySideBenchmark,
			utilityGrenadesThrownBenchmark,
		] = await Promise.all([
			BenchmarksRepository.getDeterministicBenchmark(playerStatsBenchmark),
			BenchmarksRepository.getOpeningClutchingRatingsBenchmark(meta),
			BenchmarksRepository.getSprayAccuracyPerWeaponBenchmark(meta),
			BenchmarksRepository.getTeamplayDuelsBenchmark(playerStatsBenchmark),
			BenchmarksRepository.getTeamplaySideBenchmark(meta),
			BenchmarksRepository.getUtilityGrenadesThrownBenchmark(meta),
		]);

		const fullBenchmark: Benchmark = {
			...teamplaySideBenchmark,
			...teamplayDuelsBenchmark,
			...sprayAccuracyPerWeaponBenchmark,
			...deterministicBenchmark,
			...utilityGrenadesThrownBenchmark,
			...openingClutchingRatingsBenchmark,
			...playerStatsBenchmark,
		};

		return this.normalizeBenchmark(fullBenchmark, skills);
	}

	// add more input params if needed.
	// This function is an additional benchmark for any that are calcualted as a result of existing benchmarks
	protected static getDeterministicBenchmark(playerStatsBenchmark: PlayerStatsBenchmark): DeterministicBenchmark {
		const flashbangScoreAvg = Math.max(
			(playerStatsBenchmark?.flashbangHitFoeDurationAvg || 0)
				+ 5 * (playerStatsBenchmark?.flashAssistAvg || 0)
				+ (playerStatsBenchmark?.flashbangHitFoePerFlashbangAvg || 0)
				- 0.25 * (playerStatsBenchmark?.flashbangHitFriendPerFlashbangAvg || 0),
			0,
		);

		const flashbangScoreStd = Math.sqrt(
			Math.max(
				(playerStatsBenchmark?.flashbangHitFoeDurationStd || 0) ** 2
					+ 5 ** 2 * (playerStatsBenchmark?.flashAssistAvg || 0) ** 2
					+ (playerStatsBenchmark?.flashbangHitFoePerFlashbangAvg || 0) ** 2
					- 0.25 ** 2 * (playerStatsBenchmark?.flashbangHitFriendPerFlashbangAvg || 0) ** 2,
				0,
			),
		);

		return {
			flashbangScoreAvg,
			flashbangScoreStd,

			dataSource: playerStatsBenchmark.dataSource,
			label: playerStatsBenchmark.label,
			mapName: playerStatsBenchmark.mapName,
			rankType: playerStatsBenchmark.rankType,
			roundsPlayedAvg: playerStatsBenchmark.roundsPlayedAvg,
			roundsPlayedStd: playerStatsBenchmark.roundsPlayedStd,
			sampleSize: playerStatsBenchmark.sampleSize,
			skillLevelMax: playerStatsBenchmark.skillLevelMax,
			skillLevelMin: playerStatsBenchmark.skillLevelMin,
		};
	}

	protected static async getUtilityGrenadesThrownBenchmark(meta: BenchmarkMetadata): Promise<UtilityGrenadesThrownBenchmark> {
		try {
			if (meta.skillLevelMin === null && meta.skillLevelMax === null) return {} as any; // don't include this in unified benchmark

			// the `await` is important
			return await BenchmarksRepository.loadBenchmarkModule('utility-grenades-thrown', meta, null);
		} catch (err) {
			console.error(logLabel, 'failed to get spray accuracy benchmark', meta, err);
			return {} as any;
		}
	}

	protected static async getSprayAccuracyPerWeaponBenchmark(meta: BenchmarkMetadata): Promise<SprayAccuracyPerWeaponBenchmark> {
		try {
			if (meta.skillLevelMin === null && meta.skillLevelMax === null) return {} as any; // don't include this in unified benchmark

			// the `await` is important
			return await BenchmarksRepository.loadBenchmarkModule('spray-accuracy-per-weapon', meta, null);
		} catch (err) {
			console.error(logLabel, 'failed to get spray accuracy benchmark', meta, err);
			return {} as any;
		}
	}

	protected static async getOpeningClutchingRatingsBenchmark(meta: BenchmarkMetadata): Promise<LeetifyOpeningClutchingRatingBenchmark> {
		try {
			// the `await` is important
			return await BenchmarksRepository.loadBenchmarkModule('leetify-opening-clutching-ratings', meta, null);
		} catch (err) {
			console.error(logLabel, 'failed to get clutch rating benchmark', meta, err);
			return {} as any;
		}
	}

	protected static normalizeBenchmark(benchmark: Benchmark, skills: Skill[]): Benchmark {
		for (const skill of skills) {
			if (!skill.benchmarkKeyAvg || !skill.benchmarkKeyStd) continue;

			const stdRescaleFactor = skill.isScaledForMatch || skill.keepValuesVerbatim
				? 1
				: Math.sqrt(benchmark.roundsPlayedAvg || 1);

			// TODO why are we having issues without "as any" here?
			(benchmark as any)[skill.benchmarkKeyAvg] = BenchmarksRepository.normalizeBenchmarkValue(benchmark[skill.benchmarkKeyAvg], skill);
			(benchmark as any)[skill.benchmarkKeyStd] = BenchmarksRepository.normalizeBenchmarkValue(benchmark[skill.benchmarkKeyStd], skill) / stdRescaleFactor;
		}

		return benchmark;
	}

	public static normalizeBenchmarkValue(benchmarkValue: any, skill: Skill): number {
		benchmarkValue = Number(benchmarkValue);

		if (skill.keepValuesVerbatim) return benchmarkValue;

		switch (skill.postSign) {
			case 'ms': return parseFloat((benchmarkValue * 1000).toFixed(0));
			case '$': return parseFloat((benchmarkValue).toFixed(0));

			case '%':
			case '+':
				return parseFloat((benchmarkValue * 100).toFixed(2));

			case '°':
			case 'sec':
				return parseFloat(benchmarkValue.toFixed(2));

			default: {
				if (!skill.postSign) return parseFloat(benchmarkValue.toFixed(2));
				return benchmarkValue;
			}
		}
	}

	protected static async getMapWinRateBenchmark(meta: BenchmarkMetadata): Promise<TeamplaySideMapWinRateBenchmark> {
		if (meta.skillLevelMin === null && meta.skillLevelMax === null) return {} as any; // don't include this in unified benchmark

		const ctRoundsWonRatios: { [mapName: string]: number } = {};
		const tRoundsWonRatios: { [mapName: string]: number } = {};

		await Promise.all(Object.values(MapName).map(async (mapName) => {
			try {
				const mwrb = await BenchmarksRepository.loadBenchmarkModule('map-win-rates', meta, mapName);
				if (!mwrb) return;

				ctRoundsWonRatios[mwrb.mapName] = mwrb.ctRoundsWonRatio;
				tRoundsWonRatios[mwrb.mapName] = mwrb.tRoundsWonRatio;
			} catch (err) {
				console.error(logLabel, 'failed to get map win rate benchmark', meta, mapName, err);
			}
		}));

		// TODO this seems very out of date?
		return {
			ctRoundWinRateAvg: CalculatorHelper.averageOf(Object.values(ctRoundsWonRatios), 0) / 100,
			ctRoundWinRateStd: BenchmarksRepository.roundWinRateStd / 100,

			tRoundWinRateAvg: CalculatorHelper.averageOf(Object.values(tRoundsWonRatios), 0) / 100,
			tRoundWinRateStd: BenchmarksRepository.roundWinRateStd / 100,

			// divide all these by 100, as they'll be multiplied by 100 when they're normalized
			ctRoundWinRateAncientAvg: ctRoundsWonRatios.de_ancient / 100,
			ctRoundWinRateAncientStd: BenchmarksRepository.roundWinRateStd / 100,
			tRoundWinRateAncientAvg: tRoundsWonRatios.de_ancient / 100,
			tRoundWinRateAncientStd: BenchmarksRepository.roundWinRateStd / 100,

			ctRoundWinRateDust2Avg: ctRoundsWonRatios.de_dust2 / 100,
			ctRoundWinRateDust2Std: BenchmarksRepository.roundWinRateStd / 100,
			tRoundWinRateDust2Avg: tRoundsWonRatios.de_dust2 / 100,
			tRoundWinRateDust2Std: BenchmarksRepository.roundWinRateStd / 100,

			ctRoundWinRateInfernoAvg: ctRoundsWonRatios.de_inferno / 100,
			ctRoundWinRateInfernoStd: BenchmarksRepository.roundWinRateStd / 100,
			tRoundWinRateInfernoAvg: tRoundsWonRatios.de_inferno / 100,
			tRoundWinRateInfernoStd: BenchmarksRepository.roundWinRateStd / 100,

			ctRoundWinRateMirageAvg: ctRoundsWonRatios.de_mirage / 100,
			ctRoundWinRateMirageStd: BenchmarksRepository.roundWinRateStd / 100,
			tRoundWinRateMirageAvg: tRoundsWonRatios.de_mirage / 100,
			tRoundWinRateMirageStd: BenchmarksRepository.roundWinRateStd / 100,

			ctRoundWinRateNukeAvg: ctRoundsWonRatios.de_nuke / 100,
			ctRoundWinRateNukeStd: BenchmarksRepository.roundWinRateStd / 100,
			tRoundWinRateNukeAvg: tRoundsWonRatios.de_nuke / 100,
			tRoundWinRateNukeStd: BenchmarksRepository.roundWinRateStd / 100,

			ctRoundWinRateOverpassAvg: ctRoundsWonRatios.de_overpass / 100,
			ctRoundWinRateOverpassStd: BenchmarksRepository.roundWinRateStd / 100,
			tRoundWinRateOverpassAvg: tRoundsWonRatios.de_overpass / 100,
			tRoundWinRateOverpassStd: BenchmarksRepository.roundWinRateStd / 100,

			ctRoundWinRateVertigoAvg: ctRoundsWonRatios.de_vertigo / 100,
			ctRoundWinRateVertigoStd: BenchmarksRepository.roundWinRateStd / 100,
			tRoundWinRateVertigoAvg: tRoundsWonRatios.de_vertigo / 100,
			tRoundWinRateVertigoStd: BenchmarksRepository.roundWinRateStd / 100,
		};
	}

	protected static async getBombsiteAttemptBenchmark(meta: BenchmarkMetadata): Promise<TeamplaySideBombsiteAttemptBenchmark> {
		if (meta.skillLevelMin === null && meta.skillLevelMax === null) return {} as any; // don't include this in unified benchmark

		const holdRatios: { a: number[]; b: number[] } = { a: [], b: [] };
		const retakeRatios: { a: number[]; b: number[] } = { a: [], b: [] };
		const takeRatios: { a: number[]; b: number[] } = { a: [], b: [] };
		const afterplantRatios: { a: number[]; b: number[] } = { a: [], b: [] };

		await Promise.all(Object.values(MapName).map(async (mapName) => {
			if (!mapName.startsWith('de_')) return;

			try {
				const bsab = await BenchmarksRepository.loadBenchmarkModule('bombsite-attempts', meta, mapName);
				if (!bsab) return;

				holdRatios.a.push(bsab.aHoldRatio);
				holdRatios.b.push(bsab.bHoldRatio);
				retakeRatios.a.push(bsab.aRetakeRatio);
				retakeRatios.b.push(bsab.bRetakeRatio);
				takeRatios.a.push(bsab.aTakeRatio);
				takeRatios.b.push(bsab.bTakeRatio);
				afterplantRatios.a.push(bsab.aAfterplantRatio);
				afterplantRatios.b.push(bsab.bAfterplantRatio);
			} catch (err) {
				console.log(logLabel, 'failed to get bombsite attempt benchmark', meta, mapName, err);
			}
		}));

		return {
			// Hold
			ctBombsiteHoldAvg: CalculatorHelper.averageOf([...holdRatios.a, ...holdRatios.b], 0) / 100,
			ctBombsiteHoldStd: BenchmarksRepository.bombsiteAttemptStd / 100,

			ctBombsiteAHoldAvg: CalculatorHelper.averageOf(holdRatios.a, 0) / 100,
			ctBombsiteAHoldStd: BenchmarksRepository.bombsiteAttemptStd / 100,

			ctBombsiteBHoldAvg: CalculatorHelper.averageOf(holdRatios.b, 0) / 100,
			ctBombsiteBHoldStd: BenchmarksRepository.bombsiteAttemptStd / 100,

			// Retake
			ctBombsiteRetakeAvg: CalculatorHelper.averageOf([...retakeRatios.a, ...retakeRatios.b], 0) / 100,
			ctBombsiteRetakeStd: BenchmarksRepository.bombsiteAttemptStd / 100,

			ctBombsiteARetakeAvg: CalculatorHelper.averageOf(retakeRatios.a, 0) / 100,
			ctBombsiteARetakeStd: BenchmarksRepository.bombsiteAttemptStd / 100,

			ctBombsiteBRetakeAvg: CalculatorHelper.averageOf(retakeRatios.b, 0) / 100,
			ctBombsiteBRetakeStd: BenchmarksRepository.bombsiteAttemptStd / 100,

			// Take
			tBombsiteTakeAvg: CalculatorHelper.averageOf([...takeRatios.a, ...takeRatios.b], 0) / 100,
			tBombsiteTakeStd: BenchmarksRepository.bombsiteAttemptStd / 100,

			tBombsiteATakeAvg: CalculatorHelper.averageOf(takeRatios.a, 0) / 100,
			tBombsiteATakeStd: BenchmarksRepository.bombsiteAttemptStd / 100,

			tBombsiteBTakeAvg: CalculatorHelper.averageOf(takeRatios.b, 0) / 100,
			tBombsiteBTakeStd: BenchmarksRepository.bombsiteAttemptStd / 100,

			// Afterplant
			tBombsiteAfterplantAvg: CalculatorHelper.averageOf([...afterplantRatios.a, ...afterplantRatios.b], 0) / 100,
			tBombsiteAfterplantStd: BenchmarksRepository.bombsiteAttemptStd / 100,

			tBombsiteAAfterplantAvg: CalculatorHelper.averageOf(afterplantRatios.a, 0) / 100,
			tBombsiteAAfterplantStd: BenchmarksRepository.bombsiteAttemptStd / 100,

			tBombsiteBAfterplantAvg: CalculatorHelper.averageOf(afterplantRatios.b, 0) / 100,
			tBombsiteBAfterplantStd: BenchmarksRepository.bombsiteAttemptStd / 100,
		};
	}

	protected static async getTeamplaySideBenchmark(meta: BenchmarkMetadata): Promise<TeamplaySideBenchmark> {
		const [
			bombsiteAttemptBenchmark,
			mapWinRateBenchmark,
		] = await Promise.all([
			BenchmarksRepository.getBombsiteAttemptBenchmark(meta),
			BenchmarksRepository.getMapWinRateBenchmark(meta),
		]);

		return {
			...mapWinRateBenchmark,
			...bombsiteAttemptBenchmark,
			...BenchmarksRepository.fakeBenchmark,
		};
	}

	protected static getTeamplayDuelsBenchmark(selectedBenchmark: PlayerStatsBenchmark): TeamplayDuelsBenchmark {
		const tradeRatingAvg = CalculatorHelper.averageOf([
			selectedBenchmark.tradeKillsSuccessPercentageAvg,
			selectedBenchmark.tradeKillOpportunitiesPerRoundAvg,
			selectedBenchmark.tradedDeathsSuccessPercentageAvg,
		], 2);

		const tradeRatingStd = CalculatorHelper.averageOf([
			selectedBenchmark.tradeKillsSuccessPercentageStd,
			selectedBenchmark.tradeKillOpportunitiesPerRoundStd,
			selectedBenchmark.tradedDeathsSuccessPercentageStd,
		]);

		const ctOpeningDuelsRatingAvg = CalculatorHelper.averageOf([
			selectedBenchmark.ctOpeningAggressionSuccessRateAvg,
			selectedBenchmark.ctOpeningDuelSuccessPercentageAvg,
		], 2);

		const ctOpeningDuelsRatingStd = CalculatorHelper.averageOf([
			selectedBenchmark.ctOpeningAggressionSuccessRateStd,
			selectedBenchmark.ctOpeningDuelSuccessPercentageStd,
		]);

		const tOpeningDuelsRatingAvg = CalculatorHelper.averageOf([
			selectedBenchmark.tOpeningAggressionSuccessRateAvg,
			selectedBenchmark.tOpeningDuelSuccessPercentageAvg,
		], 2);

		const tOpeningDuelsRatingStd = CalculatorHelper.averageOf([
			selectedBenchmark.tOpeningAggressionSuccessRateStd,
			selectedBenchmark.tOpeningDuelSuccessPercentageStd,
		]);

		return {
			ctTradeRatingAvg: tradeRatingAvg * 100,
			ctTradeRatingStd: tradeRatingStd * 100,
			tTradeRatingAvg: tradeRatingAvg * 100,
			tTradeRatingStd: tradeRatingStd * 100,

			ctOpeningDuelsRatingAvg: ctOpeningDuelsRatingAvg * 100,
			ctOpeningDuelsRatingStd: ctOpeningDuelsRatingStd * 100,

			tOpeningDuelsRatingAvg: tOpeningDuelsRatingAvg * 100,
			tOpeningDuelsRatingStd: tOpeningDuelsRatingStd * 100,
		};
	}

	public static normalcdf(to: number): number {
		const z = to / Math.sqrt(2);
		const t = 1 / (1 + 0.3275911 * Math.abs(z));
		const a1 = 0.254829592;
		const a2 = -0.284496736;
		const a3 = 1.421413741;
		const a4 = -1.453152027;
		const a5 = 1.061405429;
		const erf = 1 - (((((a5 * t + a4) * t) + a3) * t + a2) * t + a1) * t * Math.exp(-z * z);
		const sign = z < 0 ? -1 : 1;

		return (1 / 2) * (1 + sign * erf);
	}
}
