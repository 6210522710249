import { Benchmark } from '../../benchmarks-repository';
import { PlayerClutchKillsPercentage } from './player-clutch-kills-percentage';
import { SkillId } from '../skill';

export class PlayerClutch1v3KillsPercentage extends PlayerClutchKillsPercentage {
	public readonly benchmarkKeyAvg: keyof Benchmark = 'playerClutch1v3KillsPercentageAvg';
	public readonly benchmarkKeyStd: keyof Benchmark = 'playerClutch1v3KillsPercentageStd';
	id = SkillId.PLAYER_CLUTCH_1V3_KILLS_PERCENTAGE;
	title = '1v3 Clutch Kills';

	focusAreaLowerBound = 12;
	focusAreaUpperBound = 26;
}
