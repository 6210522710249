import { Injectable } from '@angular/core';
import { BehaviorSubject, Subject } from 'rxjs';
import { first, takeUntil } from 'rxjs/operators';

type ScriptAlias = 'faceitOauth' | 'stripe';

@Injectable({
	providedIn: 'root',
})
export class ScriptLoaderService {
	public readonly faceitOauthLoaded$ = new BehaviorSubject<boolean>(false);
	public readonly stripeLoaded$ = new BehaviorSubject<boolean>(false);

	protected readonly faceitOauthUrl = 'https://cdn.faceit.com/oauth/faceit-oauth-sdk-1.2.6.min.js';
	protected readonly stripeUrl = 'https://js.stripe.com/v3/';

	public load(): void {
		// if we're SSR-ing (or the page has somehow already finished loading), immediately load third party scripts -- wait for the page to finish loading otherwise
		if (typeof document === 'undefined' || document.readyState === 'complete') {
			this.appendAllScripts();
		} else {
			document.addEventListener('readystatechange', () => {
				if (document.readyState === 'complete') this.appendAllScripts();
			});
		}
	}

	public awaitScript(scriptAlias: ScriptAlias, ngUnsubscribe: Subject<void>, callback: () => unknown): void {
		const subject = this.getSubjectFromAlias(scriptAlias);

		if (subject.getValue()) {
			callback();
			return;
		}

		subject.pipe(
			first((isLoaded) => isLoaded),
			takeUntil(ngUnsubscribe),
		).subscribe(() => {
			callback();
		});
	}

	protected appendAllScripts(): void {
		this.appendScript(this.faceitOauthUrl);
		this.appendScript(this.stripeUrl); // Stripe recommends including their script on all pages (not just the checkout page) "to prevent fraud"
	}

	protected getSubjectFromAlias(scriptAlias: ScriptAlias): BehaviorSubject<boolean> {
		switch (scriptAlias) {
			case 'faceitOauth': return this.faceitOauthLoaded$;
			case 'stripe': return this.stripeLoaded$;
		}
	}

	protected appendScript(src: string): void {
		const el = document.createElement('script');
		el.src = src;
		el.async = true;
		el.defer = true;
		el.addEventListener('load', (e) => this.onLoaded(e, src));
		document.head.appendChild(el);
	}

	protected onLoaded(e: Event, src: string): void {
		switch (src) {
			case this.faceitOauthUrl: return this.faceitOauthLoaded$.next(true);
			case this.stripeUrl: return this.stripeLoaded$.next(true);
		}
	}
}
