import { Benchmark } from '../../benchmarks-repository';
import { FocusAreaSkill } from '../focus-area-skill';
import { PostSign, SkillGroupId, SkillId } from '../skill';

export class PlayerClutchRating extends FocusAreaSkill {
	public readonly benchmarkKeyAvg: keyof Benchmark = 'clutching1vXAvg';
	public readonly benchmarkKeyStd: keyof Benchmark = 'clutching1vXStd';
	public groupId: SkillGroupId = 'clutchSkillGroup';
	public postSign?: PostSign = '+';
	public readonly showChart: boolean = false;

	dependsOn = null;
	description = '';
	detailsInfo = false;
	displayInGoodBadSummary = false;
	groupTitle = 'Clutching';
	id = SkillId.CLUTCH_1VX_RATING;
	improveTip = false;
	inversed = false;
	isRatio = false;
	minValue = 0;
	smallDescription = '';
	title = 'Overall Clutch Rating';

	focusAreaLowerBound = 0;
	focusAreaUpperBound = 25;
}
