<svg
	*ngIf="label"
	[attr.aria-label]="label"
	[ngbTooltip]="tooltip && label"
	[ngClass]="className"
	container="body"
	openDelay="300"
	tooltipClass="default-tooltip"
	viewBox="0 0 32.94 23.29"
>
	<path class="left" d="M10.8 8.37v.09l-1.26 5.72a1.1 1.1 0 0 0 .84 1.31h4.14a1.1 1.1 0 0 1 1.11 1.09 1 1 0 0 1 0 .24l-1.38 6.44H1.67a1.65 1.65 0 0 1-1.66-1.62 2 2 0 0 1 0-.35L4.22 1.75A2.21 2.21 0 0 1 6.38 0h6.2Z" />
	<path class="right" d="M12.68 7.15 14.2 0h17.08a1.65 1.65 0 0 1 1.65 1.65 1.93 1.93 0 0 1 0 .34l-4.21 19.56a2.19 2.19 0 0 1-2.16 1.74h-6.81l3.13-14.53a1.34 1.34 0 0 0-1-1.58h-.28Z" />
</svg>
