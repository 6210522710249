import { HttpClient, HttpErrorResponse } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { offset } from '@popperjs/core';
import { Subject } from 'rxjs';
import { WebsocketService } from 'src/app/services/websocket.service';

// TODO move this to independent file:
export type GameDTO = {
	id: string;
	gameDuration: number;
	queueId: string;
	teams: [
		{
			team: string;
			participants: [
				{
					puuid: string;
					champion_level: number;
					items: string;
					summoner_spell_one: number;
					summoner_spell_two: number;
					champion_id: number;
					win: boolean;
					role: string;
					user_name: string;
					user_tag: string;
					created_at: Date;
					profile_icon_id: number;
					team: string;
					player_id: string;
					primary_selection_id: number;
					primary_selection_1: number;
					primary_selection_2: number;
					primary_selection_3: number;
					primary_selection_4: number;
					secondary_selection_id: number;
					secondary_selection_1: number;
					secondary_selection_2: number;
					stat_selection_1: number;
					stat_selection_2: number;
					stat_selection_3: number;
					kills: number;
					deaths: number;
					assists: number;
					total_damage_dealt: number;
					total_gold_earned: number;
					total_gold_spent: number;
					total_self_heal: number;
					total_damage_taken: number;
					magic_damage_taken: number;
					physical_damage_taken: number;
					true_damage_taken: number;
					damage_self_mitigated: number;
					wards_placed: number;
					wards_destroyed: number;
					total_damage_to_turrets: number;
					total_damage_to_objectives: number;
					vision_score: number;
					cc_score: number;
					cs_per_minute: number;
					gold_per_minute: number;
					damage_per_minute: number;
				},
			];
		},
	];
};

const placeholderGame: GameDTO = {
	id: 'string',
	gameDuration: 10,
	queueId: 'string',
	teams: [
		{
			team: 'string',
			participants: [
				{
					puuid: 'string',
					champion_level: 10,
					items: 'string',
					summoner_spell_one: 10,
					summoner_spell_two: 10,
					champion_id: 10,
					win: true,
					role: 'string',
					user_name: 'string',
					user_tag: 'string',
					created_at: new Date(),
					profile_icon_id: 10,
					team: 'string',
					player_id: 'string',
					primary_selection_id: 10,
					primary_selection_1: 10,
					primary_selection_2: 10,
					primary_selection_3: 10,
					primary_selection_4: 10,
					secondary_selection_id: 10,
					secondary_selection_1: 10,
					secondary_selection_2: 10,
					stat_selection_1: 10,
					stat_selection_2: 10,
					stat_selection_3: 10,
					kills: 10,
					deaths: 10,
					assists: 10,
					total_damage_dealt: 10,
					total_gold_earned: 10,
					total_gold_spent: 10,
					total_self_heal: 10,
					total_damage_taken: 10,
					magic_damage_taken: 10,
					physical_damage_taken: 10,
					true_damage_taken: 10,
					damage_self_mitigated: 10,
					wards_placed: 10,
					wards_destroyed: 10,
					total_damage_to_turrets: 10,
					total_damage_to_objectives: 10,
					vision_score: 10,
					cc_score: 10,
					cs_per_minute: 10,
					gold_per_minute: 10,
					damage_per_minute: 10,
				},
			],
		},
	],
};

export type GamesHistoryResponse = {
	response: GameDTO[];
	meta: {
		next: number;
	};
};

@Injectable({
	providedIn: 'root',
})
export class GamesService {
	private readonly gamesHistorySource = new Subject<GamesHistoryResponse>();
	public readonly gamesHistory$ = this.gamesHistorySource.asObservable();

	private readonly gameDetailsSource = new Subject<GameDTO>();
	public readonly gameDetails$ = this.gameDetailsSource.asObservable();

	private readonly lastProcessedGameSource = new Subject<GameDTO>();
	public readonly lastProcessedGame$ = this.lastProcessedGameSource.asObservable();

	private readonly gamesListByIds = new Subject<GameDTO[]>();
	public readonly gamesListByIds$ = this.gamesListByIds.asObservable();

	private gameDetailsCache: { [gameId: string]: GameDTO } = {};
	private gamesHistoryCache: { [paramsHash: string]: GamesHistoryResponse } = {};
	private gamesListByIdsCache: { [key: string]: GameDTO[] } = {};

	public constructor(
		private readonly http: HttpClient,
		private readonly websocketService: WebsocketService,
	) {
		// TODO (maybe?) move this to the individual components
		this.websocketService.newGameAvailable$.subscribe((gameId) => {
			this.gamesHistoryCache = {};
			this.gamesListByIdsCache = {};

			for (const cache of [this.gameDetailsCache]) {
				delete cache[gameId];
			}
		});
	}

	public reloadGamesHistory(filters?: any, spectatingId?: string): void {
		// Placeholder implementation
		// this.gamesHistorySource.next({
		// 	meta: {
		// 		next: 0,
		// 	},
		// 	response: [placeholderGame],
		// });
		// return;

		const params = { ...filters };
		if (spectatingId) {
			params['spectatingId'] = spectatingId;
		}

		const paramsHash = JSON.stringify(params);

		if (this.gamesHistoryCache[paramsHash]) {
			this.gamesHistorySource.next(this.gamesHistoryCache[paramsHash]);
			return;
		}

		this.http.get<GamesHistoryResponse>('/api/match/v1/matches', { params }).subscribe((gamesHistory: GamesHistoryResponse) => {
			this.gamesHistoryCache = {}; // removing old cache to preserve memory
			this.gamesHistoryCache[paramsHash] = gamesHistory;
			this.gamesHistorySource.next(gamesHistory);
		});
	}

	public reloadGameDetails(id: string): void {
		// Placeholder implementation
		// this.gameDetailsSource.next(placeholderGame);
		// return;

		if (this.gameDetailsCache[id]) {
			this.gameDetailsSource.next(this.gameDetailsCache[id]);
			return;
		}

		this.http.get<GameDTO>(`/api/match/v1/matches/${id}`).subscribe(
			(gameDetails: GameDTO) => {
				// gameDetails.finishedAt = gameDetails.finishedAt ? new Date(gameDetails.finishedAt) : undefined;

				// gameDetails.playerStats.sort((psA, psB) => psA.initialTeamNumber - psB.initialTeamNumber);
				// gameDetails.playerStats.forEach(PlayerStatsHelper.correlateWeightedStats);

				this.gameDetailsCache[id] = gameDetails;
				this.gameDetailsSource.next(gameDetails);
			},
			(error: HttpErrorResponse) => {
				this.gameDetailsSource.next(null);
			},
		);
	}

	public reloadLastProcessedGame(): void {
		// this.http.get<GameDTO>('/api/games/last-processed').subscribe((game: GameDTO) => this.lastProcessedGameSource.next(game));
	}

	public fetchLastProcessedGame(): Promise<GameDTO> {
		return new Promise<GameDTO>((resolve) => {
			resolve(placeholderGame);
		});
		// return this.http.get<GameDTO>('/api/games/last-processed').toPromise();
	}

	public reloadGamesListById(steam64Id: string, gameIds: string[]): void {
		const paramsHash = `${steam64Id}-${gameIds}`;

		if (this.gamesListByIdsCache[paramsHash]) {
			this.gamesListByIds.next(this.gamesListByIdsCache[paramsHash]);
			// return;
		}

		// this.http.post<GameDTO[]>('/api/games/list-by-ids', { gameIds, steam64Id }).subscribe((games) => {
		// 	this.gamesListByIdsCache[paramsHash] = games;
		// 	this.gamesListByIds.next(games);
		// });
	}
}
