import { Benchmark } from '../../../benchmarks-repository';
import { MapSideWinrate } from './map-side-win-rate';
import { SkillId } from '../../skill';
import { TeamName } from '../../../enums';

export class CtRoundWinRateDust2 extends MapSideWinrate {
	public readonly benchmarkKeyAvg: keyof Benchmark = 'ctRoundWinRateDust2Avg';
	public readonly benchmarkKeyStd: keyof Benchmark = 'ctRoundWinRateDust2Std';

	id = SkillId.CT_ROUND_WIN_RATE_DUST2;
	side = TeamName.COUNTER_TERRORIST;
	title = 'CT Round Win Rate Dust 2';
}
