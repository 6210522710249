import { Benchmark } from '../../../benchmarks-repository';
import { RoundWinRate } from './round-win-rate';
import { SkillId } from '../../skill';
import { TeamName } from '../../../enums';

export class TRoundWinRate extends RoundWinRate {
	public readonly benchmarkKeyAvg: keyof Benchmark = 'tRoundWinRateAvg';
	public readonly benchmarkKeyStd: keyof Benchmark = 'tRoundWinRateStd';

	description = 'Your won rounds on T side, divided by all rounds you played on T side.';
	groupTitle = 'Teamplay T Side';
	id = SkillId.T_ROUND_WIN_RATE;
	side = TeamName.TERRORIST;
	smallDescription = 'Your won rounds on T side, divided by all rounds you played on T side.';
}
