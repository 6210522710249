import { Component, OnInit } from '@angular/core';
import { Router } from '@angular/router';
import { AuthService } from 'src/app/auth/auth.service';
import { UserService } from 'src/app/services/user.service';
import { socialMediaLinks } from 'src/constants';

@Component({
	selector: 'app-plain-layout',
	templateUrl: './plain-layout.component.html',
	styleUrls: ['./plain-layout.component.scss'],
})
export class PlainLayoutComponent implements OnInit {
	protected readonly socialMediaLinks = socialMediaLinks;

	protected attemptingLogin = false;

	public constructor(
		protected readonly router: Router,
		protected readonly userService: UserService,
	) {
		//
	}

	protected attemptLogin(): void {
		this.attemptingLogin = true;

		const loginRoutes = ['/', '/login', '/signin', '/auth/login'];

		this.userService.myself().then((user) => {
			// if (user && loginRoutes.find((lr) => lr === this.router.url)) {
			// 	this.router.navigate(['/app']);
			// }

			this.attemptingLogin = false;
		}).catch(() => {
			this.attemptingLogin = false;
		});
	}

	public ngOnInit(): void {
		if (AuthService.getToken()) this.attemptLogin();
	}
}
