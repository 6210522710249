export type ZScoreDeviationStep = -2 | -1 | 0 | 1 | 2;

export class ZScoreHelper {
	public static readonly zScorePercentile = {
		_70: 0.524,
		_90: 1.28155,
	};

	public static getZScore(userStat: number, statAvg: number, statStd: number, matchesCount: number, isInversed: boolean): number {
		let zScore = (userStat - statAvg) / statStd;
		if (isInversed) zScore *= -1;

		return zScore * Math.sqrt(matchesCount);
	}

	public static valueFromZScore(zScore: number, avg: number, std: number, significance = 2): number {
		return Number(((zScore * std) + avg).toFixed(significance));
	}

	public static getZScoreDeviationStep(userStat: number, statAvg: number, statStd: number, matchesCount: number, isInversed: boolean): ZScoreDeviationStep | null {
		const zScore = this.getZScore(userStat, statAvg, statStd, matchesCount, isInversed);

		if (zScore < -ZScoreHelper.zScorePercentile._90) return -2;
		if (zScore < -ZScoreHelper.zScorePercentile._70) return -1;
		if (zScore < ZScoreHelper.zScorePercentile._70) return 0;
		if (zScore < ZScoreHelper.zScorePercentile._90) return 1;
		if (zScore >= ZScoreHelper.zScorePercentile._90) return 2;

		return null;
	}

	public static getZScoreDeviationStepLabel(userStat: number, statAvg: number, statStd: number, matchesCount: number, isInversed: boolean): string {
		return ZScoreHelper.getZScoreLabel(
			ZScoreHelper.getZScoreDeviationStep(userStat, statAvg, statStd, matchesCount, isInversed),
		);
	}

	public static getZScoreLabel(zScoreDeviation: ZScoreDeviationStep): string {
		switch (zScoreDeviation) {
			case -2: return 'Poor';
			case -1: return 'Subpar';
			case 0: return 'Average';
			case 1: return 'Good';
			case 2: return 'Great';
		}
	}

	public static getZScoreTextColorClass(zScoreDeviation: ZScoreDeviationStep): string {
		switch (zScoreDeviation) {
			case -2: return 'text-poor';
			case -1: return 'text-subpar';
			case 0: return 'text-average';
			case 1: return 'text-good';
			case 2: return 'text-great';
		}
	}

	public static getScoreCircleOutlineColorClass(zScoreDeviation: ZScoreDeviationStep): string {
		switch (zScoreDeviation) {
			case -2: return 'poor';
			case -1: return 'subpar';
			case 0: return 'average';
			case 1: return 'good';
			case 2: return 'great';
		}
	}
}
