import { Benchmark } from '../../../benchmarks-repository';
import { CtBombsiteAttemptRate } from './ct-bombsite-attempt-rate';
import { SkillId } from '../../skill';

export class CtBombsiteHoldRate extends CtBombsiteAttemptRate {
	public readonly benchmarkKeyAvg: keyof Benchmark = 'ctBombsiteHoldAvg';
	public readonly benchmarkKeyStd: keyof Benchmark = 'ctBombsiteHoldStd';

	description = 'The amount of times you successfully defended a bombsite, divided by all times you tried to defend a bombsite against attacking Terrorists.';
	id = SkillId.CT_BOMBSITE_HOLD_RATE;
	smallDescription = 'The amount of times you successfully defended a bombsite, divided by all times you tried to defend a bombsite against attacking Terrorists.';
	title = 'Bombsite Holds';
}
