import { Benchmark } from '../../benchmarks-repository';
import { FocusAreaSkill } from '../focus-area-skill';
import { SkillGroupId, SkillId } from '../skill';

export class HeFoesDamageAvg extends FocusAreaSkill {
	public readonly benchmarkKeyAvg: keyof Benchmark = 'heFoesDamageAvg';
	public readonly benchmarkKeyStd: keyof Benchmark = 'heFoesDamageStd';
	public readonly decimalPlaces = 2;
	public readonly dependsOn = 'heThrown';
	public readonly descriptionExtra = 'All HE damage divided by the number of HEs thrown.';
	public readonly description = 'The total amount of damage you did to enemies with your HE grenades, divided by the total number of HE grenades thrown. Low numbers here means you have potential to plan your HE grenade usage better.';
	public readonly detailsInfo = false;
	public readonly displayInGoodBadSummary = true;
	public readonly fallbackValue = 'n/a';
	public readonly focusAreaLowerBound = 0;
	public readonly focusAreaUpperBound = 21;
	public readonly groupIcon = 'mdi mdi-progress-wrench';
	public readonly groupId: SkillGroupId = 'utilitySkillGroup';
	public readonly groupTitle = 'Utility group';
	public readonly icon = 'mdi mdi-star';
	public readonly id = SkillId.HE_FOES_DAMAGE_AVG;
	public readonly improveTip = true;
	public readonly inversed = false;
	public readonly isRatio = false;
	public readonly minValue = 0;
	public readonly shortTitle = 'Avg HE damage';
	public readonly smallDescription = 'All HE damage to enemies divided by the number of HEs thrown.';
	public readonly title = 'Damage to Enemies per HE';
}
