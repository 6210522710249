import { Inject, Injectable, PLATFORM_ID } from '@angular/core';
import { isPlatformBrowser } from '@angular/common';
import { Subject } from 'rxjs';
import { GameImportStatusEventData, HighlightStatusChangeEventData } from 'leetify-shared-utils/dto';
import { ImportQueueLoad, WebsocketEvent } from 'leetify-shared-utils/enums';
import { AuthService } from 'src/app/auth/auth.service';
import { environment } from 'src/environments/environment';

@Injectable({
	providedIn: 'root',
})
export class WebsocketService {
	public readonly freeProReceived$ = new Subject<void>();
	public readonly gameImportStatus$ = new Subject<GameImportStatusEventData>();
	public readonly gameImportStatuses: { [gameId: string]: GameImportStatusEventData } = {};
	public readonly importQueueLoad$ = new Subject<ImportQueueLoad>();
	public readonly newGameAvailable$ = new Subject<string>();
	public readonly highlightStatusChange$ = new Subject<HighlightStatusChangeEventData>();

	protected isBrowser: boolean;
	protected retryTimeout: ReturnType<typeof setTimeout>;
	protected ws: WebSocket;

	public constructor(
		@Inject(PLATFORM_ID) private platformId: Record<string, any>,
	) {
		this.isBrowser = isPlatformBrowser(this.platformId);

		this.setSyncInterval();
	}

	public connect(): void {
		if (!this.isBrowser) return;

		if (this.ws) this.ws.close();

		const host = environment.apiUrl.replace(/^http/, 'ws');
		this.ws = new WebSocket(`${host}/websockets`);

		this.ws.onopen = () => this.authenticate();

		this.ws.onmessage = (msg: MessageEvent) => {
			try {
				const { event, data }: { event: WebsocketEvent; data: any } = JSON.parse(msg.data);

				switch (event) {
					case WebsocketEvent.FREE_PRO_RECEIVED:
						return this.freeProReceived$.next();

					case WebsocketEvent.GAME_IMPORT_STATUS:
						this.gameImportStatuses[data.gameId] = data;
						return this.gameImportStatus$.next(data);

					case WebsocketEvent.NEW_GAME_AVAILABLE:
						delete this.gameImportStatuses[data.gameId];
						return this.newGameAvailable$.next(data.gameId);

					case WebsocketEvent.IMPORT_QUEUE_STATUS:
						return this.importQueueLoad$.next(data.load);

					case WebsocketEvent.HIGHLIGHT_STATUS_CHANGE:
						return this.highlightStatusChange$.next(data);
				}
			} catch (err) {
				console.error(err);
			}
		};

		this.ws.onclose = () => {
			if (this.retryTimeout) clearTimeout(this.retryTimeout);
			this.retryTimeout = setTimeout(() => this.connect(), 1000);
		};
	}

	public authenticate(): void {
		this.send(WebsocketEvent.AUTHENTICATE, { token: AuthService.getToken() });
	}

	public syncGames(): void {
		this.send(WebsocketEvent.SYNC_GAMES);
	}

	protected send(event: WebsocketEvent, data?: any): void {
		if (this.ws) this.ws.send(JSON.stringify({ event, data }));
	}

	protected setSyncInterval(): void {
		if (!this.isBrowser) return;

		setInterval(() => this.syncGames(), 30 * 1000); // 30 seconds
	}
}
