import { Benchmark } from '../../benchmarks-repository';
import { Skill, SkillId, PostSign } from '../skill';

export class OpeningRating extends Skill {
	public readonly benchmarkKeyAvg: keyof Benchmark = 'openingAvg';
	public readonly benchmarkKeyStd: keyof Benchmark = 'openingStd';
	public postSign?: PostSign = '+';

	dependsOn = null;
	description = '';
	descriptionExtra = '';
	detailsInfo = false;
	displayInGoodBadSummary = false;
	groupIcon = 'mdi mdi-progress-wrench';
	groupId = null;
	groupTitle = 'Ratings';
	id = SkillId.OPENING_RATING;
	improveTip = false;
	inversed = false;
	isRatio = false;
	smallDescription = '';
	title = 'Opening Leetify Rating';
}
