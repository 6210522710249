import { Benchmark } from '../../../benchmarks-repository';
import { MapSideWinrate } from './map-side-win-rate';
import { SkillId } from '../../skill';
import { TeamName } from '../../../enums';

export class CtRoundWinRateAncient extends MapSideWinrate {
	public readonly benchmarkKeyAvg: keyof Benchmark = 'ctRoundWinRateAncientAvg';
	public readonly benchmarkKeyStd: keyof Benchmark = 'ctRoundWinRateAncientStd';

	id = SkillId.CT_ROUND_WIN_RATE_ANCIENT;
	side = TeamName.COUNTER_TERRORIST;
	title = 'CT Round Win Rate Ancient';
}
