<!-- header: only show anything if at least one header-related template, or the headerText parameters are set -->
<ng-container *ngIf="headerOuterTemplate || headerInnerTemplate || headerTextTemplate || headerText">
	<ng-container *ngIf="headerOuterTemplate; else headerOuterDefault" [ngTemplateOutlet]="headerOuterTemplate.templateRef"></ng-container>

	<ng-template #headerOuterDefault>
		<header *ngIf="isHeaderSemanticElement" class="header" [ngClass]="{ '--padding': showHeaderPadding }">
			<ng-container [ngTemplateOutlet]="headerInner"></ng-container>
		</header>

		<div *ngIf="!isHeaderSemanticElement" class="header" [ngClass]="{ '--padding': showHeaderPadding }">
			<ng-container [ngTemplateOutlet]="headerInner"></ng-container>
		</div>
	</ng-template>

	<ng-template #headerInner>
		<ng-container *ngIf="headerInnerTemplate; else headerInnerDefault" [ngTemplateOutlet]="headerInnerTemplate.templateRef"></ng-container>

		<ng-template #headerInnerDefault>
			<div class="icon-and-text">
				<leetify-icon *ngIf="headerIcon" [icon]="headerIcon"></leetify-icon>
				<ng-container *ngIf="headerTextTemplate; else headerTextDefault" [ngTemplateOutlet]="headerTextTemplate.templateRef"></ng-container>

				<ng-template #headerTextDefault>
					<div *ngIf="headerText" class="text-truncate">{{ headerText }}</div>
				</ng-template>
			</div>
		</ng-template>
	</ng-template>
</ng-container>

<ng-container *ngIf="bodyOuterTemplate; else bodyOuterDefault" [ngTemplateOutlet]="bodyOuterTemplate.templateRef"></ng-container>

<ng-template #bodyOuterDefault>
	<div class="body">
		<ng-container *ngIf="bodyInnerTemplate; else bodyInnerDefault" [ngTemplateOutlet]="bodyInnerTemplate.templateRef"></ng-container>

		<ng-template #bodyInnerDefault>
			{{ bodyText }}
			<ng-content></ng-content>
		</ng-template>
	</div>
</ng-template>
