import { Benchmark } from '../../benchmarks-repository';
import { PostSign, Skill, SkillGroupId, SkillId } from '../skill';

export class TeamClutchAttemptsPercentage extends Skill {
	public readonly benchmarkKeyAvg: keyof Benchmark = 'teamClutchAttemptsPercentageAvg';
	public readonly benchmarkKeyStd: keyof Benchmark = 'teamClutchAttemptsPercentageStd';
	public groupId: SkillGroupId = 'clutchSkillGroup';
	public postSign: PostSign = '%';

	dependsOn = null;
	description = 'Percentage of clutches your team attempted to win.';
	detailsInfo = false;
	displayInGoodBadSummary = false;
	groupTitle = 'Clutching';
	id = SkillId.TEAM_CLUTCH_ATTEMPTS_PERCENTAGE;
	improveTip = false;
	inversed = false;
	isRatio = true;
	minValue = 0;
	smallDescription = 'Percentage of clutches your team attempted to win.';
	title = 'Team Clutch Attempt Rate';
}
