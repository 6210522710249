import { CommonModule } from '@angular/common';
import { FormsModule, ReactiveFormsModule } from '@angular/forms';
import { NgbToastModule, NgbTooltipModule } from '@ng-bootstrap/ng-bootstrap';
import { NgModule } from '@angular/core';
import { RouterModule } from '@angular/router';
import { Title } from '@angular/platform-browser';

import { AttemptingLoginComponent } from 'src/app/layout/attempting-login/attempting-login.component';
import { ButtonComponent } from 'src/app/components/atoms/button/button.component';
import { CardBodyInnerDirective } from 'src/app/components/atoms/card/card-body-inner.directive';
import { CardBodyOuterDirective } from 'src/app/components/atoms/card/card-body-outer.directive';
import { CardComponent } from 'src/app/components/atoms/card/card.component';
import { CardHeaderInnerDirective } from 'src/app/components/atoms/card/card-header-inner.directive';
import { CardHeaderOuterDirective } from 'src/app/components/atoms/card/card-header-outer.directive';
import { CardHeaderTextDirective } from 'src/app/components/atoms/card/card-header-text.directive';
import { ExpandableVerticalComponent } from 'src/app/components/atoms/expandable-vertical/expandable-vertical.component';
import { IconComponent } from 'src/app/components/atoms/icon/icon.component';
import { RadioComponent } from 'src/app/components/atoms/radio/radio.component';
import { LeetifyRatingPipe } from 'src/app/pipes/leetify-rating.pipe';
import { LottieModule } from 'src/app/lottie.module';
import { MiniProfileComponent } from 'src/app/widgets/mini-profile/mini-profile.component';
import { ModalComponent } from 'src/app/widgets/modal/modal.component';
import { ProgressBarChartComponent } from 'src/app/widgets/progress-bar-chart/progress-bar-chart.component';
import { ScoreCircleOutlineComponent } from 'src/app/widgets/score-circle-outline-widget/score-circle-outline.component';
import { ScrollToAnchorDirective } from 'src/app/directives/scroll-to-anchor.directive';
import { ToastComponent } from 'src/app/toast/toast.component';
import { TooltipComponent } from 'src/app/tooltip/tooltip.component';
import { UserLeetifyIconComponent } from 'src/app/widgets/user-leetify-icon/user-leetify-icon.component';

const components = [
	// Pipes (they aren't technically atoms, but they fill the same niche)
	LeetifyRatingPipe,

	// Directives (also not technically atoms, but they also fall into the same category as atoms)
	CardBodyInnerDirective,
	CardBodyOuterDirective,
	CardHeaderInnerDirective,
	CardHeaderOuterDirective,
	CardHeaderTextDirective,
	ScrollToAnchorDirective,

	// Atoms
	ButtonComponent,
	CardComponent,
	ExpandableVerticalComponent,
	IconComponent,
	ModalComponent,
	ProgressBarChartComponent,
	RadioComponent,
	ScoreCircleOutlineComponent,
	UserLeetifyIconComponent,

	// TODO these are not atoms and need to be migrated to their own modules or completely replaced
	AttemptingLoginComponent,
	MiniProfileComponent,
	ToastComponent,
	TooltipComponent,
];

@NgModule({
	declarations: [
		...components,
	],

	imports: [
		CommonModule,
		FormsModule,
		LottieModule,
		NgbToastModule,
		NgbTooltipModule,
		ReactiveFormsModule,
		RouterModule,
	],

	exports: [
		CommonModule,
		FormsModule,
		LottieModule,
		NgbToastModule,
		NgbTooltipModule,
		ReactiveFormsModule,
		RouterModule,
		...components,
	],

	providers: [
		Title,
	],
})
export class AtomsModule {
	//
}
