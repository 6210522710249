import { Benchmark } from '../../benchmarks-repository';
import { PostSign, Skill, SkillGroupId, SkillId } from '../skill';
import { TeamName } from '../../enums';

export class TOpeningDuelAttemptsPercentage extends Skill {
	public readonly benchmarkKeyAvg: keyof Benchmark = 'tOpeningDuelAttemptsPercentageAvg';
	public readonly benchmarkKeyStd: keyof Benchmark = 'tOpeningDuelAttemptsPercentageStd';
	public groupId: SkillGroupId = 'positioningSkillGroup';
	public postSign: PostSign = '%';

	dependsOn = null;
	description = '';
	descriptionExtra = '';
	detailsInfo = false;
	displayInGoodBadSummary = false;
	groupIcon = 'mdi mdi-progress-wrench';
	groupTitle = 'Positioning group';
	icon = 'mdi mdi-weather-cloudy';
	id = SkillId.T_OPENING_DUEL_ATTEMPTS_PERCENTAGE;
	improveTip = false;
	inversed = false;
	isRatio = false;
	minValue = 0;
	side = TeamName.TERRORIST;
	smallDescription = '';
	title = 'T Opening Duel Attempts Percentage';
}
