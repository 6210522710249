import { Skill, SkillId } from '../skill';

export class TotalDamage extends Skill {
	public readonly benchmarkKeyAvg = undefined;
	public readonly benchmarkKeyStd = undefined;

	dependsOn = null;
	description = '';
	detailsInfo = false;
	displayInGoodBadSummary = false;
	groupId = null;
	groupTitle = 'General';
	id = SkillId.TOTAL_DAMAGE;
	improveTip = false;
	inversed = false;
	isRatio = false;
	smallDescription = '';
	title = 'Total Damage';
}
