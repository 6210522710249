import { Benchmark } from '../../benchmarks-repository';
import { PlayerClutchSavePercentage } from './player-clutch-save-percentage';
import { SkillId } from '../skill';

export class PlayerClutch1v5SavePercentage extends PlayerClutchSavePercentage {
	public readonly benchmarkKeyAvg: keyof Benchmark = 'playerClutch1v5SavePercentageAvg';
	public readonly benchmarkKeyStd: keyof Benchmark = 'playerClutch1v5SavePercentageStd';
	id = SkillId.PLAYER_CLUTCH_1V5_SAVE_PERCENTAGE;
	title = '1v5 Save Rate';
}
