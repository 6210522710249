import { Benchmark } from '../../benchmarks-repository';
import { PostSign, Skill, SkillGroupId, SkillId } from '../skill';

export class AccuracyHead extends Skill {
	public readonly benchmarkKeyAvg: keyof Benchmark = 'accuracyHeadAvg';
	public readonly benchmarkKeyStd: keyof Benchmark = 'accuracyHeadStd';
	public readonly dependsOn = 'shotsHitFoe';
	public readonly description = "'edshot machine? Measures how many of your total hits were in the head. Not to be confused with headshot kills as measured on the in-game scoreboard. Excludes shots with AWP.";
	public readonly descriptionExtra = 'All shots that hit enemy in the head divided by all the shots that hit the enemy.';
	public readonly detailsInfo = true;
	public readonly displayInGoodBadSummary = true;
	public readonly fallbackValue = 'n/a';
	public readonly groupIcon = 'mdi mdi-crosshairs-gps';
	public readonly groupId: SkillGroupId = 'aimSkillGroup';
	public readonly groupTitle = 'Aim group';
	public readonly icon = 'mdi mdi-food-apple';
	public readonly id = SkillId.ACCURACY_HEAD;
	public readonly improveTip = true;
	public readonly inversed = false;
	public readonly isRatio = true;
	public readonly minValue = 0;
	public readonly postSign: PostSign = '%';
	public readonly shortTitle = 'Head Accuracy';
	public readonly smallDescription = 'How many of your total hits were in the head. Excludes shots with AWP.';
	public readonly title = 'Headshot Accuracy';
}
