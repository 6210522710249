import { Benchmark } from '../../../benchmarks-repository';
import { PostSign, Skill, SkillGroupId, SkillId } from '../../skill';

export class UntradedKillsRate extends Skill {
	public readonly benchmarkKeyAvg: keyof Benchmark = 'untradedKillsRateAvg';
	public readonly benchmarkKeyStd: keyof Benchmark = 'untradedKillsRateStd';
	public groupId: SkillGroupId = 'teamplaySituationGroup';
	public postSign: PostSign = '%';

	dependsOn = null;
	description = 'Percentage of rounds where someone on the team got a kill that went untraded to equalize it to a 4v4 situation when your team had a 4v5 disadvantage.';
	detailsInfo = false;
	displayInGoodBadSummary = false;
	groupTitle = '4v5';
	id = SkillId.UNTRADED_KILLS_RATE;
	improveTip = false;
	inversed = false;
	isRatio = true;
	minValue = 0;
	smallDescription = 'Percentage of your teams kills that were not traded by enemy team during a 4v5.';
	title = 'Untraded Kills';
}
