import { Benchmark } from '../../benchmarks-repository';
import { Skill, SkillId } from '../skill';

export class PositioningRating extends Skill {
	public readonly benchmarkKeyAvg: keyof Benchmark = 'positioningRatingAvg';
	public readonly benchmarkKeyStd: keyof Benchmark = 'positioningRatingStd';

	decimalPlaces = 0;
	dependsOn = null;
	description = '';
	detailsInfo = false;
	displayInGoodBadSummary = false;
	groupId = null;
	groupTitle = 'Ratings';
	id = SkillId.POSITIONING_RATING;
	improveTip = false;
	inversed = false;
	isRatio = false;
	isScaledForMatch = true;
	shortTitle = 'Positioning';
	smallDescription = '';
	title = 'Positioning Rating';
	fallbackValue = 'n/a';
}
