import { Skill, SkillId } from '../skill';

export class UtilityDamage extends Skill {
	public readonly benchmarkKeyAvg = undefined;
	public readonly benchmarkKeyStd = undefined;

	decimalPlaces = 0;
	dependsOn = null;
	description = '';
	detailsInfo = false;
	displayInGoodBadSummary = false;
	groupId = null;
	groupTitle = 'Utility';
	id = SkillId.UTILITY_DAMAGE;
	improveTip = false;
	inversed = false;
	isRatio = false;
	shortTitle = 'Utility Damage';
	smallDescription = '';
	title = 'Total Utility Damage';
}
