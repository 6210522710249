import { HttpClient } from '@angular/common/http';
import { Inject, Injectable, PLATFORM_ID } from '@angular/core';
import { Subject } from 'rxjs';
import { isPlatformServer } from '@angular/common';

@Injectable({
	providedIn: 'root',
})
export class ReferrerDomainBlacklistService {

	private blacklistSource = new Subject<string>();
	public blacklist$ = this.blacklistSource.asObservable();

	public constructor(
		@Inject(PLATFORM_ID) private platformId,
		protected readonly http: HttpClient,
	) {
	}

	public async checkDomain(): Promise<void> {
		if (isPlatformServer(this.platformId)) return;

		const referrer = document.referrer;
		if (!referrer || referrer === '') return;

		this.http.post('/api/referrer/check', {
			domain: referrer,
		}).subscribe({
			error: ({ status }) => {
				if (status === 403) {
					this.blacklistSource.next(referrer);
				}
			},
		});
	}

}
