import { Benchmark } from '../../benchmarks-repository';
import { OpeningDuelSuccessPercentage } from './opening-duel-success-percentage';
import { SkillId } from '../skill';
import { TeamName } from '../../enums';

export class TOpeningDuelSuccessPercentage extends OpeningDuelSuccessPercentage {
	public readonly benchmarkKeyAvg: keyof Benchmark = 'tOpeningDuelSuccessPercentageAvg';
	public readonly benchmarkKeyStd: keyof Benchmark = 'tOpeningDuelSuccessPercentageStd';
	id = SkillId.T_OPENING_DUEL_SUCCESS_PERCENTAGE;
	side = TeamName.TERRORIST;
	title = 'T Opening Duel Success Percentage';
	public readonly showChart: boolean = false;

	focusAreaLowerBound = 1;
	focusAreaUpperBound = 95;
}
