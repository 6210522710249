<div *ngIf="user && year && quarter" class="banner">
	<div *ngIf="year === 2023 && quarter === ProgressReportQuarter.ANNUAL" class="body">
		Recap 2023 is here! Check out what you accomplished at the end of CS:GO and the dawn of CS2.
	</div>

	<div *ngIf="year !== 2023 || quarter !== ProgressReportQuarter.ANNUAL" class="body">
		Take a look at what you've accomplished in
		<ng-container [ngSwitch]="quarter">
			<ng-container *ngSwitchCase="ProgressReportQuarter.ANNUAL">{{ year }}!</ng-container>

			<ng-container *ngSwitchCase="ProgressReportQuarter.H1">{{ year }} so far!</ng-container>
			<ng-container *ngSwitchCase="ProgressReportQuarter.H2">Q3 and Q4 of {{ year }}!</ng-container>

			<ng-container *ngSwitchCase="ProgressReportQuarter.Q1">Q1 of {{ year }}!</ng-container>
			<ng-container *ngSwitchCase="ProgressReportQuarter.Q2">Q2 of {{ year }}!</ng-container>
			<ng-container *ngSwitchCase="ProgressReportQuarter.Q3">Q3 of {{ year }}!</ng-container>
			<ng-container *ngSwitchCase="ProgressReportQuarter.Q4">Q4 of {{ year }}!</ng-container>
		</ng-container>
	</div>

	<a class="open-button" [routerLink]="['/progress-report', year, quarter, user.steam64Id]">
		<ng-container [ngSwitch]="quarter">
			<ng-container *ngSwitchCase="ProgressReportQuarter.ANNUAL">View Recap</ng-container>
			<ng-container *ngSwitchDefault>View Progress Report</ng-container>
		</ng-container>

		<leetify-icon [icon]="Icon.ARROW_RIGHT"></leetify-icon>
	</a>

	<button (click)="dismiss()" class="dismiss-button">
		Dismiss
	</button>
</div>
